import fromPairs from 'lodash/fromPairs';
import type { JsonObject, PartialDeep } from 'type-fest';
import type {
  AnalyticsEventData,
  AnalyticsEventImportance,
  EventDataDefault,
  ValidEventData,
} from '@cbhq/client-analytics';
import { config, logEvent as CCAlogEvent } from '@cbhq/client-analytics';

import { leaveBreadcrumb } from '../bugsnag';
import { clientSessionIdStore } from '../clientSessionIdStore';
import { getServerMetadata } from '../getServerMetadata';
import { broadcastEvent, isMobileExperience } from '../postMessage';

import type { DeprecatedEventName } from '.';
import type { ApplePayEventName, CommonEventName, OnrampEventName } from './eventDeclarations';

export const getDefaultMetadata = (): ExtraEventData => {
  const isInReactNativeWebView = isMobileExperience();
  const { platform } = config;

  const clientAppDetails = clientSessionIdStore.getClientAppDetails();
  const featureFlags = clientSessionIdStore.getFeatureFlags();

  const redactedAppParams = clientSessionIdStore.getRedactedAppParamsMetadata();
  const safeWidgetParams = (() => {
    if (!redactedAppParams?.widgetParams) return [];

    const { destinationWallets, ...safeParams } = redactedAppParams.widgetParams;
    return safeParams;
  })();

  const destinationWallets = redactedAppParams?.widgetParams?.destinationWallets;

  const metadata: ExtraEventData = {
    appId: clientSessionIdStore.getAppId(),
    sessionId: clientSessionIdStore.getClientSessionId(),
    authSessionId: clientSessionIdStore.getGsscData()?.sid,
    authTime: clientSessionIdStore.getGsscData()?.auth_time,
    referrer: document.referrer,
    platform,
    isInReactNativeWebView,
    clientPlatformAttribution: clientSessionIdStore.getPlatformAttribution(),
    clientSdkVersion: clientSessionIdStore.getSdkVersion(),
    clientPlatformVersion: clientSessionIdStore.getPlatformVersion(),
    clientReactNativeVersion: clientSessionIdStore.getReactNativeVersion(),
    displayName: clientAppDetails?.displayName,
    oauthClientId: clientAppDetails?.oauthId,
    clientFeatureFlags: featureFlags,
    'appParams.queryParams': redactedAppParams?.queryExclusiveParams,
    'appParams.initMethod': redactedAppParams?.initMethod,
    'appParams.widgetParams': safeWidgetParams,
    'appParams.widgetParams.destinationWallets': JSON.stringify(destinationWallets),
    buyFlowType: clientSessionIdStore.getBuyFlowType(),
    authProvider: clientSessionIdStore.getAuthProvider(),
    authenticationMethod: clientSessionIdStore.getAuthenticationMethod(),
    exposedExperiments: {
      ...fromPairs(
        clientSessionIdStore.getExposedExperiments().map((exp) => [exp.experiment, exp.group]),
      ),
      ...getServerMetadata()?.exposedExperiments,
    },
    originalReferringDomain: getServerMetadata()?.originalReferringDomain,
    guestCheckoutTransactionUuid: clientSessionIdStore.getGuestCheckoutTransactionUuid(),
  };

  const walletAnalyticsData = clientSessionIdStore.getWalletAnalyticsData();
  const walletUserId = clientSessionIdStore.getWalletUserId();

  if (walletAnalyticsData !== undefined) {
    // user identified by walletUserId
    metadata.userUuid = clientSessionIdStore.getUserUuid();
  } else if (walletUserId !== undefined) {
    // for old wallet clients, where amplitude sesison is not shared between flows
    // user identified by retail user UUID
    metadata.walletUserId = walletUserId;
  }

  return metadata;
};

export type LogEventOptions = {
  broadcastToSDK?: boolean;
  isBroadcastToSDKEnabled?: boolean;
  importance?: AnalyticsEventImportance;
};

const cbPayEventPrefix = 'onramp.';
type CBPayEventPrefix = typeof cbPayEventPrefix;

export type EventName =
  | DeprecatedEventName
  | `${CBPayEventPrefix}${CommonEventName | OnrampEventName | ApplePayEventName}`;

/** Those event will be broadcasted to the SDK by default, given the client has the appropriate feature flag enabled */
export const eventsToBroadcastByDefault: readonly EventName[] = [
  'blocked_by_policy_restrictions',
  'onramp.send_failure',
  'onramp.unsupported_country',
  'onramp.buy_failure',
  'onramp.txn_submit',
  'onramp.source_of_funds_report',
  'onramp.submit_success',
  'onramp.submit_2FA',
  'onramp.buy_insufficient_limit',
  'onramp.ocb_page_view',
  'onramp.ocb_page_submit',
  'onramp.ocb_page_cancel',
  'onramp.ocb_page_change_asset',
  'onramp.ocb_page_change_source_of_funds',
  'onramp.ocb_page_change_network',
  'onramp.ocb_page_create_transfer_requested',
  'onramp.ocb_page_create_transfer_succeeded',
  'onramp.ocb_page_create_transfer_failure',
  'onramp.guest_checkout_step',
];

type RequiredEventData = ValidEventData;
export type ExtraEventData = PartialDeep<EventDataDefault>;
export type EventData = RequiredEventData & ExtraEventData;

/**
 * Overriding log event to add our own parameters to the events.
 * DO NOT USE ON SERVER SIDE, this relies on window object & will error there.
 */
export function logEvent(name: EventName, metadata: EventData, options: LogEventOptions = {}) {
  const { importance, broadcastToSDK, isBroadcastToSDKEnabled } = options;
  const enrichedMetadata = { ...getDefaultMetadata(), ...metadata };
  // it's safe to coerce EventData into AnalyticsEventData because the only difference is that undefined is allowed, which is just ignored
  CCAlogEvent(name, enrichedMetadata as AnalyticsEventData, importance);

  leaveBreadcrumb(
    `Analytics event logged: '${name}'`,
    {
      name,
      logEventOptions: options,
      ...metadata,
    },
    'log',
  );

  const isClientAllowlistedForBroadcasting = Boolean(
    isBroadcastToSDKEnabled &&
      clientSessionIdStore.getFeatureFlags()?.broadcastAnalyticsEventsEnabled,
  );

  const shouldBroadcastIfEnabled = broadcastToSDK ?? eventsToBroadcastByDefault.includes(name);

  if (shouldBroadcastIfEnabled && isClientAllowlistedForBroadcasting) {
    const unprefixedName = name.startsWith(cbPayEventPrefix)
      ? name.slice(cbPayEventPrefix.length)
      : name;

    broadcastEvent({
      eventName: 'analytics',
      event: { name: `cbpay.${unprefixedName}`, metadata: metadata as JsonObject },
    });
  }
}
