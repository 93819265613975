import type { InitMethodType } from '@onramp/components/AppManagerProvider/types';
import type {
  GuestCheckoutTransactionError,
  GuestCheckoutTransactionStatus,
  PaymentMethodTypeV2,
} from '@onramp/data/graphql-types';
import type { TransferType } from '@onramp/hooks/useTransferType';
import type { WidgetKey } from '@onramp/shared/appParams.schema';
import type { ServerMetadata } from '@onramp/shared/serverMetadata.schema';
import { initMethodAtom } from '@onramp/state/recoil/atoms/initProcessAtoms';
import type { BuyFlowType } from '@onramp/state/recoil/buyFlowType';
import { jotaiStore } from '@onramp/state/recoil/utils';
import type { SourceOfFundsType } from '@onramp/state/types/SourceOfFundsData';
import fromPairs from 'lodash/fromPairs';
import mapKeys from 'lodash/mapKeys';
import pickBy from 'lodash/pickBy';
import type { EmptyObject, SnakeCasedProperties } from 'type-fest';
import type { LogMetric } from '@cbhq/client-analytics';
import { logMetric, MetricType } from '@cbhq/client-analytics';
// eslint-disable-next-line @cbhq/no-lib-import
import type {
  PhoneRegisterStep,
  TwoFactorRegisterErrorType,
} from '@cbhq/two-factor-register/lib/types';

import type { ExperimentTestName } from './experiments/experiments';
import { leaveBreadcrumb } from './bugsnag';
import type { AuthenticationMethod } from './clientSessionIdStore';
import { clientSessionIdStore } from './clientSessionIdStore';
import type { RequiredFieldLoggerArg } from './eventing';
import { getIsEmbedded } from './getIsEmbedded';
import { getServerMetadata } from './getServerMetadata';
import { getUseCallHookFnOnMount } from './getUseCallFnOnMount';
import type { GuestCheckoutTimerName } from './guestCheckoutUtils';
import { is3ds } from './is3ds';
import { isMobileExperience } from './postMessage';
import { getRouteKeyFromPath } from './routes';
import { isNotNullish } from './types';
import { useReffedFunction } from './useReffedFunction';
import { wrapHookWithCallOnce, wrapWithCallOnce } from './wrapWithCallOnce';

/**
 * To add a new metric, start with {@link MetricDefinitionMap}. Add a new field to it
 * where the key is the metricName and the value is {@link MetricDefinition}. For a given
 * metric:
 *
 * - {@link MetricDefinition.tags} defines its expected metadata
 * - {@link MetricDefinition.metricType} defines its {@link MetricType}
 *
 * Having added it, TypeScript will guide you: you'll get errors in objects you need to update.
 * */
export type MetricDefinitionMap = {
  critical_step: MetricDefinition<{
    tags:
      | { step: 'init'; initMethod?: InitMethodType; isSecureInit?: boolean }
      | { step: 'init-success'; initMethod?: InitMethodType; isSecureInit?: boolean }
      | { step: 'redirect-to-login' }
      | { step: 'has-active-session' }
      | { step: 'buy-flow-type-determined' }
      | { step: 'select-asset' }
      | { step: 'submit-amount' }
      | { step: 'txn-submit' }
      | { step: 'buy-succeeded'; with_3ds_challenge: boolean }
      | { step: 'send-succeeded'; two_factor_required: boolean; with_retry: boolean }
      | { step: 'create-send-succeeded'; with_retry: boolean }
      | { step: '2fa-attempt' }
      | { step: '2fa-submit' }
      | { step: '2fa-success' }
      | { step: '2fa-cancel' }
      | { step: '3ds-challenge-redirect' }
      | { step: '3ds-challenge-success' }
      | { step: '3ds-challenge-failure'; stage: Return3DSFailedStage }
      // should be triggered every time the user sees an error screen
      | { step: 'error-screen' }
      // should only be triggered on the first time the user sees an error screen within a session
      | { step: 'session-with-error-screen' }
      | { step: 'exit-from-back-button' };
    metricType: MetricType.count;
  }>;
  invalid_wallet_address: MetricDefinition<{
    tags: {
      in_create_wallet_transaction_treatment: string | undefined;
      asset_ticker: string | undefined;
    };
    metricType: MetricType.count;
  }>;
  critical_query_status: MetricDefinition<{
    tags: { query: CriticalQuery; status: 'loading' | 'success' | 'failure' };
    metricType: MetricType.count;
  }>;
  recent_transaction_create_transfer_failure: MetricDefinition<{
    tags: {
      asset_ticker: string | undefined;
      currency: string | undefined;
      selected_network: string | undefined;
      input_amount: string | undefined;
    };
    metricType: MetricType.count;
  }>;
  submit_apple_pay_step: MetricDefinition<{
    tags:
      | { step: 'start' }
      | { step: 'success' }
      | { step: 'cancelled'; secondsToCancel: string; eventType: string }
      | { step: 'tokenizer-failed' }
      | {
          step: 'internal-step-completed';
          internal_step: 'payment_method' | 'shipping_contact' | 'shipping_method';
        }
      | { step: 'merchant-validated'; is_merchant_valid: boolean }
      | { step: 'merchant-validated-v2'; is_merchant_valid: boolean }
      | { step: 'unknown-error' };
    metricType: MetricType.count;
  }>;
  guest_checkout_step: MetricDefinition<{
    metricType: MetricType.count;
    tags: { isReturningUser?: boolean } & (
      | {
          step: 'loaded';
        }
      | {
          step: 'redirected_to_regular_flow';
          reason: 'authenticated' | 'gc_disabled';
        }
      | {
          step: 'started';
        }
      | {
          step: 'login-page';
        }
      | {
          step: 'chose-to-login';
        }
      | {
          step: 'tos-modal';
          substep: 'displayed' | 'accepted' | 'declined' | 'closed';
        }
      | {
          step: 'create-guest-session';
          substep: RequestStepSubstep;
          previouslyExpired: boolean;
        }
      | {
          step: 'init-sardine';
          substep: 'succeeded' | 'failed';
        }
      | {
          step: 'sardine-log';
          customerId: string;
          substep: 'succeeded' | 'failed';
        }
      | {
          step: 'phone-verification-page';
        }
      | {
          step: 'phone-verification';
          substep:
            | PhoneRegisterStep
            | 'continue_to_input'
            | 'transaction_limit'
            | 'continue_to_card_details' // Frames flow, skipping the input page
            | 'continue_to_order_preview' // Non-frames flow, skipping the input page and billing address page
            | undefined;
        }
      | { step: 'phone-verification-error'; type: TwoFactorRegisterErrorType }
      | {
          step: 'phone-registation-error';
          message: string;
        }
      | {
          step: 'create-guest-checkout-session';
          substep: RequestStepSubstep;
        }
      | {
          step: 'input-page';
          substep:
            | 'loaded'
            | 'continue_to_order_preview'
            | 'continue_to_select_asset'
            | 'exit_on_back'
            | 'continue_to_card_details';
          input_amount?: string;
          has_default_input_amount?: boolean;
          is_navigating?: boolean;
          amount_zero?: boolean;
          has_limits_left?: boolean;
          amount_too_big?: boolean;
          undefined_exchange_rate?: boolean;
          has_checked_asset_list?: boolean;
        }
      | {
          step: 'input-page-continue-disabled';
          is_navigating: boolean;
          amount_zero: boolean;
          has_limits_left: boolean;
          amount_too_big: boolean;
          undefined_exchange_rate: boolean;
        }
      | {
          step: 'select-asset-page';
        }
      | {
          step: 'guest-checkout-change-network';
        }
      | {
          step: 'append-tokenex-script';
          substep: 'succeeded' | 'failed';
        }
      | {
          step: 'validate-tokenex';
          substep: 'start' | 'timeout' | 'success' | 'fail';
          label: 'card' | 'cvv' | 'submit' | 'tokenize';
        }
      | {
          // We can't get a label on success
          step: 'validate-tokenex';
          substep: 'success';
        }
      | {
          step: 'tokenize-tokenex';
          substep: 'start' | 'fail' | 'success' | 'timeout';
        }
      | {
          step: 'skipping-billing-page';
        }
      | {
          step: 'billing-address-page';
        }
      | {
          step: 'create-transaction';
          substep: RequestStepSubstep;
          is_preview: boolean;
          network: string;
          asset: string;
        }
      | {
          step: 'card-details-page';
        }
      | {
          step: 'unsupported-asset-page';
        }
      | {
          step: 'order-preview-page';
          substep: 'loaded' | 'continue_to_interstitial' | 'recaptcha_visibile_success';
        }
      | {
          step: 'interstitial-page';
        }
      | {
          step: 'commit-transaction';
          substep: RequestStepSubstep;
          network: string;
          asset: string;
        }
      | {
          step: 'external-checkout';
          substep: 'started' | 'loaded' | 'succeeded' | 'closed';
          experience: 'mobile' | 'web' | 'iframe';
        }
      | {
          step: 'transaction-status-polling';
          substep: 'started' | 'failed';
        }
      | {
          step: 'transaction-status-polling';
          substep: 'finished';
          status: GuestCheckoutTransactionStatus | undefined;
        }
      | {
          step: 'transaction-error';
          type: GuestCheckoutTransactionError;
        }
      | {
          step: 'success';
        }
      | {
          step: 'order-details-page';
          hasBlockExplorerUrl: boolean;
        }
      | {
          step: '3ds-redirect';
          substep: 'started' | 'loaded' | '3ds_success' | '3ds_failure';
          timerExpired?: boolean;
        }
      | {
          step: 'load-tokenex';
          substep: 'started' | 'failed' | 'succeeded' | 'timeout';
          errorMessage?: string;
        }
      | {
          step: 'order-submitted-page';
          substep: 'loaded';
        }
      | {
          step: 'timer';
          // Renamed this in code for clarity, but didn't want to impact our eventing
          type: Exclude<GuestCheckoutTimerName, 'signupSession'> | 'session';
          substep: 'started' | 'expired';
        }
    );
  }>;
  required_gql_field_missing: MetricDefinition<{
    metricType: MetricType.count;
    tags: Partial<Omit<RequiredFieldLoggerArg, 'error'>>;
  }>;
  preset_fiat_amount_param_ignored: MetricDefinition<{
    metricType: MetricType.count;
    tags: { paramCurrency: string | undefined; accountCurrency: string | undefined };
  }>;
  perf_web_vital: MetricDefinition<{
    tags: { vital_type: 'lcp' | 'ttfb' | 'cls' | 'fid' | 'fcp' };
    metricType: MetricType.distribution;
  }>;
  page_view: MetricDefinition<{
    tags: { page_key: string | undefined; is_initial_page: boolean };
    metricType: MetricType.count;
  }>;
  landing: MetricDefinition<{
    tags: { step: 'select-login' | 'redirect' | 'has_session_redirect' | 'select-guest-checkout' };
    metricType: MetricType.count;
  }>;
  error: MetricDefinition<{
    tags: {
      error_type: string;
      user_facing: string;
      severity: string | undefined;
      source: string;
    };
    metricType: MetricType.count;
  }>;
  two_factor_error: MetricDefinition<{
    tags: { error_type: string };
    metricType: MetricType.count;
  }>;
  buy_success_send_fail_error: MetricDefinition<{
    tags: { two_factor_required: boolean; send_fee_increased: boolean; fee_treatment: string };
    metricType: MetricType.count;
  }>;
  have_sources_of_funds: MetricDefinition<{
    tags: {
      haveCryptoSourceOfFunds: boolean;
      haveFiatSourceOfFunds: boolean;
    };
    metricType: MetricType.count;
  }>;
  cb_login_session_check: MetricDefinition<{
    tags: { status: string };
    metricType: MetricType.count;
  }>;
  destination_wallets_uniform_shape: MetricDefinition<{
    tags: { uniform: boolean };
    metricType: MetricType.count;
  }>;
};

export type RequestStepSubstep = 'started' | 'failed' | 'succeeded';

type ExperimentExposureTags = {
  [Tag in `experiment_${ExperimentTestName}`]: string | undefined;
};

/**
 * This is meant to check that whatever is passed to it is compatible to {@link LogMetricTags}, and
 * then just return it as is
 */
type TagsDefinition<T extends Partial<LogMetricTags>> = T;

/**
 * The portion of {@link ServerMetadata} that can be directly included in
 * {@link AutomaticWidgetMetricTags}, because of type compatibility, then converted to snake case,
 * to follow metric tag name convention (e.g. `exposedExperiments` > `exposed_experiments`)
 */
type AutomaticServerMetadata = SnakeCasedProperties<
  TagsDefinition<Omit<ServerMetadata, 'exposedExperiments'>>
>;

/** Tags automatically added by `logWidgetMetric` */
export type AutomaticWidgetMetricTags = TagsDefinition<
  {
    app_id: string | undefined;
    client_name: string | undefined;
    oauth_client_id: string | undefined;
    platform_attribution: string | undefined;
    widget_type: WidgetKey | undefined;
    transfer_type: TransferType | undefined;
    page_key: string | undefined;
    source_of_funds_type: SourceOfFundsType | undefined;
    payment_method_type: PaymentMethodTypeV2 | undefined;
    selected_asset_ticker: string | undefined;
    selected_network: string | undefined;
    is_3ds: boolean;
    is_l2_send: boolean;
    buy_flow_type: BuyFlowType | undefined;
    is_in_react_native_web_view: boolean;
    authentication_method: AuthenticationMethod | undefined;
    is_embedded: boolean;
    init_method: InitMethodType | undefined;
  } & ExperimentExposureTags &
    AutomaticServerMetadata
>;

/** Tags automatically added by {@link useLogWidgetMetric} — those should depend on context/hooks */
export type GeneralWidgetMetricTags = EmptyObject;

type LogMetricTags = Partial<NonNullable<LogMetric['tags']>>;

type MetricDefinition<Definition extends { tags: LogMetricTags; metricType: MetricType }> =
  Definition;

export type MetricName = keyof MetricDefinitionMap;

const widgetMetricType: { [K in MetricName]: MetricDefinitionMap[K]['metricType'] } = {
  critical_step: MetricType.count,
  invalid_wallet_address: MetricType.count,
  critical_query_status: MetricType.count,
  recent_transaction_create_transfer_failure: MetricType.count,
  submit_apple_pay_step: MetricType.count,
  guest_checkout_step: MetricType.count,
  required_gql_field_missing: MetricType.count,
  preset_fiat_amount_param_ignored: MetricType.count,
  perf_web_vital: MetricType.distribution,
  page_view: MetricType.count,
  error: MetricType.count,
  landing: MetricType.count,
  two_factor_error: MetricType.count,
  buy_success_send_fail_error: MetricType.count,
  have_sources_of_funds: MetricType.count,
  cb_login_session_check: MetricType.count,
  destination_wallets_uniform_shape: MetricType.count,
};

/**
 * The string returned by each function is used to determine if two metrics are 'the same', so that
 * they're not reported twice by {@link useLogWidgetMetricOnce} and {@link useLogWidgetMetricOnceOnMount}.
 * The string _must_ be either `metricName` itself or prefixed with `` `${metricName}.` ``.
 *
 * E.g. For `critical_step`, two metrics are the same if they have the same `tags.step`.
 * */
const widgetMetricUniqueIdentifierFnMap: {
  [M in MetricName]: (param: LogWidgetMetricParams[M]) => M | `${M}.${string}`;
} = {
  critical_step: ({ metricName, tags }) => `${metricName}.${tags.step}`,
  invalid_wallet_address: ({ metricName }) => metricName,
  critical_query_status: ({ metricName }) => metricName,
  recent_transaction_create_transfer_failure: ({ metricName }) => metricName,
  submit_apple_pay_step: ({ metricName, tags }) =>
    tags.internal_step
      ? `${metricName}.${tags.step}.${tags.internal_step}`
      : `${metricName}.${tags.step}`,
  guest_checkout_step: ({ metricName, tags }) =>
    tags.substep ? `${metricName}.${tags.step}.${tags.substep}` : `${metricName}.${tags.step}`,
  landing: ({ metricName, tags }) => `${metricName}.${tags.step}`,
  required_gql_field_missing: ({ metricName, tags }) =>
    `${metricName}.${tags.owner}.${tags.fieldPath}`,
  preset_fiat_amount_param_ignored: ({ metricName }) => metricName,
  perf_web_vital: ({ metricName, tags }) => `${metricName}.${tags.vital_type}`,
  page_view: ({ metricName, tags }) => `${metricName}.${tags.page_key}`,
  error: ({ metricName, tags }) =>
    `${metricName}.${tags.page_key}.${tags.error_type}.${tags.user_facing}`,
  two_factor_error: ({ metricName, tags }) => `${metricName}.${tags.error_type}`,
  buy_success_send_fail_error: ({ metricName }) => `${metricName}`,
  have_sources_of_funds: ({ metricName }) => `${metricName}`,
  cb_login_session_check: ({ metricName }) => `${metricName}`,
  destination_wallets_uniform_shape: ({ metricName }) => `${metricName}`,
};

export type LogWidgetMetricParams = {
  [M in MetricName]: {
    metricName: M;
    value: number;
    tags: MetricDefinitionMap[M]['tags'] & LogMetricTags;
  };
};

type LogWidgetMetricParamsWithGeneralTags<M extends MetricName> = LogWidgetMetricParams[M] & {
  tags: GeneralWidgetMetricTags;
};

export function logWidgetMetric<M extends MetricName>({
  metricName,
  value,
  tags,
}: LogWidgetMetricParamsWithGeneralTags<M>) {
  const { sourceOfFunds, selectedAsset, selectedNetwork } =
    clientSessionIdStore.getBuyWidgetState() ?? {};
  const initMethod = jotaiStore.get(initMethodAtom);

  const clientExperimentExposureTags = fromPairs(
    clientSessionIdStore.getExposedExperiments().map((exp) => {
      const tagName: keyof ExperimentExposureTags = `experiment_${exp.experiment}`;
      return [tagName, exp.group];
    }),
  ) as ExperimentExposureTags;

  const serverExperimentExposureTags = mapKeys(
    getServerMetadata()?.exposedExperiments,
    (_value, key): keyof ExperimentExposureTags => {
      const experimentName = key as keyof NonNullable<ServerMetadata['exposedExperiments']>;
      return `experiment_${experimentName}`;
    },
  ) as ExperimentExposureTags;

  const automaticTags: AutomaticWidgetMetricTags = {
    app_id: clientSessionIdStore.getAppId(),
    client_name: clientSessionIdStore.getClientAppDetails()?.displayName,
    oauth_client_id: clientSessionIdStore.getClientAppDetails()?.oauthId,
    platform_attribution: clientSessionIdStore.getPlatformAttribution(),
    widget_type: clientSessionIdStore.getAppParamsMetadata()?.widgetParams?.widget,
    transfer_type: clientSessionIdStore.getTransferType(),
    page_key: getRouteKeyFromPath(window.location.href),
    source_of_funds_type: sourceOfFunds?.type,
    payment_method_type: sourceOfFunds?.paymentMethodType,
    selected_asset_ticker: selectedAsset?.ticker,
    selected_network: clientSessionIdStore.getActiveNetworkMetadata()?.id ?? selectedNetwork?.id,
    is_l2_send: Boolean(selectedNetwork),
    is_3ds: is3ds(sourceOfFunds),
    buy_flow_type: clientSessionIdStore.getBuyFlowType(),
    is_in_react_native_web_view: isMobileExperience(),
    authentication_method: clientSessionIdStore.getAuthenticationMethod(),
    is_embedded: getIsEmbedded(),
    init_method: initMethod,
    ...clientExperimentExposureTags,
    ...serverExperimentExposureTags,
  };

  const logMetricParams: LogMetric = {
    metricName: `cbpay.${metricName}`,
    metricType: widgetMetricType[metricName],
    value,
    tags: pickBy(
      {
        ...automaticTags,
        ...tags,
      },
      isNotNullish,
    ),
  };

  logMetric(logMetricParams);

  leaveBreadcrumb(`Metric logged: '${logMetricParams.metricName}'`, logMetricParams, 'log');
}

export function useLogWidgetMetric() {
  return useReffedFunction(<M extends MetricName>(params: LogWidgetMetricParams[M]) => {
    const generalTags: GeneralWidgetMetricTags = {};
    logWidgetMetric<M>({ ...params, tags: { ...params.tags, ...generalTags } });
  });
}

function argsToKeyFn<M extends MetricName>(params: LogWidgetMetricParams[M]) {
  const perMetricArgsToKeyFn = widgetMetricUniqueIdentifierFnMap[params.metricName];

  if (perMetricArgsToKeyFn) {
    return perMetricArgsToKeyFn(params);
  }

  return JSON.stringify(params);
}

/** We have a shared cache so that the same metric isn't sent twice across {@link logWidgetMetricOnce} and {@link useLogWidgetMetricOnce} */
export const logWidgetMetricCacheMap = new Map<string, string>();

export const logWidgetMetricOnce = wrapWithCallOnce(logWidgetMetric, {
  argsToKeyFn,
  argsToCacheFn: argsToKeyFn,
  cacheMap: logWidgetMetricCacheMap,
});

export const useLogWidgetMetricOnce = wrapHookWithCallOnce(useLogWidgetMetric, {
  argsToKeyFn,
  argsToCacheFn: argsToKeyFn,
  cacheMap: logWidgetMetricCacheMap,
});

export const useLogWidgetMetricOnMount = getUseCallHookFnOnMount(useLogWidgetMetric);
export const useLogWidgetMetricOnceOnMount = getUseCallHookFnOnMount(useLogWidgetMetricOnce);

export type Return3DSFailedStage =
  | 'check_critical_data'
  | 'get_3ds_challenge_state'
  | 'parse_3ds_challenge_state'
  | 'fetch_gql_query'
  | 'parse_gql_data'
  | 'assert_selected_asset'
  | 'assert_transfer_data'
  | 'check_card_exists_and_transfer_not_cancelled'
  | 'transfer_status_polling';

export type CriticalQuery = 'ocb.useSelectAsset';
