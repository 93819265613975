import { isLocalDevelopment } from '@onramp/utils/environment/sharedEnv';
import { logWidgetMetricOnce } from '@onramp/utils/metrics';

import { getErrorVitals } from '../initErrorVitals';

import { deleteGuestSignupSession } from './deleteGuestSignupSession';

type IsCbSessionActiveResult = Promise<
  | {
      ok: false;
    }
  | {
      ok: true;
      active: boolean;
    }
>;

/**
 * This utility makes a call to an API on the Coinbase login service to check if the user has a valid session. We can't
 * proxy this request through our node server because it needs to have the login.coinabse.com cookies in order to
 * actually check the session. We need to check this session because the U2FA component requires an active login session
 * to work.
 *
 * When we don't have an active session, this function also calls a second API to delete our inactive session data to
 * prevent the two factor register component from showing an expired session error.
 *
 * @returns
 *  ok - Whether we fetched the session status without error.
 *  active - Whether we have an active session.
 */
export const isCbSessionActive = async (): IsCbSessionActiveResult => {
  if (isLocalDevelopment) {
    // We can't call the login service directly in local due to CORS, so we just assume the login session is active.
    const active = !new URLSearchParams(window.location.search).has('forceEnableGc');
    const mockMessage = active
      ? 'Mocking as active since param forceEnableGc was not present.'
      : 'Mocking as inactive due forceEnableGc';
    // eslint-disable-next-line no-console
    console.info(`Can't query actual session status from localhost. ${mockMessage}`);
    return {
      ok: true,
      active,
    };
  }

  try {
    // Access control for this API is managed here in Entry Gateway, it has a strict list of allowed headers, so we
    // cannot use our normal fetchWithCommonHeaders method.
    // https://github.cbhq.net/infra/entry-gateway/blob/0dbe3c1a834919a70d39ead344208d62a968817f/config/config.primary.production.aws.use1.yml#L7478
    // eslint-disable-next-line no-restricted-globals
    const getStatusRes = await fetch(
      `${process.env.NEXT_PUBLIC_LOGIN_HOST}/api/v1/get-session-status`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      },
    );

    if (!getStatusRes.ok && getStatusRes.status !== 401) {
      getErrorVitals().logError(new Error('Bad response status code from /get-session-status'), {
        context: 'is_cb_session_active',
        isHandled: false,
        severity: 'error',
        status: getStatusRes.status,
      });
      logSessionCheckMetric('error');
      return {
        ok: false,
      };
    }

    logSessionCheckMetric(await getAuthStatus(getStatusRes));

    if ((await getAuthStatus(getStatusRes)) === 'active') {
      return {
        ok: true,
        active: true,
      };
    }

    // This clears any expired session state, preventing issues with two-factor-register
    if (!(await deleteGuestSignupSession())) {
      return {
        ok: false,
      };
    }

    return {
      ok: true,
      active: Boolean(getStatusRes.ok),
    };
  } catch (e) {
    getErrorVitals().logError(new Error(''), {
      context: 'is_cb_session_active',
      severity: 'error',
      isHandled: false,
    });
    logSessionCheckMetric('error');
    return {
      ok: false,
    };
  }
};

export function logSessionCheckMetric(status: 'active' | 'error' | 'expired' | 'inactive') {
  logWidgetMetricOnce({
    metricName: 'cb_login_session_check',
    value: 1,
    tags: { status },
  });
}

export async function getAuthStatus(res: Response) {
  if (res.ok) return 'active';

  // try {
  //   // https://github.cbhq.net/identity/unified-login/blob/ba7e88d29ff969ab1800ef536922763fa52b99f2/internal/signin/sessions.go#L43
  //   // This "unauthenticated" corresponds to e.g. a user who started the sign in flow,
  //   // but did not complete it.
  //   if ((await res.text()) === 'session is expired or unauthenticated') {
  //     return 'expired';
  //   }
  // } catch {
  //   getErrorVitals().logError(new Error('res.text error'), {
  //     context: 'is_cb_session_active',
  //     severity: 'error',
  //     isHandled: false,
  //   });
  //   return 'error';
  // }

  return 'inactive';
}
