import type {
  getIsCountryAllowedAccountFragment$data,
  getIsCountryAllowedAccountFragment$key,
} from '@onramp/data/__generated__/getIsCountryAllowedAccountFragment.graphql';
import type {
  getIsCountryAllowedFiatFragment$data,
  getIsCountryAllowedFiatFragment$key,
} from '@onramp/data/__generated__/getIsCountryAllowedFiatFragment.graphql';
import { graphql, readInlineData } from '@cbhq/data-layer';

const getIsCountryAllowedAccountFragment = graphql`
  fragment getIsCountryAllowedAccountFragment on Account @inline {
    type
  }
`;

type Account = Omit<getIsCountryAllowedAccountFragment$data, ' $fragmentType'>;

export function isCryptoAccountAllowed(
  countryCode: string | null | undefined,
  fragmentRef: getIsCountryAllowedAccountFragment$key,
): boolean {
  const cryptoAccount = readInlineData(getIsCountryAllowedAccountFragment, fragmentRef);

  if (!countryCode || !cryptoAccount) return false;

  const permissions = countryPermissionMap[countryCode];

  if (!permissions) return false;

  return permissions.some((p) => p.kind === 'crypto' && p.conditionFn(cryptoAccount));
}

export function isFiatWalletAllowed(
  countryCode: string | null | undefined,
  fragmentRef: getIsCountryAllowedAccountFragment$key,
): boolean {
  const fiatWallet = readInlineData(getIsCountryAllowedAccountFragment, fragmentRef);

  if (!countryCode || !fiatWallet) return false;

  const permissions = countryPermissionMap[countryCode];

  if (!permissions) return false;

  return permissions.some((p) => p.kind === 'fiat-wallet' && p.conditionFn(fiatWallet));
}

const getIsCountryAllowedFiatFragment = graphql`
  fragment getIsCountryAllowedFiatFragment on PaymentMethodV2 @inline {
    type
    instantBuy
    pickerData {
      __typename
      ... on WorldpayCardPickerData {
        use3ds
      }
      # this is needed for Relay to include 'BankPickerDataV2' as a possible value for __typename, but doesn't incur in additional data being requested
      ... on BankPickerDataV2 {
        __typename
      }
    }
  }
`;

export type FiatPaymentMethod = Omit<getIsCountryAllowedFiatFragment$data, ' $fragmentType'>;

export function isFiatAccountAllowed({
  countryCode,
  pm: fragmentRef,
  isInstantACHEnabled,
}: {
  countryCode: string | null | undefined;
  pm: getIsCountryAllowedFiatFragment$key;
  isInstantACHEnabled: boolean;
}): boolean {
  const paymentMethod = readInlineData(getIsCountryAllowedFiatFragment, fragmentRef);
  if (!countryCode || !paymentMethod) return false;

  const permissions = countryPermissionMap[countryCode];

  if (!permissions) return false;

  return permissions.some(
    (p) =>
      p.kind === 'fiat' &&
      p.conditionFn(paymentMethod, {
        isInstantACHEnabled,
      }),
  );
}

type CryptoPermissionConstraint = {
  kind: 'crypto';
  conditionFn: (data: Account) => boolean;
};

type FiatWalletPermissionConstraint = {
  kind: 'fiat-wallet';
  conditionFn: (data: Account) => boolean;
};

type FiatPermissionConstraint = {
  kind: 'fiat';
  conditionFn: (
    data: FiatPaymentMethod,
    ops?: {
      isInstantACHEnabled?: boolean;
    },
  ) => boolean;
};

type PermissionConstraint =
  | CryptoPermissionConstraint
  | FiatPermissionConstraint
  | FiatWalletPermissionConstraint;

export const CRYPTO_WALLET_PERMISSION: CryptoPermissionConstraint = {
  kind: 'crypto',
  conditionFn: (data) => data.type === 'WALLET',
};

export const FIAT_WALLET_PERMISSION: FiatWalletPermissionConstraint = {
  kind: 'fiat-wallet',
  conditionFn: (data) => data.type === 'FIAT',
};

export const NON_3DS_DEBIT_CARD_PERMISSION: FiatPermissionConstraint = {
  kind: 'fiat',
  conditionFn: (data) => {
    const uses3ds =
      data.pickerData?.__typename === 'WorldpayCardPickerData' && data.pickerData?.use3ds;
    const isWorldpayCard = data.type === 'WORLDPAY_CARD';
    return isWorldpayCard && !uses3ds;
  },
};

export const THREE_DS_DEBIT_CARD_PERMISSION: FiatPermissionConstraint = {
  kind: 'fiat',
  conditionFn: (data) => {
    const uses3ds =
      data.pickerData?.__typename === 'WorldpayCardPickerData' && data.pickerData?.use3ds;
    const isWorldpayCard = data.type === 'WORLDPAY_CARD';

    return isWorldpayCard && uses3ds;
  },
};

export const ACH_WITH_INSTANT_BUY_PERMISSION: FiatPermissionConstraint = {
  kind: 'fiat',
  conditionFn: (data, { isInstantACHEnabled = true } = {}) => {
    const isACH = data.pickerData?.__typename === 'BankPickerDataV2';

    return isACH && !!data.instantBuy && isInstantACHEnabled;
  },
};

export const APPLE_PAY_PERMISSION: FiatPermissionConstraint = {
  kind: 'fiat',
  conditionFn: (data) => {
    return data.type === 'APPLE_PAY';
  },
};

// Want to make this US only for now
export const APPLE_PAY_PERMISSION_V2: FiatPermissionConstraint = {
  kind: 'fiat',
  conditionFn: (data) => {
    return data.type === 'APPLE_PAY';
  },
};

export type PaymentMethodPermission =
  | 'crypto_wallet'
  | 'non_3ds_card'
  | '3ds_card'
  | 'instant_ach'
  | 'fiat_wallet'
  | 'apple_pay'
  | 'apple_pay_v2';

type CountryPermissionMap = Record<string, readonly PermissionConstraint[]>;

const DEFAULT_PERMISSIONS = Object.freeze([
  FIAT_WALLET_PERMISSION,
  CRYPTO_WALLET_PERMISSION,
  NON_3DS_DEBIT_CARD_PERMISSION,
  THREE_DS_DEBIT_CARD_PERMISSION,
]);

export const countryPermissionMap: CountryPermissionMap = Object.freeze({
  AD: DEFAULT_PERMISSIONS,
  AE: DEFAULT_PERMISSIONS,
  AF: DEFAULT_PERMISSIONS,
  AG: DEFAULT_PERMISSIONS,
  AI: DEFAULT_PERMISSIONS,
  AL: DEFAULT_PERMISSIONS,
  AM: DEFAULT_PERMISSIONS,
  AO: DEFAULT_PERMISSIONS,
  AQ: DEFAULT_PERMISSIONS,
  AR: DEFAULT_PERMISSIONS,
  AS: DEFAULT_PERMISSIONS,
  AT: DEFAULT_PERMISSIONS,
  AU: DEFAULT_PERMISSIONS,
  AW: DEFAULT_PERMISSIONS,
  AX: DEFAULT_PERMISSIONS,
  AZ: DEFAULT_PERMISSIONS,
  BA: DEFAULT_PERMISSIONS,
  BB: DEFAULT_PERMISSIONS,
  BD: DEFAULT_PERMISSIONS,
  BE: DEFAULT_PERMISSIONS,
  BF: DEFAULT_PERMISSIONS,
  BG: DEFAULT_PERMISSIONS,
  BH: DEFAULT_PERMISSIONS,
  BI: DEFAULT_PERMISSIONS,
  BJ: DEFAULT_PERMISSIONS,
  BL: DEFAULT_PERMISSIONS,
  BM: DEFAULT_PERMISSIONS,
  BN: DEFAULT_PERMISSIONS,
  BO: DEFAULT_PERMISSIONS,
  BQ: DEFAULT_PERMISSIONS,
  BR: DEFAULT_PERMISSIONS,
  BS: DEFAULT_PERMISSIONS,
  BT: DEFAULT_PERMISSIONS,
  BV: DEFAULT_PERMISSIONS,
  BW: DEFAULT_PERMISSIONS,
  BY: DEFAULT_PERMISSIONS,
  BZ: DEFAULT_PERMISSIONS,
  CA: [...DEFAULT_PERMISSIONS, ACH_WITH_INSTANT_BUY_PERMISSION],
  CC: DEFAULT_PERMISSIONS,
  CD: DEFAULT_PERMISSIONS,
  CF: DEFAULT_PERMISSIONS,
  CG: DEFAULT_PERMISSIONS,
  CH: DEFAULT_PERMISSIONS,
  CI: DEFAULT_PERMISSIONS,
  CK: DEFAULT_PERMISSIONS,
  CL: DEFAULT_PERMISSIONS,
  CM: DEFAULT_PERMISSIONS,
  CN: DEFAULT_PERMISSIONS,
  CO: DEFAULT_PERMISSIONS,
  CR: DEFAULT_PERMISSIONS,
  CU: DEFAULT_PERMISSIONS,
  CV: DEFAULT_PERMISSIONS,
  CW: DEFAULT_PERMISSIONS,
  CX: DEFAULT_PERMISSIONS,
  CY: DEFAULT_PERMISSIONS,
  CZ: DEFAULT_PERMISSIONS,
  DE: [...DEFAULT_PERMISSIONS, APPLE_PAY_PERMISSION],
  DJ: DEFAULT_PERMISSIONS,
  DK: DEFAULT_PERMISSIONS,
  DM: DEFAULT_PERMISSIONS,
  DO: DEFAULT_PERMISSIONS,
  DZ: DEFAULT_PERMISSIONS,
  EC: DEFAULT_PERMISSIONS,
  EE: DEFAULT_PERMISSIONS,
  EG: DEFAULT_PERMISSIONS,
  EH: DEFAULT_PERMISSIONS,
  ER: DEFAULT_PERMISSIONS,
  ES: [...DEFAULT_PERMISSIONS, APPLE_PAY_PERMISSION],
  ET: DEFAULT_PERMISSIONS,
  FI: DEFAULT_PERMISSIONS,
  FJ: DEFAULT_PERMISSIONS,
  FK: DEFAULT_PERMISSIONS,
  FM: DEFAULT_PERMISSIONS,
  FO: DEFAULT_PERMISSIONS,
  FR: [...DEFAULT_PERMISSIONS, APPLE_PAY_PERMISSION],
  GA: DEFAULT_PERMISSIONS,
  GB: DEFAULT_PERMISSIONS,
  GD: DEFAULT_PERMISSIONS,
  GE: DEFAULT_PERMISSIONS,
  GF: DEFAULT_PERMISSIONS,
  GG: DEFAULT_PERMISSIONS,
  GH: DEFAULT_PERMISSIONS,
  GI: DEFAULT_PERMISSIONS,
  GL: DEFAULT_PERMISSIONS,
  GM: DEFAULT_PERMISSIONS,
  GN: DEFAULT_PERMISSIONS,
  GP: DEFAULT_PERMISSIONS,
  GQ: DEFAULT_PERMISSIONS,
  GR: DEFAULT_PERMISSIONS,
  GS: DEFAULT_PERMISSIONS,
  GT: DEFAULT_PERMISSIONS,
  GU: DEFAULT_PERMISSIONS,
  GW: DEFAULT_PERMISSIONS,
  GY: DEFAULT_PERMISSIONS,
  HK: DEFAULT_PERMISSIONS,
  HM: DEFAULT_PERMISSIONS,
  HN: DEFAULT_PERMISSIONS,
  HR: DEFAULT_PERMISSIONS,
  HT: DEFAULT_PERMISSIONS,
  HU: DEFAULT_PERMISSIONS,
  ID: DEFAULT_PERMISSIONS,
  IE: DEFAULT_PERMISSIONS,
  IL: DEFAULT_PERMISSIONS,
  IM: DEFAULT_PERMISSIONS,
  IN: DEFAULT_PERMISSIONS,
  IO: DEFAULT_PERMISSIONS,
  IQ: DEFAULT_PERMISSIONS,
  IR: [...DEFAULT_PERMISSIONS, APPLE_PAY_PERMISSION],
  IS: DEFAULT_PERMISSIONS,
  IT: [...DEFAULT_PERMISSIONS, APPLE_PAY_PERMISSION],
  JE: DEFAULT_PERMISSIONS,
  JM: DEFAULT_PERMISSIONS,
  JO: DEFAULT_PERMISSIONS,
  KE: DEFAULT_PERMISSIONS,
  KG: DEFAULT_PERMISSIONS,
  KH: DEFAULT_PERMISSIONS,
  KI: DEFAULT_PERMISSIONS,
  KM: DEFAULT_PERMISSIONS,
  KN: DEFAULT_PERMISSIONS,
  KP: DEFAULT_PERMISSIONS,
  KR: DEFAULT_PERMISSIONS,
  KW: DEFAULT_PERMISSIONS,
  KY: DEFAULT_PERMISSIONS,
  KZ: DEFAULT_PERMISSIONS,
  LA: DEFAULT_PERMISSIONS,
  LB: DEFAULT_PERMISSIONS,
  LC: DEFAULT_PERMISSIONS,
  LI: DEFAULT_PERMISSIONS,
  LK: DEFAULT_PERMISSIONS,
  LR: DEFAULT_PERMISSIONS,
  LS: DEFAULT_PERMISSIONS,
  LT: DEFAULT_PERMISSIONS,
  LU: DEFAULT_PERMISSIONS,
  LV: DEFAULT_PERMISSIONS,
  LY: DEFAULT_PERMISSIONS,
  MA: DEFAULT_PERMISSIONS,
  MC: DEFAULT_PERMISSIONS,
  MD: DEFAULT_PERMISSIONS,
  ME: DEFAULT_PERMISSIONS,
  MF: DEFAULT_PERMISSIONS,
  MG: DEFAULT_PERMISSIONS,
  MH: DEFAULT_PERMISSIONS,
  MK: DEFAULT_PERMISSIONS,
  ML: DEFAULT_PERMISSIONS,
  MM: DEFAULT_PERMISSIONS,
  MN: DEFAULT_PERMISSIONS,
  MO: DEFAULT_PERMISSIONS,
  MP: DEFAULT_PERMISSIONS,
  MQ: DEFAULT_PERMISSIONS,
  MR: DEFAULT_PERMISSIONS,
  MS: DEFAULT_PERMISSIONS,
  MT: DEFAULT_PERMISSIONS,
  MU: DEFAULT_PERMISSIONS,
  MV: DEFAULT_PERMISSIONS,
  MW: DEFAULT_PERMISSIONS,
  MX: DEFAULT_PERMISSIONS,
  MY: DEFAULT_PERMISSIONS,
  MZ: DEFAULT_PERMISSIONS,
  NA: DEFAULT_PERMISSIONS,
  NC: DEFAULT_PERMISSIONS,
  NE: DEFAULT_PERMISSIONS,
  NF: DEFAULT_PERMISSIONS,
  NG: DEFAULT_PERMISSIONS,
  NI: DEFAULT_PERMISSIONS,
  NL: DEFAULT_PERMISSIONS,
  NO: DEFAULT_PERMISSIONS,
  NP: DEFAULT_PERMISSIONS,
  NR: DEFAULT_PERMISSIONS,
  NU: DEFAULT_PERMISSIONS,
  NZ: DEFAULT_PERMISSIONS,
  OM: DEFAULT_PERMISSIONS,
  PA: DEFAULT_PERMISSIONS,
  PE: DEFAULT_PERMISSIONS,
  PF: DEFAULT_PERMISSIONS,
  PG: DEFAULT_PERMISSIONS,
  PH: DEFAULT_PERMISSIONS,
  PK: DEFAULT_PERMISSIONS,
  PL: DEFAULT_PERMISSIONS,
  PM: DEFAULT_PERMISSIONS,
  PN: DEFAULT_PERMISSIONS,
  PR: DEFAULT_PERMISSIONS,
  PS: DEFAULT_PERMISSIONS,
  PT: DEFAULT_PERMISSIONS,
  PW: DEFAULT_PERMISSIONS,
  PY: DEFAULT_PERMISSIONS,
  QA: DEFAULT_PERMISSIONS,
  RE: DEFAULT_PERMISSIONS,
  RO: DEFAULT_PERMISSIONS,
  RS: DEFAULT_PERMISSIONS,
  RU: DEFAULT_PERMISSIONS,
  RW: DEFAULT_PERMISSIONS,
  SA: DEFAULT_PERMISSIONS,
  SB: DEFAULT_PERMISSIONS,
  SC: DEFAULT_PERMISSIONS,
  SD: DEFAULT_PERMISSIONS,
  SE: DEFAULT_PERMISSIONS,
  SG: DEFAULT_PERMISSIONS,
  SH: DEFAULT_PERMISSIONS,
  SI: DEFAULT_PERMISSIONS,
  SJ: DEFAULT_PERMISSIONS,
  SK: DEFAULT_PERMISSIONS,
  SL: DEFAULT_PERMISSIONS,
  SM: DEFAULT_PERMISSIONS,
  SN: DEFAULT_PERMISSIONS,
  SO: DEFAULT_PERMISSIONS,
  SR: DEFAULT_PERMISSIONS,
  SS: DEFAULT_PERMISSIONS,
  ST: DEFAULT_PERMISSIONS,
  SV: DEFAULT_PERMISSIONS,
  SX: DEFAULT_PERMISSIONS,
  SY: DEFAULT_PERMISSIONS,
  SZ: DEFAULT_PERMISSIONS,
  TC: DEFAULT_PERMISSIONS,
  TD: DEFAULT_PERMISSIONS,
  TF: DEFAULT_PERMISSIONS,
  TG: DEFAULT_PERMISSIONS,
  TH: DEFAULT_PERMISSIONS,
  TJ: DEFAULT_PERMISSIONS,
  TK: DEFAULT_PERMISSIONS,
  TL: DEFAULT_PERMISSIONS,
  TM: DEFAULT_PERMISSIONS,
  TN: DEFAULT_PERMISSIONS,
  TO: DEFAULT_PERMISSIONS,
  TR: DEFAULT_PERMISSIONS,
  TT: DEFAULT_PERMISSIONS,
  TV: DEFAULT_PERMISSIONS,
  TW: DEFAULT_PERMISSIONS,
  TZ: DEFAULT_PERMISSIONS,
  UA: DEFAULT_PERMISSIONS,
  UG: DEFAULT_PERMISSIONS,
  UM: DEFAULT_PERMISSIONS,
  US: [
    ...DEFAULT_PERMISSIONS,
    ACH_WITH_INSTANT_BUY_PERMISSION,
    APPLE_PAY_PERMISSION,
    APPLE_PAY_PERMISSION_V2,
  ],
  UY: DEFAULT_PERMISSIONS,
  UZ: DEFAULT_PERMISSIONS,
  VA: DEFAULT_PERMISSIONS,
  VC: DEFAULT_PERMISSIONS,
  VE: DEFAULT_PERMISSIONS,
  VG: DEFAULT_PERMISSIONS,
  VI: DEFAULT_PERMISSIONS,
  VN: DEFAULT_PERMISSIONS,
  VU: DEFAULT_PERMISSIONS,
  WF: DEFAULT_PERMISSIONS,
  WS: DEFAULT_PERMISSIONS,
  YE: DEFAULT_PERMISSIONS,
  YT: DEFAULT_PERMISSIONS,
  ZA: DEFAULT_PERMISSIONS,
  ZM: DEFAULT_PERMISSIONS,
  ZW: DEFAULT_PERMISSIONS,
});

export const useIsCountryAllowed = (code: string | undefined | null) => {
  if (!code) return false;

  const permissions = countryPermissionMap[code];

  return Boolean(permissions) && permissions.length > 0;
};

const getPermissionValidator = (type: PaymentMethodPermission) => {
  switch (type) {
    case 'crypto_wallet':
      return CRYPTO_WALLET_PERMISSION;
    case 'non_3ds_card':
      return NON_3DS_DEBIT_CARD_PERMISSION;
    case '3ds_card':
      return THREE_DS_DEBIT_CARD_PERMISSION;
    case 'instant_ach':
      return ACH_WITH_INSTANT_BUY_PERMISSION;
    case 'fiat_wallet':
      return FIAT_WALLET_PERMISSION;
    case 'apple_pay':
      return APPLE_PAY_PERMISSION;
    case 'apple_pay_v2':
      return APPLE_PAY_PERMISSION_V2;
    default:
      return undefined;
  }
};

export const isPaymentMethodSupportedByCountry = (
  code: string | undefined | null,
  type: PaymentMethodPermission,
) => {
  const permissions = countryPermissionMap[code || ''];
  if (!permissions) {
    return false;
  }
  const validator = getPermissionValidator(type);
  return permissions.some((p) => p === validator);
};
