import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import { useReportAndViewError } from '@onramp/hooks/useReportAndViewError';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { HandledError } from '@onramp/utils/errors';
import { isMobileExperience } from '@onramp/utils/postMessage';

const messages = defineMessages({
  unsupportedAssetTitle: {
    id: 'useValidateFilteredAssets.unsupportedAssetTitle',
    defaultMessage: 'Unsupported asset',
    description: 'Title for when a digital asset is not supported by our app',
  },
  unsupportedNetworkTitle: {
    id: 'useValidateFilteredAssets.unsupportedNetworkTitle',
    defaultMessage: 'Unsupported network',
    description: 'Title for when a network is not supported by our app',
  },
  singleAssetNotSupported: {
    id: 'useValidateFilteredAssets.singleAssetNotSupported',
    defaultMessage:
      "{asset} isn't supported on Coinbase. You can go to a decentralized exchange to buy it.",
    description: 'Error message when a digital asset is not supported for purchase on our app',
  },
  assetsNotSupported: {
    id: 'useValidateFilteredAssets.assetsNotSupported',
    defaultMessage:
      "The assets you're trying to add aren't supported on Coinbase. You can go to a decentralized exchange to buy them.",
    description: 'Error message when a digital asset is not supported for purchase on our app',
  },
  networkNotSupported: {
    id: 'useValidateFilteredAssets.networkNotSupported',
    defaultMessage:
      "The network you're trying to add assets for isn't supported on Coinbase. You can go to a decentralized exchange to buy them.",
    description: 'Error message when a blockchain is not supported for purchase on our app',
  },
  assetListFail: {
    id: 'useValidateFilteredAssets.assetListFail',
    defaultMessage: 'Failed to load asset list.',
    description: 'Error on loading asset list',
  },
  assetUnavailableTitle: {
    id: 'useValidateFilteredAssets.assetUnavailableTitle',
    defaultMessage: '{ticker} is not available',
    description: 'Title for when ticker is unavailable',
  },
  navigateToPartnerAppErrorMessage: {
    id: 'useValidateFilteredAssets.navigateToPartnerAppErrorMessage',
    defaultMessage: 'Please navigate back to the partner app and select a different asset to buy.',
    description: 'Error message when a digital asset is not supported for purchase on our app',
  },
});

/**
 * Developers may pass either an asset or a network we don't support.
 * Usually these would be ignored if there's other valid wallet/asset/blockchain configs available.
 * If not, we display an error message relating to the context on what the developer is passing in.
 * - If there's tickers in the `tickerAllowList` = unsupported assets were passed in.
 * - If there's no tickers = an unsupported network was passed in
 */
export const useValidateAssets = (filteredAssets: unknown[], searchQuery: string) => {
  const reportAndViewError = useReportAndViewError();
  const { formatMessage } = useIntl();
  const { shouldAutoSelectAsset, singleAssetsRequested } = useDestinationWalletsV2();
  const { destinationWallets } = useWidgetParams('buy');

  useEffect(() => {
    if (filteredAssets.length === 0 && searchQuery.length === 0) {
      const isNetworkPassed = singleAssetsRequested.length === 0;

      const errorMessage = (() => {
        if (shouldAutoSelectAsset) {
          return formatMessage(messages.navigateToPartnerAppErrorMessage);
        }
        if (isNetworkPassed) {
          return formatMessage(messages.networkNotSupported);
        }
        return formatMessage(messages.assetsNotSupported);
      })();

      reportAndViewError(
        new HandledError({
          message: errorMessage,
          debugMessage: 'Unsupported asset or network error',
        })
          .addHandlingParams({
            backType: 'none',
            title: isNetworkPassed
              ? formatMessage(messages.unsupportedNetworkTitle)
              : formatMessage(messages.assetUnavailableTitle, {
                  ticker: singleAssetsRequested[0],
                }),
            pictogramName: 'hero:networkWarning',
            showExitButton: isMobileExperience(),
          })
          .addMetadata({
            blockchains: destinationWallets[0]?.blockchains,
            assets: destinationWallets[0]?.assets,
          }),
      );
    }
  }, [
    destinationWallets,
    filteredAssets,
    formatMessage,
    reportAndViewError,
    searchQuery,
    shouldAutoSelectAsset,
    singleAssetsRequested,
  ]);
};
