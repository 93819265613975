// eslint-disable-next-line no-restricted-imports
import type { Response } from '@onramp/server/types';
// eslint-disable-next-line no-restricted-imports
import { isEligibleForOneClickBuy } from '@onramp/server/utils/isEligibleForOneClickBuy';
import type { KillSwitchesConfigType } from '@onramp/utils/killswitches/killSwitchConfiguration';
import { persistQueryParamsFromSearchParams } from '@onramp/utils/persistQueryParams';
import type { BaseRouteKey } from '@onramp/utils/routes';
import { getIsRedirectExemptRoute, getPathType, Routes } from '@onramp/utils/routes';
import type { AppContext } from 'next/app';
import { z } from 'zod';

import { checkGuestCheckoutEligibility } from './checkGuestCheckoutEligibility';
/**
 * Check that all expected query parameters are present.
 * If not, redirect user to coinbase.com.
 * Returns true when a redirect response was sent.
 */
export function handleNonIgnoredPaths(
  reqUrl: URL,
  query: AppContext['router']['query'],
  reqQuery: Record<string, string>,
  reqBody: Record<string, string>,
  res: Response,
): boolean {
  const route = reqUrl.pathname;
  const pathType = getPathType(route);
  const { locals } = res;

  const isRedirectExemptRoute = getIsRedirectExemptRoute({ route, pathType });
  const accessToken: string = locals.accessToken || '';
  const refreshToken: string = locals.refreshToken || '';
  const unifiedSessionManagerCookie = locals.unifiedSessionManagerCookie || '';
  const hasAtLeastOneToken =
    Boolean(accessToken) || Boolean(refreshToken) || Boolean(unifiedSessionManagerCookie);

  // Paths we don't want to validate and redirect
  const isIgnoredPath = isRedirectExemptRoute || route === '/404' || pathType === 'embed'; // TODO [ONRAMP-1729]: move these to route exempt
  if (isIgnoredPath) {
    return false;
  }

  const { isAllowedToUseGuestCheckout } = checkGuestCheckoutEligibility(query, res);
  // RedirectOnInit just redirects the user to authed flow this is there we make sure to redirect the user to signin
  if (!process.env.JWT_OVERRIDE && pathType === 'buy' && !route.startsWith('/buy/guest')) {
    if (!hasAtLeastOneToken) {
      const redirectUri = `${Routes.Signin}?${persistQueryParamsFromSearchParams(
        reqUrl.searchParams,
      )}`;
      res.logger.info(`[ServerRedirects] Redirecting to signin due to missing auth token`, {
        redirectTo: redirectUri,
      });
      res.locals.statsd?.increment('server_redirect', { reason: 'missing_auth_token' });
      res.writeHead(302, {
        Location: redirectUri,
      });
      res.end();
      return true;
    }
  }

  // Access killswitches on SSR to validate default route
  const { killSwitches } = locals;
  /**
   * If not the default route, redirect to the default route.
   * asPath contains all query params, and route is just the pathname.
   * Doing a simple replace preserves query params
   */
  const redirectTo = validateDefaultRoute(
    pathType,
    route,
    killSwitches,
    isAllowedToUseGuestCheckout,
    reqQuery,
    reqBody,
    res,
  );
  if (redirectTo) {
    const redirectUri = new URL(reqUrl, process.env.NEXT_PUBLIC_ONRAMP_BASE_URL);
    redirectUri.pathname = redirectTo;
    res.logger.info(`[ServerRedirects] Redirecting to ${redirectTo} due to non default route`, {
      redirectTo: redirectUri.toString(),
    });
    res.locals.statsd?.increment('server_redirect', { reason: 'non_default_route', redirectTo });
    res.writeHead(307, { Location: redirectUri.toString() });
    res.end();
    return true;
  }

  return false;
}

function validateDefaultRoute(
  pathType: BaseRouteKey,
  route: string,
  killSwitches: KillSwitchesConfigType | undefined,
  isAppIdAllowedForGuestCheckout: boolean,
  reqQuery: Record<string, string>,
  reqBody: unknown,
  res: Response,
) {
  // The v2 input page changes the valid initial routes to v2 input & OCB
  // Need to check pathType to allow e.g. embed, 3ds return
  if (pathType === 'buy' && route !== Routes.V2.OnrampInput) {
    if (route === Routes.Buy.OneClick && !isEligibleForOneClickBuy(reqQuery, reqBody, res)) {
      return undefined;
    }
  }

  if (route.startsWith('/buy/guest')) {
    if (!isAppIdAllowedForGuestCheckout || killSwitches?.kill_cbpay_guest_checkout) {
      return Routes.Buy.SelectAsset;
    }

    if (route !== Routes.Landing) {
      return Routes.Landing;
    }

    return undefined;
  }

  const isOneClickBuyKilled = killSwitches?.kill_one_click_buy;
  if (
    pathType === 'buy' &&
    route !== Routes.Buy.SelectAsset &&
    (!isOneClickBuyKilled ? route !== Routes.Buy.OneClick : true)
  ) {
    return Routes.Buy.SelectAsset;
  }

  return undefined;
}

export const QueryParamSchema = z.object({
  appId: z.string(),
});

export const SecureStandaloneQueryParamSchema = z.object({
  appId: z.string(),
  type: z.string(),
});

export const SecureInitQueryParamSchema = z.object({
  sessionToken: z.string(),
});
