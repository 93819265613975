import { isDevelopment } from '@onramp/utils/environment/sharedEnv';
import { useInitParams } from '@onramp/v2/client/contexts/initParams/InitParamsContext';

const SCW_APP_ID = '1efee441-2f33-635d-9f21-128dac1fc074';
const DEV_SCW_APP_ID = '558b75d2-f532-4b46-a752-43a382ea2b5f';

export function useIsSmartContractWallet() {
  const {
    internalInitParams: {
      clientAppDetails: { appId },
    },
  } = useInitParams();

  if (appId === SCW_APP_ID) {
    return true;
  }

  return isDevelopment && appId === DEV_SCW_APP_ID;
}
