import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import Bugsnag from '@bugsnag/js';
import { QUERY_KEYS } from '@onramp/config/queryKeys';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { bugsnagEnabled } from '@onramp/utils/environment/sharedEnv';
import { logEvent } from '@onramp/utils/eventing/logEvent';
import type { ExperimentTestName } from '@onramp/utils/experiments/experiments';
import {
  Experiments,
  exposeExperiment,
  getExperimentSubjectType,
} from '@onramp/utils/experiments/experiments';
import { fetchExperiments } from '@onramp/utils/experiments/fetchExperiments';
import { SubjectType } from '@cbhq/client-analytics';
import type { ArrayFromExperiments, Experiments as ExperimentsType } from '@cbhq/experiments';
import { ExperimentProvider } from '@cbhq/experiments';

type ExperimentationProviderProps = {
  children: React.ReactNode;
};

export const ExperimentationProvider: React.FC<ExperimentationProviderProps> = ({ children }) => {
  const { data: fetchedExperiments } = useQuery<
    Awaited<ReturnType<typeof fetchExperiments>>,
    unknown,
    ArrayFromExperiments<ExperimentsType>
  >(QUERY_KEYS.EXPERIMENTS, fetchExperiments, {
    // Transform API response
    select: (data) => {
      return data.groups.map((group): ArrayFromExperiments<ExperimentsType>[number] => ({
        name: group.test,
        group: group.group,
        skipTracking: !group.isTracked,
      }));
    },
  });

  const experiments = useMemo(
    () => (typeof fetchedExperiments !== 'undefined' ? fetchedExperiments : []),
    [fetchedExperiments],
  );

  useEffect(() => {
    // Once data is fetched/massaged, set Bugsnag features.
    const relevantExperiments = experiments.filter((experiment) => experiment.name in Experiments);

    if (bugsnagEnabled) {
      Bugsnag.clearFeatureFlags();
      Bugsnag.addFeatureFlags(
        relevantExperiments.map((experiment) => ({
          name: experiment.name,
          variant: experiment.group,
        })),
      );
    }
  }, [experiments]);

  return (
    <ExperimentProvider experiments={experiments} trackExposure={trackExposure}>
      {children}
    </ExperimentProvider>
  );
};

const trackExposure = (testName: string, group: string) => {
  const subjectType = getExperimentSubjectType(testName);

  const subjectId = (() => {
    if (subjectType === SubjectType.device) {
      return clientSessionIdStore.getDeviceId();
    }

    if (subjectType === SubjectType.wallet_user) {
      return clientSessionIdStore.getWalletUserId();
    }

    if (subjectType === SubjectType.custom) {
      return clientSessionIdStore.getAppId();
    }

    return undefined;
  })();

  exposeExperiment({
    testName,
    group,
    subjectId,
    subjectType,
    logEvent: (name, metadata, importance) =>
      logEvent(name, metadata, {
        importance,
        broadcastToSDK: subjectType === SubjectType.wallet_user,
      }),
  });

  clientSessionIdStore.addExposedExperiment({ experiment: testName as ExperimentTestName, group });
};
