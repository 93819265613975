import fromPairs from 'lodash/fromPairs';
import type { z } from 'zod';

export const getQueryParams = () => new URL(window.location.href).searchParams;
export const getQueryParamsAsRecord = () => fromPairs([...getQueryParams().entries()]);

export const getQueryParamsObjectFromZodSchema = <T extends z.ZodObject<z.ZodRawShape>>(
  schema: T | z.ZodEffects<T>,
  /** query params parsed as a `Record`, like the result of {@link getQueryParamsAsRecord getQueryParamsAsRecord} — needed for server support */
  queryParams: Record<string, unknown> | undefined,
): z.infer<T> => {
  const params = queryParams ?? getQueryParamsAsRecord();

  const shape = (() => {
    if ('shape' in schema) {
      return schema.shape;
    }

    return schema._def.schema.shape;
  })();

  // Attempt to parse param as a string.
  // On error, attempt to parse JSON.parsed param.
  return Object.keys(shape).reduce<Record<string, unknown>>((acc, curr) => {
    acc[curr] = params[curr] || undefined;
    if (acc[curr]) {
      try {
        const decoded = decodeURIComponent(acc[curr] as string);

        const normalParseResult = shape[curr].safeParse(decoded);
        if (normalParseResult.success) {
          acc[curr] = normalParseResult.data;
          return acc;
        }

        const jsonParseResult = shape[curr].safeParse(JSON.parse(decoded));
        if (jsonParseResult.success) {
          acc[curr] = jsonParseResult.data;
        }
      } catch {
        // noop
      }
    }
    return acc;
  }, {});
};
