import { useEffect } from 'react';
import { useCountryCodeLoadable } from '@onramp/state/recoil/selectors/countryCodeSelector';
import { Experiments } from '@onramp/utils/experiments/experiments';
import { useIsTreatment } from '@cbhq/experiments';

import { useLogWidgetEventOnce } from './eventing/useLogWidgetEvent';
import { useKillSwitches } from './killswitches/useKillSwitches';
import { checkIfApplePayIsSupported } from './applePay';
import { clientSessionIdStore } from './clientSessionIdStore';
import { isPaymentMethodSupportedByCountry } from './getIsCountryAllowed';

export function useIsApplePayAvailable() {
  const isApplePayKilled = useKillSwitches().kill_cbpay_applepay;

  const featureFlags = clientSessionIdStore.getFeatureFlags();
  const logWidgetEvent = useLogWidgetEventOnce();
  const countryLoadable = useCountryCodeLoadable();
  const country = countryLoadable.state === 'hasData' ? countryLoadable.data : undefined;

  const isApplePayEnabledForClient = featureFlags?.applePayEnabled ?? false;
  const isAppIdInCKOExperiment = useIsTreatment(Experiments.mar_2025_onramp_auth_apple_cko_appid);
  const isDeviceIdInCKOExperiment = useIsTreatment(
    Experiments.mar_2025_onramp_auth_apple_cko_device_id,
    {
      skipTracking: !isAppIdInCKOExperiment,
    },
  );
  const isInCkoExperiment = isAppIdInCKOExperiment && isDeviceIdInCKOExperiment;
  const isApplePayAllowedInCountry = isPaymentMethodSupportedByCountry(
    country,
    isInCkoExperiment ? 'apple_pay_v2' : 'apple_pay',
  );

  // if ApplePay isn't supported, we shouldn't track this as exposure to the experiments
  // this is checking for version support & registered debit card
  const isApplePaySupported = checkIfApplePayIsSupported();

  const isIos = clientSessionIdStore.getPlatformAttribution() === 'ios';
  // this is checking for project ID support
  const isApplePayEnabledForProject = featureFlags?.applePayEnabled || isInCkoExperiment;

  const result =
    !isApplePayKilled &&
    isApplePaySupported &&
    isApplePayEnabledForProject &&
    isApplePayAllowedInCountry;

  useEffect(() => {
    const canMakePayments = (() => {
      try {
        const ApplePaySession = typeof window !== 'undefined' ? window.ApplePaySession : undefined;
        if (!ApplePaySession) return false;

        return Boolean(ApplePaySession.canMakePayments());
      } catch {
        return false;
      }
    })();

    const supportsVersion6 = (() => {
      try {
        const ApplePaySession = typeof window !== 'undefined' ? window.ApplePaySession : undefined;
        if (!ApplePaySession) return false;

        return Boolean(ApplePaySession.supportsVersion(6));
      } catch {
        return false;
      }
    })();

    logWidgetEvent('apple_pay_availability_metadata', {
      isApplePayEnabledForClient,
      isIos,
      isApplePaySupported,
      isApplePaySessionDefined: Boolean(window.ApplePaySession),
      isApplePayKilled,
      supportsVersion6,
      canMakePayments,
      isInCkoExperiment,
      isAppIdInCKOExperiment,
      isDeviceIdInCKOExperiment,
    });
  }, [
    isAppIdInCKOExperiment,
    isApplePayEnabledForClient,
    isApplePayKilled,
    isApplePaySupported,
    isDeviceIdInCKOExperiment,
    isInCkoExperiment,
    isIos,
    logWidgetEvent,
    result,
  ]);

  return result;
}
