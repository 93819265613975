import type { Store } from '@onramp/v2/client/views/guest/onramp/state/types';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

/**
 * This atom will hold the store for the V2 Guest Checkout flow.
 * Add any additional global state needed for the flow here.
 * We will migrate the rest of the atoms below into this store soon.
 */
export const guestOnrampV2StoreAtom = atomWithImmer<Store>({
  selectedAsset: undefined,
  selectedNetwork: undefined,
  inputAmount: {
    userAmount: { value: '0', currency: 'USD' },
    inputType: 'fiat',
  },
  guestSessionAuthorizationCode: '',
  guestCheckoutTOSAcceptDate: undefined,
  billingAddress: undefined,
  tokenizedCardData: undefined,
  transactionLimitShown: false,

  pageErrors: {
    cardInputFormError: undefined,
    changeNetworkError: undefined,
    editOrderError: undefined,
    selectAssetError: undefined,
    hasAvsError: false,
  },
  flags: {
    hasContinuedAsGuest: false,
  },
  createSessionData: undefined,
  authorizeSessionData: undefined,
  createGCTransactionData: undefined,
  commitGCTransactionData: undefined,
  polledTransactionData: undefined,
  email: undefined,
  paymentMethod: undefined,
  phoneNumber: undefined,
  persistedGuestSession: undefined,
  createGuestMagicSpendTransactionData: undefined,
  commitGuestMagicSpendTransactionData: undefined,
});

export const useGuestOnrampV2StoreValue = () => useAtomValue(guestOnrampV2StoreAtom);
export const useUpdateGuestOnrampV2Store = () => useSetAtom(guestOnrampV2StoreAtom);
