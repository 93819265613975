import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Loader } from '@onramp/components/Loader';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { getRouteWithParams } from '@onramp/hooks/useOnRampRouter';
import { useIsLoggedInEgwSessionManager } from '@onramp/usm/useIsLoggedInEgwSessionManager';

const messages = defineMessages({
  loading: {
    id: `ActiveSessionProviderV2.loading`,
    defaultMessage: 'Wait for a moment...',
    description: 'Loading message instructing the user to wait.',
  },
  redirecting: {
    id: `ActiveSessionProviderV2.redirecting`,
    defaultMessage: 'Redirecting to sign in...',
    description: 'Loading message indicating that the user will be redirected to login.',
  },
  title: {
    id: `ActiveSessionProviderV2.title`,
    defaultMessage: 'Sign in to Coinbase',
    description: 'Header title while checking if the user is logged in',
  },
});

export const ActiveSessionProviderV2: React.FC = ({ children }) => {
  const isLoggedIn = useIsLoggedInEgwSessionManager();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!isLoggedIn) {
      const currentPath = window.location.pathname;
      const usmSignInRoute = getRouteWithParams('V2Signin');
      if (currentPath !== '') {
        window.location.assign(`${usmSignInRoute}`);
      }
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    <PageWrapper headerText={formatMessage(messages.title)}>
      <Loader
        subText={
          !isLoggedIn ? formatMessage(messages.redirecting) : formatMessage(messages.loading)
        }
      />
    </PageWrapper>;
  }

  return <>{children}</>;
};
