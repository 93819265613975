import { useCallback, useContext, useEffect } from 'react';
import { InitParamsContext } from '@onramp/v2/client/contexts/initParams/InitParamsContext';
import { getErrorVitals } from '@onramp/v2/client/utils/initErrorVitals';
import { doOncePerSession } from '@onramp/v2/client/utils/session/doOncePerSession';
import { useOnrampInputStoreValue } from '@onramp/v2/client/views/onramp/state/store';
import type { LogMetric } from '@cbhq/client-analytics';
import { logMetric } from '@cbhq/client-analytics';

type Tags = Record<string, string | boolean> | undefined;

export const useLogOnrampMetricV2 = () => {
  const context = useContext(InitParamsContext);
  const {
    flags: { hasContinuedFromInput },
  } = useOnrampInputStoreValue();

  const logMetricWithDefaultTags = useCallback(
    (metric: LogMetric) => {
      const initParams = context?.initParams ?? {};
      const tags = {
        ...metric.tags,
        hasContinuedFromInput,
      };

      if (!context) {
        getErrorVitals().logError(
          new Error('useLogGuestMetric must be used within a InitParamsProvider'),
          {
            context: 'useLogGuestMetric',
            severity: 'critical',
            isHandled: false,
          },
        );
      }

      if (context !== undefined) {
        logMetric({
          ...metric,
          tags: {
            ...tags,
            appId: initParams.appId,
          },
        });
      } else {
        logMetric({
          ...metric,
          tags,
        });
      }
    },
    [context, hasContinuedFromInput],
  );

  return logMetricWithDefaultTags;
};

export const useLogOnrampMetricOnceV2 = () => {
  const logOrampMetric = useLogOnrampMetricV2();

  const logMetricOnce = useCallback(
    (metric: LogMetric, tags?: Tags) => {
      const logOnce = doOncePerSession(logOrampMetric, metric.metricName);
      logOnce({ ...metric, tags: { ...metric.tags, ...tags } });
    },
    [logOrampMetric],
  );

  return logMetricOnce;
};

export const useLogOnrampMetricOnMountV2 = (metric: LogMetric) => {
  const logOnrampMetricOnce = useLogOnrampMetricOnceV2();

  useEffect(() => {
    logOnrampMetricOnce(metric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
