import { defineMessages, useIntl } from 'react-intl';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Link as CDSLink, TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import EmbedSafeAnchor from '../embedding/EmbedSafeAnchor';

export function BlockedUkUserOverlay() {
  const { formatMessage } = useIntl();

  return (
    <VStack
      flexGrow={1}
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      spacing={3}
      testID="blocked-uk-user-overlay"
    >
      <VStack alignItems="center">
        <Box spacingTop={6} spacingBottom={3}>
          <HeroSquare name="restrictedCountry" />
        </Box>
        <TextTitle3 as="h3" align="center" spacingBottom={2}>
          {formatMessage(messages.blockedUkUserTitle)}
        </TextTitle3>
        <TextBody as="p" align="center" color="foregroundMuted">
          {formatMessage(messages.blockedUkUserMessage, {
            CoinbaseLink,
          })}
        </TextBody>
      </VStack>
    </VStack>
  );
}

const CoinbaseLink = (text: string) => {
  return (
    <EmbedSafeAnchor href="https://coinbase.com">
      <CDSLink underline={false}>
        <TextBody as="span" color="primary">
          {text}
        </TextBody>
      </CDSLink>
    </EmbedSafeAnchor>
  );
};

const messages = defineMessages({
  blockedUkUserTitle: {
    id: 'BlockedUkUserOverlay.blockedUkUserTitle',
    defaultMessage: 'Not available in your country',
    description: 'Error title for blocked UK users',
  },
  blockedUkUserMessage: {
    id: 'BlockedUkUserOverlay.blockedUkUserMessage',
    defaultMessage:
      'Coinbase Onramp is only available in the UK for some apps. You can still send crypto from <CoinbaseLink>Coinbase</CoinbaseLink> to your wallet.',
    description: 'Error message for blocked UK users',
  },
});
