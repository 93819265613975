import {
  ActionType,
  AnalyticsEventImportance,
  ComponentType,
  exposeExperiment as ccaExposeExperiment,
  SubjectType,
} from '@cbhq/client-analytics';
import { createExperiments } from '@cbhq/experiments';

import type { EventData, EventName } from '../eventing/logEvent';

export const deviceExperimentConfig = {
  // eslint-disable-next-line local-rules/experiment-name-conventions
  mar_2023_cbpay_require_gssc_auth: ['control', 'treatment'],
  feb_2025_onramp_guest_save_session: ['control', 'treatment'],
  mar_2025_onramp_auth_apple_cko_device_id: ['control', 'treatment'],
} as const;

export const walletExperimentConfig = {
  // eslint-disable-next-line local-rules/experiment-name-conventions
  oct_2023_cbpay_frames_internal_testing: ['control', 'treatment'],
  jun_2024_onramp_account_redesign: ['control', 'treatment'],
  oct_2024_onramp_guest_apple_pay: ['control', 'treatment'],
  feb_2025_onramp_guest_save_session_testing: ['control', 'treatment'],
  jan_2025_onramp_usm: ['control', 'treatment'],
} as const;

export const appIDExpimentConfig = {
  mar_2025_onramp_auth_apple_cko_appid: ['control', 'treatment'],
} as const;

export const experimentNames = [
  ...Object.keys(deviceExperimentConfig),
  ...Object.keys(walletExperimentConfig),
  ...Object.keys(appIDExpimentConfig),
];

export const getExperimentSubjectType = (test: string) => {
  if (test in walletExperimentConfig) {
    return SubjectType.wallet_user;
  }

  if (test in deviceExperimentConfig) {
    return SubjectType.device;
  }

  if (test in appIDExpimentConfig) {
    return SubjectType.custom as SubjectType;
  }

  return undefined;
};

export const Experiments = createExperiments({
  ...deviceExperimentConfig,
  ...walletExperimentConfig,
  ...appIDExpimentConfig,
});

export type ExperimentTestName = keyof typeof Experiments;
export type CBPayExperimentType = typeof Experiments[ExperimentTestName];

// Exposes a union of the variants for a given experiment
export type ExperimentVariants<E extends keyof typeof Experiments> =
  typeof Experiments[E]['groups'][number];

export function exposeExperiment({
  testName,
  group,
  subjectId,
  subjectType,
  logEvent,
  customSubjectType,
}: {
  group: string;
  testName: string;
  subjectId: string | undefined;
  subjectType: SubjectType | undefined;
  customSubjectType?: string;
  logEvent: (
    eventName: EventName,
    metadata: EventData,
    importance: AnalyticsEventImportance,
  ) => void;
}) {
  if (subjectType && subjectId) {
    const typedSuibjectType = customSubjectType as string;
    ccaExposeExperiment({
      group,
      testName,
      subjectId,
      subjectType,
      customSubjectType: typedSuibjectType,
    });
  }

  /**
   * This event's goal is to have our own experiment exposure event where we can attach relevant metadata that `split_test_exposed` & `split_test_device_exposed`
   * do not accept, like `subjectType`. This also won't trigger `cifer.exposure` metrics, preventing exposure from being reported twice
   */
  logEvent(
    'onramp.experiment_exposed',
    {
      action: ActionType.measurement,
      componentType: ComponentType.unknown,
      group,
      testName,
      subjectId,
      subjectType,
      customSubjectType,
    },
    AnalyticsEventImportance.high,
  );

  if (subjectType === SubjectType.device) {
    logEvent(
      'split_test_device_exposed',
      {
        action: ActionType.measurement,
        componentType: ComponentType.page,
        loggingId: '9bf34231-55f9-4814-89c6-d3ebebfbbe07',
        group,
        test: testName,
      },
      AnalyticsEventImportance.high,
    );
  }
}
