import { defineMessages, useIntl } from 'react-intl';
import { Loader } from '@onramp/components/Loader';
import { PageWrapper } from '@onramp/components/PageWrapper';

const messages = defineMessages({
  loading: {
    id: 'GenericLoadingScreen.loading',
    description: 'Header indicating loading status.',
    defaultMessage: 'Loading',
  },
});

export const GenericLoadingScreen = () => {
  const { formatMessage } = useIntl();

  return (
    <PageWrapper headerText={formatMessage(messages.loading)} hideHamburgerMenu>
      <Loader />
    </PageWrapper>
  );
};
