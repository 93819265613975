import { createContext, useContext, useMemo, useState } from 'react';
import type { MagicSpendParams } from '@onramp/shared/magicSpendParams.schema';
import type { InitParamsType, InternalInitParamsType } from '@onramp/types/initParams';

type InitParamsContextType = {
  initParams: InitParamsType;
  internalInitParams: InternalInitParamsType;
  magicSpendParams: MagicSpendParams;
};

const defaultInternalInitParams = {
  clientAppDetails: {
    appId: '',
    oauthId: '',
    domains: [],
    displayName: '',
    isActive: false,
    isSecureInitRequired: false,
    isThirdParty: false,
  },
};

const defaultMagicSpendParams = {
  appId: '',
  ownerAddress: '',
  toAddresses: [] as string[],
  chainId: 0,
  amount: '',
  asset: '',
  mocked: false,
  paymentMethod: 'CARD' as const,
};

export const InitParamsContext = createContext<InitParamsContextType>({
  initParams: {
    appId: '',
    destinationWallets: [],
  },
  internalInitParams: defaultInternalInitParams,
  magicSpendParams: defaultMagicSpendParams,
});

export const InitParamsProvider: React.FC<{
  initParamsFromServer: InitParamsType;
  internalInitParamsFromServer: InternalInitParamsType;
  magicSpendParamsFromServer: MagicSpendParams | undefined;
}> = ({
  children,
  initParamsFromServer,
  internalInitParamsFromServer,
  magicSpendParamsFromServer,
}) => {
  const [initParams] = useState<InitParamsType>(initParamsFromServer ?? {});
  const [internalInitParams] = useState<InternalInitParamsType>(internalInitParamsFromServer);
  const [magicSpendParams] = useState<MagicSpendParams>(
    magicSpendParamsFromServer ?? defaultMagicSpendParams,
  );

  const value = useMemo(
    () => ({
      initParams,
      internalInitParams,
      magicSpendParams,
    }),
    [initParams, internalInitParams, magicSpendParams],
  );

  return <InitParamsContext.Provider value={value}>{children}</InitParamsContext.Provider>;
};

export const useInitParams = () => {
  const context = useContext(InitParamsContext);
  if (context === undefined) {
    throw new Error('useInitParams must be used within a InitParamsProvider');
  }
  return context;
};
