/* eslint-disable formatjs/no-literal-string-in-jsx, react-perf/jsx-no-new-function-as-prop */
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ComponentType } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@onramp/config/queryKeys';
import { useCanSeeInternalInfo } from '@onramp/hooks/useCanSeeInternalInfo';
import { useDynamicKillSwitchPrefixes } from '@onramp/hooks/usePrefixedKillSwitches';
import { useLogout } from '@onramp/hooks/utils/useLogout';
import {
  isDebug3dsEnabledAtom,
  isDebug3dsEnabledMessage,
  isMockBuyAndSendEnabledAtom,
  isMockBuyAndSendEnabledMessage,
} from '@onramp/state/recoil/atoms/debugAtoms';
import { jotaiStore } from '@onramp/state/recoil/utils';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { clearCookie } from '@onramp/utils/cookies/clientUtils';
import copyToClipboard from '@onramp/utils/copyToClipboard';
import { isProduction } from '@onramp/utils/environment/sharedEnv';
import { useLogWidgetEvent } from '@onramp/utils/eventing/useLogWidgetEvent';
import { Experiments } from '@onramp/utils/experiments/experiments';
import { setExperimentCookie } from '@onramp/utils/experiments/fetchExperiments';
import {
  deduplicateExperimentArrays,
  getExperimentsFromCookieName,
} from '@onramp/utils/experiments/utils';
import {
  getKillSwitchesFromCookieName,
  setKillSwitchCookie,
} from '@onramp/utils/killswitches/fetchKillSwitches';
import type { KillSwitchesConfigType } from '@onramp/utils/killswitches/killSwitchConfiguration';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import { atom, useAtom, useAtomValue } from 'jotai';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import type { ListCellProps } from '@cbhq/cds-web/cells';
import { ListCell } from '@cbhq/cds-web/cells';
import { SearchInput, Select, SelectOption, TextInput } from '@cbhq/cds-web/controls';
import { Switch } from '@cbhq/cds-web/controls/Switch';
import { Divider, Grid, HStack, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { TextBody, TextCaption, TextLabel2, TextTitle2 } from '@cbhq/cds-web/typography';

type DebugMenuProps = {
  onClose: () => void;
};

type TabId = 'data_tab' | 'actions_tab' | 'experiments_tab' | 'kill_switches_tab' | 'logs_tab';
type Tab = {
  id: TabId;
  label: string;
  component: ComponentType;
  isForInternalUseOnly?: boolean;
};

const tabs: Tab[] = [
  { id: 'data_tab', label: 'Data', component: DataList },
  { id: 'actions_tab', label: 'Actions', component: ActionsList },
  {
    id: 'experiments_tab',
    label: 'Experiments',
    component: ExperimentsTab,
    isForInternalUseOnly: true,
  },
  {
    id: 'kill_switches_tab',
    label: 'Kill switches',
    component: KillSwitchesTab,
    isForInternalUseOnly: true,
  },
  {
    id: 'logs_tab',
    label: 'Logs',
    component: LogsTab,
    isForInternalUseOnly: true,
  },
];

export const DebugMenu = ({ onClose }: DebugMenuProps) => {
  const logWidgetEvent = useLogWidgetEvent();
  const [tab, setTab] = useState<TabId>('data_tab');
  const onTabChange = useReffedFunction((v: string) => {
    setTab(v as TabId);
  });

  // in production, internal use only tabs are only shown to employees
  const canSeeInternalInfo = useCanSeeInternalInfo();

  const filteredTabs = useMemo(
    () =>
      tabs.filter((t) => {
        if (t.isForInternalUseOnly) {
          return !isProduction || canSeeInternalInfo;
        }

        return true;
      }),
    [canSeeInternalInfo],
  );

  useEffect(
    () =>
      logWidgetEvent('debug_menu_viewed', { tab, canSeeInternalUseOnlyTabs: canSeeInternalInfo }),
    [logWidgetEvent, tab, canSeeInternalInfo],
  );

  const TabComponent = useMemo(
    () => filteredTabs.find((t) => t.id === tab)?.component,
    [filteredTabs, tab],
  );

  return (
    <VStack spacingVertical={3}>
      <HStack spacingHorizontal={3} justifyContent="space-between">
        <TextTitle2 as="p">Debug menu</TextTitle2>
        <IconButton testID="debug-menu-close" name="close" onPress={onClose} />
      </HStack>
      <VStack spacingHorizontal={3}>
        <TabNavigation value={tab} tabs={filteredTabs} onChange={onTabChange} />
      </VStack>
      {TabComponent && <TabComponent />}
    </VStack>
  );
};

function DataList() {
  const canSeeInternalInfo = useCanSeeInternalInfo();
  return (
    <>
      <ClickToCopyListCell title="Device Id" description={clientSessionIdStore.getDeviceId()} />
      <ClickToCopyListCell
        title="Session Id"
        description={clientSessionIdStore.getClientSessionId()}
      />
      <ClickToCopyListCell title="User Uuid" description={clientSessionIdStore.getUserUuid()} />
      <ClickToCopyListCell
        title="Wallet User Id"
        description={clientSessionIdStore.getWalletUserId()}
      />
      <ClickToCopyListCell
        title="Entity hash"
        description={clientSessionIdStore.getGuestCheckoutEntityHash()}
      />
      {canSeeInternalInfo && <ClickToCopyListCell title="URL" description={window.location.href} />}
    </>
  );
}

function ActionsList() {
  const handleLogout = useLogout('debug_menu');
  const canSeeInternalInfo = useCanSeeInternalInfo();

  const [isMockBuyAndSendEnabled, setMockBuyAndSendEnabled] = useAtom(isMockBuyAndSendEnabledAtom);
  const toggleMockBuyAndSend = useCallback(
    () => setMockBuyAndSendEnabled(!isMockBuyAndSendEnabled),
    [isMockBuyAndSendEnabled, setMockBuyAndSendEnabled],
  );

  const [isDebug3dsEnabled, setDebug3dsEnabled] = useAtom(isDebug3dsEnabledAtom);
  const toggleDebug3ds = useCallback(
    () => setDebug3dsEnabled(!isDebug3dsEnabled),
    [isDebug3dsEnabled, setDebug3dsEnabled],
  );

  const logCookieNamesOnServer = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    void fetch('/log-cookie-names', {
      method: 'POST',
    });
  }, []);

  return (
    <VStack gap={3} spacing={3}>
      <Switch onChange={toggleMockBuyAndSend} checked={isMockBuyAndSendEnabled}>
        Enable Mocked Buy and Send
      </Switch>
      <TextLabel2 as="p">{isMockBuyAndSendEnabledMessage}</TextLabel2>
      <Switch onChange={toggleDebug3ds} checked={isDebug3dsEnabled}>
        Enable 3DS Debugging
      </Switch>
      <TextLabel2 as="p">{isDebug3dsEnabledMessage}</TextLabel2>
      <Divider />
      <Button onPress={handleLogout} variant="negative">
        Logout
      </Button>
      {canSeeInternalInfo && (
        <Button onPress={logCookieNamesOnServer}>Log cookie names on server</Button>
      )}
    </VStack>
  );
}

const reloadPage = () => window.location.reload();

function ExperimentsTab() {
  const queryClient = useQueryClient();

  const clearOverriddenExperiments = useCallback(async () => {
    clearCookie({ cookieName: 'overridenExperiments' });
    await queryClient.invalidateQueries(QUERY_KEYS.EXPERIMENTS);
  }, [queryClient]);

  const clearExperimentCache = useCallback(async () => {
    clearCookie({ cookieName: 'deviceExperiments' });
    clearCookie({ cookieName: 'walletExperiments' });
    await queryClient.invalidateQueries(QUERY_KEYS.EXPERIMENTS);
  }, [queryClient]);

  const experiments = Object.values(Experiments).sort((a, b) => a.name.localeCompare(b.name));

  const forceRerender = useForceRerender();
  const overriddenExperiments = getExperimentsFromCookieName('overridenExperiments') ?? [];

  const forceIntoGroup = useReffedFunction(async (testName: string, groupToForceInto: string) => {
    const newExperimentData = (() => {
      if (groupToForceInto) {
        return {
          groups: deduplicateExperimentArrays([
            ...overriddenExperiments,
            // `isTracked: false` prevents overridden experiments from counting as exposure
            { test: testName, group: groupToForceInto, isTracked: false },
          ]),
        };
      }

      return {
        groups: overriddenExperiments.filter((g) => g.test !== testName),
      };
    })();

    setExperimentCookie('overridenExperiments', newExperimentData);
    // without this, the UI won't be updated when an override changes because cookie overrides are stored in a cookie and do not trigger a re-render
    forceRerender();
    await queryClient.invalidateQueries(QUERY_KEYS.EXPERIMENTS);
  });

  const [searchText, setSearchText] = useState('');

  return (
    <VStack spacing={3} gap={3}>
      <Grid gap={1} columns={2}>
        <Button compact variant="negative" onPress={clearOverriddenExperiments}>
          Clear overrides
        </Button>
        <Button compact variant="negative" onPress={clearExperimentCache}>
          Clear cache
        </Button>
        <Button compact onPress={reloadPage}>
          Reload page
        </Button>
      </Grid>
      <Divider />
      <SearchInput
        compact
        value={searchText}
        onChangeText={setSearchText}
        placeholder="Search experiments"
      />
      <VStack gap={2}>
        {experiments
          .filter((experiment) => experiment.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((experiment) => (
            <Select
              key={experiment.name}
              label={experiment.name}
              value={overriddenExperiments.find((g) => g.test === experiment.name)?.group || ''}
              onChange={(groupToForceInto: string) =>
                void forceIntoGroup(experiment.name, groupToForceInto)
              }
            >
              <SelectOption title="Not overridden" value="" />
              {experiment.groups.map((group) => (
                <SelectOption key={group} title={group} value={group} />
              ))}
            </Select>
          ))}
      </VStack>
    </VStack>
  );
}

function KillSwitchesTab() {
  const { data: killSwitchesData } = useQuery<KillSwitchesConfigType>(QUERY_KEYS.KILL_SWITCH);
  const killSwitchesOverrideData = getKillSwitchesFromCookieName('overridenKillSwitches');

  const queryClient = useQueryClient();
  const clearKillSwitchCache = useCallback(async () => {
    clearCookie({ cookieName: 'fetchedKillSwitches' });
    await queryClient.invalidateQueries(QUERY_KEYS.KILL_SWITCH);
  }, [queryClient]);
  const clearKillSwitchOverrides = useCallback(async () => {
    clearCookie({ cookieName: 'overridenKillSwitches' });
    await queryClient.invalidateQueries(QUERY_KEYS.KILL_SWITCH);
  }, [queryClient]);

  const overrideKillSwitch = useReffedFunction(
    async (killSwitchName: string, value: boolean | undefined) => {
      setKillSwitchCookie('overridenKillSwitches', {
        killswitches: {
          ...killSwitchesOverrideData,
          [killSwitchName]: value,
        },
      });
      await queryClient.invalidateQueries(QUERY_KEYS.KILL_SWITCH);
    },
  );

  const [searchText, setSearchText] = useState('');
  const [newKillSwitchPrefix, setNewKillSwitchPrefix] = useState('');
  const [newKillSwitchName, setNewKillSwitchName] = useState('');
  const killSwitchList = useMemo(
    () => Object.entries(killSwitchesData ?? {}).map(([name, value]) => ({ name, value })),
    [killSwitchesData],
  );

  const dynamicKillSwitchPrefixes = useDynamicKillSwitchPrefixes();
  const dynamicKillSwitchPrefix = dynamicKillSwitchPrefixes.find(
    (p) => p.prefix === newKillSwitchPrefix,
  );

  const computedNewKillSwitchName = useMemo(
    () => `${newKillSwitchPrefix}${newKillSwitchName}`.toLowerCase(),
    [newKillSwitchPrefix, newKillSwitchName],
  );

  return (
    <VStack spacing={3} gap={3}>
      <Grid gap={1} columns={2}>
        <Button compact variant="negative" onPress={clearKillSwitchOverrides}>
          Clear overrides
        </Button>
        <Button compact variant="negative" onPress={clearKillSwitchCache}>
          Clear cache
        </Button>
        <Button compact onPress={reloadPage}>
          Reload page
        </Button>
      </Grid>
      <VStack gap={1}>
        <Select
          compact
          label="Prefix"
          onChange={(value: string) => {
            setNewKillSwitchPrefix(value);
            setNewKillSwitchName('');
          }}
          value={newKillSwitchPrefix}
        >
          <SelectOption compact description="No prefix" value="" />
          {dynamicKillSwitchPrefixes.map(({ prefix, label }) => (
            <SelectOption compact key={prefix} value={prefix} description={label} title={prefix} />
          ))}
        </Select>
        <TextInput
          compact
          required
          label="Name"
          value={newKillSwitchName}
          onChange={(event) => setNewKillSwitchName(event.target.value)}
          placeholder={dynamicKillSwitchPrefix?.sampleSuffix ?? 'kill_cbpay_network_ethereum'}
          helperText={dynamicKillSwitchPrefix?.helperText}
          // together with the datalist below, provides an autocomplete experience
          list="kill-switch-suffixes"
        />
        {dynamicKillSwitchPrefix?.allowedSuffixes && (
          <datalist id="kill-switch-suffixes">
            {dynamicKillSwitchPrefix.allowedSuffixes.map((suffix) => (
              <option value={suffix}>{suffix}</option>
            ))}
          </datalist>
        )}
        {newKillSwitchName && (
          <TextBody as="div">
            Kill switch name: <TextCaption as="span">{computedNewKillSwitchName}</TextCaption>
          </TextBody>
        )}
        <Button
          compact
          disabled={!newKillSwitchName}
          onPress={() => {
            void overrideKillSwitch(computedNewKillSwitchName, true);
            setNewKillSwitchName('');
            setNewKillSwitchPrefix('');
          }}
        >
          Add new kill switch
        </Button>
      </VStack>
      <Divider />
      <SearchInput
        compact
        value={searchText}
        onChangeText={setSearchText}
        placeholder="Search kill switches"
      />
      <VStack gap={2}>
        {killSwitchList
          .filter((killSwitch) => killSwitch.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((killSwitch) => (
            <Select
              key={killSwitch.name}
              value={String(killSwitchesOverrideData?.[killSwitch.name] ?? '')}
              onChange={(value: string) => {
                /** 'true' => true, 'false' => false, '' => undefined */
                const parsedValue = value ? value === 'true' : undefined;
                void overrideKillSwitch(killSwitch.name, parsedValue);
              }}
              label={killSwitch.name}
            >
              <SelectOption value="" title="Not overridden" />
              <SelectOption value="true" title="Enabled" />
              <SelectOption value="false" title="Disabled" />
            </Select>
          ))}
      </VStack>
    </VStack>
  );
}

function ClickToCopyListCell({ description, ...props }: ListCellProps) {
  return (
    <ListCell
      detailWidth="40px"
      description={
        <TextBody as="p" overflow="wrap">
          {description || 'undefined'}
        </TextBody>
      }
      {...props}
      detail={
        typeof description === 'string' ? (
          <IconButton
            name="copy"
            variant="primary"
            transparent
            onPress={() => {
              copyToClipboard(description);
            }}
          />
        ) : undefined
      }
    />
  );
}

function useForceRerender() {
  const [, updateState] = useState<unknown>();
  return () => updateState({});
}

const debugLogsAtom = atom<string[]>([]);

export function debugLog(message: string) {
  const timestamp = Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3,
    hour12: false,
  }).format(new Date());
  const formatted = `[${timestamp}] - ${message}`;
  jotaiStore.set(debugLogsAtom, (prev) => prev.concat(formatted));
}

function LogsTab() {
  const debugLogs = useAtomValue(debugLogsAtom);
  return (
    <VStack spacing={3} gap={0.5}>
      {debugLogs.map((message) => (
        <TextBody key={message} as="p" mono>
          {message}
        </TextBody>
      ))}
    </VStack>
  );
}
