import { useCallback, useMemo } from 'react';
import type { useAllowedRecipientsQuery } from '@onramp/data/__generated__/useAllowedRecipientsQuery.graphql';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';

export const useAllowedRecipients = () => {
  const data = useLazyLoadQuery<useAllowedRecipientsQuery>(
    graphql`
      query useAllowedRecipientsQuery {
        viewer {
          p2pAllowedRecipients {
            allowlistState {
              status
            }
            listAllowedRecipients {
              edges {
                node {
                  address
                  currencies
                }
              }
            }
          }
        }
      }
    `,
    {},
  );

  const allowedRecipientsList = useMemo(() => {
    const edges = data?.viewer?.p2pAllowedRecipients?.listAllowedRecipients?.edges;
    if (!edges) {
      return [];
    }
    return edges;
  }, [data]);

  const state = data?.viewer?.p2pAllowedRecipients?.allowlistState?.status;

  const isAddressBookEnabled =
    state === 'ENABLED' ||
    state === 'ENABLED_FORCED' ||
    state === 'ENABLED_GRACE_PERIOD' ||
    state === 'DISABLED_PENDING';

  const checkIsAddressAllowed = useCallback(
    (address: string, currency: string) => {
      if (!isAddressBookEnabled) return true;

      // The same address with the same currency can occur more than once in the allowed recipients list
      for (const recipient of allowedRecipientsList) {
        if (recipient?.node?.address === address && recipient.node.currencies) {
          for (const allowedCurrency of recipient.node.currencies) {
            if (allowedCurrency === currency) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [allowedRecipientsList, isAddressBookEnabled],
  );

  return { isAddressBookEnabled, checkIsAddressAllowed };
};
