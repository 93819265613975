import { z } from 'zod';

export const ClientAppDetailsSchema = z.object({
  appId: z.string(),
  oauthId: z.string(),
  domains: z.array(z.string()),
  displayName: z.string(),
  isActive: z.boolean(),
  isSecureInitRequired: z.boolean(),
  isThirdParty: z.boolean().optional(),
});

export type ClientAppDetails = z.infer<typeof ClientAppDetailsSchema>;
