import { doOncePerSession } from '@onramp/v2/client/utils/session/doOncePerSession';
import type { LogMetric } from '@cbhq/client-analytics';
import { logMetric, MetricType } from '@cbhq/client-analytics';

const metricPrefix = 'onramp';
export type Tags = Record<string, string | boolean> | undefined;
type CriticalStepName = 'init_session' | 'session_error' | 'init_session_onramp';

/**
 * Logs a critical_step metric with the given step tag. Critical steps can only occur once per page session
 */
export const logCriticalStepMetric = (step: CriticalStepName, tags?: Tags) => {
  const idemKey = `critical_step.${step}`;
  const logMetricOnce = doOncePerSession(logMetric, idemKey);

  logMetricOnce({ ...CRITICAL_STEP, tags: { step, ...tags } });
};

export const PERF_LOG_SARDINE_CUSTOMER_ID = 'onramp.perf_log_sardine_customer_id';
export const PERF_LOG_SARDINE_SESSION_KEY = 'onramp.perf_log_sardine_sesion_key';

export const CRITICAL_STEP: LogMetric = {
  metricName: `${metricPrefix}.critical_step`,
  metricType: MetricType.count,
  value: 1,
};

export const GENERIC_ERROR: LogMetric = {
  metricName: `${metricPrefix}.generic_error`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_3DS_SUCCESS: LogMetric = {
  metricName: `${metricPrefix}.guest_3ds_success`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_3DS_FAIL: LogMetric = {
  metricName: `${metricPrefix}.guest_3ds_fail`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_3DS_IFRAME_LOAD_ERROR: LogMetric = {
  metricName: `${metricPrefix}.guest_3ds_iframe_load_error`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_ORDER_SUBMIT_ERROR: LogMetric = {
  metricName: `${metricPrefix}.guest_order_submit_error`,
  metricType: MetricType.count,
  value: 1,
};

type GuestOrderSubmitAttemptedTags = {
  platformAttribution?: 'android' | 'extension' | 'ios' | 'web';
  paymentMethod: 'card' | 'apple_pay';
};

export const GUEST_ORDER_SUBMIT_ATTEMPTED = ({
  platformAttribution,
  paymentMethod,
}: GuestOrderSubmitAttemptedTags): LogMetric => ({
  metricName: `${metricPrefix}.guest_order_submit_attempted`,
  metricType: MetricType.count,
  value: 1,
  tags: {
    ...(platformAttribution ? { platformAttribution } : {}),
    paymentMethod,
  },
});

export const GUEST_ORDER_SUBMITTED: LogMetric = {
  metricName: `${metricPrefix}.guest_order_submitted`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_3DS_IFRAME_REQUIRED: LogMetric = {
  metricName: `${metricPrefix}.guest_3ds_iframe_required`,
  metricType: MetricType.count,
  value: 1,
};

type Guest2FAStartedTags = {
  isPhoneVerificationFirst?: boolean;
  isMagicSpend?: boolean;
};

export const GUEST_2FA_STARTED = ({
  isPhoneVerificationFirst = false,
  isMagicSpend = false,
}: Guest2FAStartedTags): LogMetric => ({
  metricName: `${metricPrefix}.guest_2fa_started`,
  metricType: MetricType.count,
  value: 1,
  tags: {
    isPhoneVerificationFirst,
    isMagicSpend,
  },
});

export const GUEST_2FA_SUBMITTED: LogMetric = {
  metricName: `${metricPrefix}.guest_2fa_submitted`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_2FA_FAILED: LogMetric = {
  metricName: `${metricPrefix}.guest_2fa_failed`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_2FA_SKIPPED: LogMetric = {
  metricName: `${metricPrefix}.guest_2fa_skipped`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_RECAPTCHA_SHOW_VISIBLE: LogMetric = {
  metricName: `${metricPrefix}.guest_recaptcha_show_visible`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_2FA_RECAPTCHA_COMPLETE_VISIBLE: LogMetric = {
  metricName: `${metricPrefix}.guest_2fa_recaptcha_complete_visible`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_TOKENEX_LOAD_START: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_load_start`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_LOAD_SUCCESS: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_load_success`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_LOAD_FAIL: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_load_fail`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_LOAD_TIMEOUT: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_load_timeout`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_VALIDATE_MERCHANT_SUCCESS: LogMetric = {
  metricName: `${metricPrefix}.guest_validate_merchant_success`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_VALIDATE_MERCHANT_FAILED: LogMetric = {
  metricName: `${metricPrefix}.guest_validate_merchant_failed`,
  metricType: MetricType.count,
  value: 1,
};

export type TokenexValidateLabel = 'card' | 'cvv';

export const GUEST_TOKENEX_VALIDATE_START = (label: TokenexValidateLabel): LogMetric => ({
  metricName: `${metricPrefix}.guest_onramp_tokenex_validate_start`,
  metricType: MetricType.count,
  value: 1,
  tags: {
    label,
  },
});
export const GUEST_TOKENEX_VALIDATE_TIMEOUT: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_validate_timeout`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_VALIDATE_SUCCESS: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_validate_success`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_VALIDATE_FAIL = (label: TokenexValidateLabel): LogMetric => ({
  metricName: `${metricPrefix}.guest_onramp_tokenex_validate_fail`,
  metricType: MetricType.count,
  value: 1,
  tags: {
    label,
  },
});
export const GUEST_TOKENEX_AUTH_EXPIRED: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_auth_expired`,
  metricType: MetricType.count,
  value: 1,
};

export const GUEST_TOKENEX_TOKENIZE_START: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_tokenize_start`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_TOKENIZE_FAIL: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_tokenize_fail`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_TOKENIZE_SUCCESS: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_tokenize_success`,
  metricType: MetricType.count,
  value: 1,
};
export const GUEST_TOKENEX_TOKENIZE_TIMEOUT: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp_tokenex_tokenize_timeout`,
  metricType: MetricType.count,
  value: 1,
};

export const CARD_DETAILS_SUBMIT_PRESSED: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp.card_details.submit_pressed`,
  metricType: MetricType.count,
  value: 1,
};

export const CARD_DETAILS_SUBMIT_PRESSED_IN_SESSION: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp.card_details.submit_pressed_in_session`,
  metricType: MetricType.count,
  value: 1,
};

/**
 * @param tokenexFailure - Set to true when validate or tokenize have an unexpected error or timeout.
 */
export const CARD_DETAILS_SUBMIT_RESOLVED = (tokenexFailure: boolean): LogMetric => ({
  metricName: `${metricPrefix}.guest_onramp.card_details.submit_resolved`,
  metricType: MetricType.count,
  value: 1,
  tags: {
    tokenexFailure,
  },
});

export const CARD_DETAILS_FORM_MODIFIED: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp.card_details.form_modified`,
  metricType: MetricType.count,
  value: 1,
};

export const CARD_DETAILS_CONTINUE_WITH_APPLE_PAY: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp.card_details_continue_with_applepay`,
  metricType: MetricType.count,
  value: 1,
};

export const CARD_DETAILS_VIEWED_METRIC: LogMetric = {
  metricName: `${metricPrefix}.guest_onramp.card_details.viewed`,
  metricType: MetricType.count,
  value: 1,
};
