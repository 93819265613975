// eslint-disable-next-line no-restricted-imports
import type { Response, SecureInitParameters } from '@onramp/server/types';
import type { DestinationAddressAppParam } from '@onramp/shared/appParams.schema';
import {
  addressAndAssetsToDestinationWallets,
  DestinationAddressAppParamsSchema,
} from '@onramp/shared/appParams.schema';
import type { AppState3dsType } from '@onramp/shared/appState3ds.schema';
import type { MagicSpendParams } from '@onramp/shared/magicSpendParams.schema';
import { magicSpendParamsSchema } from '@onramp/shared/magicSpendParams.schema';
import type { PersistedGuestSession } from '@onramp/shared/persistGuestSession.schema';
import type { InitParamErrors } from '@onramp/types/initParamErrors';
import type { InitParamsType, WalletAnalyticsData } from '@onramp/types/initParams';
import {
  parseNetworkIdsInAddresses,
  parseNetworkIdsInDestinationWallets,
} from '@onramp/utils/blockchains/networkMetadata';
import type { ClientAppDetails } from '@onramp/utils/clientApps/types';
import { ClientAppDetailsSchema } from '@onramp/utils/clientApps/types';
import type { AuthenticationMethod } from '@onramp/utils/clientSessionIdStore';
import { isLocalDevelopment } from '@onramp/utils/environment/sharedEnv';
import type { FeatureFlags } from '@onramp/utils/featureFlags/types';
import type { ClientGsscData } from '@onramp/utils/gssc';
import { clientGsscDataSchema } from '@onramp/utils/gssc';
import { getIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { getIsMagicSpendPath } from '@onramp/utils/magicSpendUtils';
import { isNotNullish } from '@onramp/utils/types';
import { getIsRoute } from '@onramp/v2/client/views/onramp/card-details/utils/getIsRoute';
import bigJs from 'big.js';
import type { IncomingMessage } from 'http';
import type { AppContext, AppInitialProps } from 'next/app';
import NextApp from 'next/app';
import { z } from 'zod';

import { checkInitialRouteServerRedirect } from './serverRedirects/checkInitialRouteServerRedirect';
import { QueryParamSchema } from './serverRedirects/handleNonIgnoredPaths';

export type InitialProps = AppInitialProps & {
  csrfToken: string | undefined;
  clientAppDetails: ClientAppDetails | undefined;
  gsscData: ClientGsscData | undefined;
  authenticationMethod: AuthenticationMethod | undefined;
  guestCheckoutCountryAllowlist: string[] | undefined;
  featureFlags: FeatureFlags | undefined;
  userUuid: string | undefined;
  isGuestCheckoutRoute: boolean;
  isV2Route: boolean;
  secureInitParameters: SecureInitParameters | undefined;
  appState3ds: AppState3dsType | undefined;
  initParams?: InitParamsType;
  initParamErrors?: InitParamErrors;
  magicSpendParams?: MagicSpendParams;
  requiredParamsError?: boolean;
  persistedGuestSession?: PersistedGuestSession;
  walletAnalyticsData?: WalletAnalyticsData;
};

/**
 * Redirect all non-404 routes to /select-asset, so users always start at beginning of the flow.
 * If a user refreshes their browser a few steps into the flow, this takes them back to the start.
 */
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getInitialProps = async (appContext: AppContext): Promise<InitialProps | void> => {
  // If ctx.res is present, we're running on the server.
  const { isOnServer, req, res } =
    appContext.ctx.res !== undefined
      ? ({
          isOnServer: true,
          req: appContext.ctx.req as IncomingMessage,
          res: appContext.ctx.res as Response,
        } as const)
      : ({
          isOnServer: false,
          req: undefined,
          res: undefined,
        } as const);

  if (isOnServer && checkInitialRouteServerRedirect(req, appContext.router.query, res)) {
    // Need to return some value to satisfy typescript.
    return undefined;
  }

  const { locals = {} } = res ?? {};

  const invalidParams = locals.initParamErrors?.invalidParams ?? [];

  const isGuestCheckoutRoute = isOnServer
    ? appContext.router.route.startsWith('/buy/guest')
    : getIsGuestCheckoutPath();

  const isV2Route = isOnServer ? appContext.router.route.startsWith('/v2') : getIsRoute('/v2');
  const isLanding = isOnServer
    ? appContext.router.route.startsWith('/landing')
    : getIsRoute('/landing');

  const isMagicSpendRoute = getIsMagicSpendPath(
    isOnServer ? appContext.router.route : window.location.href,
  );
  const initialPropsCommonTags = {
    app_id: locals.clientAppDetails?.appId ?? '<missing app id>',
    page_path: appContext.ctx.pathname,
    logged_in: String(isNotNullish(locals.jwt)),
  };

  // Otherwise, we need to pass on through to Next.js
  const appInitialProps = await NextApp.getInitialProps(appContext);
  // Also get CSRF token from headers
  const csrfToken = validateInitialProp({
    prop: 'csrfToken',
    value: res?.getHeader('csrf-token'),
    schema: z.string(),
    res,
    tags: initialPropsCommonTags,
  });

  const clientAppDetails = validateInitialProp({
    prop: 'clientAppDetails',
    value: locals.clientAppDetails,
    schema: locals.clientAppDetails ? ClientAppDetailsSchema : ClientAppDetailsSchema.optional(),
    res,
    tags: initialPropsCommonTags,
  });

  const gsscData = validateInitialProp({
    prop: 'gsscData',
    value: locals.gsscData,
    schema: clientGsscDataSchema.optional(),
    res,
    tags: initialPropsCommonTags,
  });

  /** The GC country allowlist. If `undefined` during local development, defaults to `['US']` */
  const guestCheckoutCountryAllowlist = (() => {
    const configServiceAllowlist = res?.locals.configService?.getParameter(
      'payments/onramp-widget-critical',
      'guest-checkout-country-allowlist',
    )?.body.stringArray.stringArray;

    if (configServiceAllowlist || !isLocalDevelopment) return configServiceAllowlist;

    return ['US'];
  })();

  const secureInitParameters = res?.locals.secureInitParameters;
  const queryParams = appContext.ctx.query;
  let initParams = { ...queryParams } as InitParamsType;
  const nonceCookieData = res?.locals.nonceCookieData;

  if (nonceCookieData && Boolean(queryParams.nonce)) {
    initParams = {
      ...initParams,
      ...nonceCookieData,
    };

    if (nonceCookieData && nonceCookieData?.nonce !== queryParams.nonce) {
      throw new Error('Nonce mismatch');
    }
  }
  if (secureInitParameters) {
    initParams = {
      ...initParams,
      ...secureInitParameters,
    };
  }

  if (typeof initParams.handlingRequestedUrls === 'string') {
    initParams.handlingRequestedUrls = initParams.handlingRequestedUrls === 'true';
  }

  // A lot of developers find the destinationWallets param confusing, so we've added a simpler way to pass addresses
  // and supported assets. These new `addresses` and `assets` params are converted into a destinationWallets param.
  if (typeof initParams.addresses === 'string' && !invalidParams.includes('addresses')) {
    initParams.addresses = JSON.parse(decodeURIComponent(initParams.addresses)) as Record<
      string,
      string[]
    >;

    if (!invalidParams.includes('assets')) {
      if (typeof initParams.assets === 'string') {
        initParams.assets = JSON.parse(initParams.assets) as string[];
      }

      initParams.destinationWallets = addressAndAssetsToDestinationWallets(
        initParams.addresses,
        initParams.assets,
      );
    }
  }

  let magicSpendParams: Partial<MagicSpendParams> = {};

  if (isV2Route || isLanding) {
    if (isMagicSpendRoute) {
      const unparsedParams = { ...queryParams };
      try {
        if (typeof unparsedParams.toAddresses === 'string') {
          unparsedParams.toAddresses = decodeURIComponent(unparsedParams.toAddresses).split(',');
        }
        magicSpendParams = magicSpendParamsSchema.parse(unparsedParams);
      } catch (error) {
        res?.logger.warn('Failed to parse magicSpendParams', {
          magicSpendParams: unparsedParams,
        });
      }
    } else {
      const validationParams = {
        ...initParams,
      };

      try {
        let { destinationWallets } = initParams;
        if (typeof destinationWallets === 'string') {
          destinationWallets = JSON.parse(
            decodeURIComponent(destinationWallets),
          ) as unknown as DestinationAddressAppParam[];
        }

        validationParams.destinationWallets = destinationWallets;
      } catch {
        // noop
      }

      const requiredParams = RequiredParamSchema.safeParse(validationParams);
      if (!requiredParams.success) {
        res?.logger.error('Failed to parse required params', {
          error: requiredParams.error.format(),
        });
      } else {
        try {
          initParams.destinationWallets = parseNetworkIdsInDestinationWallets(
            requiredParams.data.destinationWallets,
          );
        } catch {
          //
        }
      }

      if (initParams.addresses && !invalidParams.includes('addresses')) {
        initParams.addresses = parseNetworkIdsInAddresses(initParams.addresses);
      }

      if (initParams.presetCryptoAmount) {
        try {
          initParams.presetCryptoAmount = bigJs(initParams.presetCryptoAmount).toNumber();
        } catch {
          res?.logger.warn('Failed to parse presetCryptoAmount as number', {
            presetCryptoAmount: initParams.presetCryptoAmount,
          });
        }
      }

      if (initParams.presetFiatAmount) {
        try {
          initParams.presetFiatAmount = bigJs(initParams.presetFiatAmount).toNumber();
        } catch {
          res?.logger.warn('Failed to parse presetFiatAmount as number', {
            presetFiatAmount: initParams.presetFiatAmount,
          });
        }
      }

      if (initParams.fiatCurrency) {
        initParams.fiatCurrency = initParams.fiatCurrency.toUpperCase();
      }
    }
  }

  return {
    csrfToken,
    clientAppDetails,
    gsscData,
    authenticationMethod: res?.locals.jwt?.authenticationMethod as AuthenticationMethod | undefined,
    guestCheckoutCountryAllowlist,
    featureFlags: locals.featureFlags,
    userUuid: res?.locals.userUuid,
    isGuestCheckoutRoute,
    secureInitParameters,
    appState3ds: res?.locals.appState3ds,
    initParams,
    initParamErrors: res?.locals.initParamErrors,
    isV2Route,
    magicSpendParams: magicSpendParams as MagicSpendParams,
    persistedGuestSession: res?.locals.persistedGuestSession,
    walletAnalyticsData: res?.locals.walletAnalyticsData,
    ...appInitialProps,
  };
};

function validateInitialProp<K extends keyof InitialProps>({
  prop,
  schema,
  res,
  value,
  tags,
}: {
  prop: K;
  value: unknown;
  schema: z.ZodSchema<InitialProps[K]>;
  res: Response | undefined;
  tags?: Record<string, string>;
}) {
  const parsed = schema.safeParse(value);

  if (!parsed.success) {
    res?.locals.statsd?.increment('invalid_initial_props', {
      ...tags,
      prop,
    });

    res?.logger.warn('Invalid initial prop', {
      ...tags,
      prop,
      error: parsed.error.format(),
    });

    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return parsed.data;
}

const RequiredParamSchema = QueryParamSchema.extend({
  destinationWallets: DestinationAddressAppParamsSchema,
});
