/**
 * @generated SignedSource<<54ddf35628115948560f9d5231ab1e7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PolicyRestrictionsGuardQuery$variables = {
  buys: boolean;
  inWebView: boolean;
  pay: boolean;
  sends: boolean;
};
export type PolicyRestrictionsGuardQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PolicyRestrictionsGuardFragment">;
  };
};
export type PolicyRestrictionsGuardQuery = {
  response: PolicyRestrictionsGuardQuery$data;
  variables: PolicyRestrictionsGuardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buys"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inWebView"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pay"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sends"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "restrictionName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Link",
    "kind": "LinkedField",
    "name": "link",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PolicyRestrictionsGuardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "buys",
                "variableName": "buys"
              },
              {
                "kind": "Variable",
                "name": "inWebView",
                "variableName": "inWebView"
              },
              {
                "kind": "Variable",
                "name": "pay",
                "variableName": "pay"
              },
              {
                "kind": "Variable",
                "name": "sends",
                "variableName": "sends"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PolicyRestrictionsGuardFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PolicyRestrictionsGuardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "inWebView",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isWebview2faEligible",
                "storageKey": null
              }
            ]
          },
          {
            "condition": "sends",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "sendRestrictions",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "sends"
                  }
                ],
                "concreteType": "PolicyRestriction",
                "kind": "LinkedField",
                "name": "policyRestrictions",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "policyRestrictions(action:\"sends\")"
              }
            ]
          },
          {
            "condition": "buys",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "buyRestrictions",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "buys"
                  }
                ],
                "concreteType": "PolicyRestriction",
                "kind": "LinkedField",
                "name": "policyRestrictions",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "policyRestrictions(action:\"buys\")"
              }
            ]
          },
          {
            "condition": "pay",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "payRestrictions",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "pay"
                  }
                ],
                "concreteType": "PolicyRestriction",
                "kind": "LinkedField",
                "name": "policyRestrictions",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": "policyRestrictions(action:\"pay\")"
              }
            ]
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b82dc47f295bc786f7e607e337a8d284",
    "id": null,
    "metadata": {},
    "name": "PolicyRestrictionsGuardQuery",
    "operationKind": "query",
    "text": "query PolicyRestrictionsGuardQuery(\n  $sends: Boolean!\n  $buys: Boolean!\n  $pay: Boolean!\n  $inWebView: Boolean!\n) {\n  viewer {\n    ...PolicyRestrictionsGuardFragment_1BsPKG\n    id\n  }\n}\n\nfragment PolicyRestrictionsGuardFragment_1BsPKG on Viewer {\n  userProperties {\n    uuid\n    country {\n      code\n      name\n    }\n    id\n  }\n  isWebview2faEligible @include(if: $inWebView)\n  sendRestrictions: policyRestrictions(action: sends) @include(if: $sends) {\n    restrictionName\n    link {\n      url\n      text\n    }\n    description\n    message\n    id\n  }\n  buyRestrictions: policyRestrictions(action: buys) @include(if: $buys) {\n    restrictionName\n    link {\n      url\n      text\n    }\n    description\n    message\n    id\n  }\n  payRestrictions: policyRestrictions(action: pay) @include(if: $pay) {\n    restrictionName\n    link {\n      url\n      text\n    }\n    description\n    message\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "90dc2e9af34f6baf16331c5225e874a4";

export default node;
