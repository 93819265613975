import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import EmbedSafeAnchor from '@onramp/components/embedding/EmbedSafeAnchor';
import { useAllowedRecipients } from '@onramp/hooks/send/useAllowedRecipients';
import { HandledError } from '@onramp/utils/errors';
import { maskWalletAddress } from '@onramp/utils/maskWalletAddress';

const messages = defineMessages({
  addressBookErrorTitle: {
    id: 'SelectAssetPage.addressBookErrorTitle',
    defaultMessage: 'Wallet address not allowed',
    description: 'Error message title when the wallet address has not been added to the allowlist',
  },
  addressBookErrorDescription: {
    id: 'SelectAssetPage.addressBookErrorDescription',
    defaultMessage:
      '{address} has not been added to your allowlist for {asset}. Please update your allowlist on {link} and try again.',
    description:
      "Error message description when a user's wallet address has not been added to their allowlist",
  },
});

export type AddressAllowlistValidationResult = {
  addressError: HandledError | undefined;
  addressErrorMessage: JSX.Element | undefined;
};

export function useValidateAddressAllowList() {
  const { formatMessage } = useIntl();
  const { checkIsAddressAllowed } = useAllowedRecipients();

  return useCallback(
    (walletAddress: string, assetTicker: string) => {
      const result: AddressAllowlistValidationResult = {
        addressError: undefined,
        addressErrorMessage: undefined,
      };

      if (!checkIsAddressAllowed(walletAddress, assetTicker)) {
        result.addressErrorMessage = (
          <>
            {formatMessage(messages.addressBookErrorDescription, {
              asset: assetTicker,
              address: walletAddress,

              link: (
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                <EmbedSafeAnchor href="https://www.coinbase.com/settings/allowlist">
                  coinbase.com
                </EmbedSafeAnchor>
              ),
            })}
          </>
        );
        result.addressError = new HandledError({
          message: 'failed address book validation',
        })
          .addHandlingParams({
            title: formatMessage(messages.addressBookErrorTitle),
            richMessage: result.addressErrorMessage,
          })
          .addMetadata({
            asset_ticker: assetTicker,
            masked_address: maskWalletAddress(walletAddress),
          });
      }

      return result;
    },
    [checkIsAddressAllowed, formatMessage],
  );
}
