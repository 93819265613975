/**
 * @generated SignedSource<<461414f4e11f7875c3b3681ccfe99ca2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type P2PUserAllowlistStatus = "DISABLED" | "DISABLED_PENDING" | "ENABLED" | "ENABLED_FORCED" | "ENABLED_GRACE_PERIOD" | "UNSPECIFIED" | "%future added value";
export type useAllowedRecipientsQuery$variables = {};
export type useAllowedRecipientsQuery$data = {
  readonly viewer: {
    readonly p2pAllowedRecipients: {
      readonly allowlistState: {
        readonly status: P2PUserAllowlistStatus | null;
      } | null;
      readonly listAllowedRecipients: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly address: string | null;
            readonly currencies: ReadonlyArray<string | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  };
};
export type useAllowedRecipientsQuery = {
  response: useAllowedRecipientsQuery$data;
  variables: useAllowedRecipientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currencies",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useAllowedRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "P2PAllowedRecipients",
            "kind": "LinkedField",
            "name": "p2pAllowedRecipients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "P2PUserAllowlistState",
                "kind": "LinkedField",
                "name": "allowlistState",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "P2PAllowedRecipientConnection",
                "kind": "LinkedField",
                "name": "listAllowedRecipients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "P2PAllowedRecipientEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "P2PAllowedRecipient",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useAllowedRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "P2PAllowedRecipients",
            "kind": "LinkedField",
            "name": "p2pAllowedRecipients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "P2PUserAllowlistState",
                "kind": "LinkedField",
                "name": "allowlistState",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "P2PAllowedRecipientConnection",
                "kind": "LinkedField",
                "name": "listAllowedRecipients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "P2PAllowedRecipientEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "P2PAllowedRecipient",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "476b9c0250bc888472b8f96be07d156d",
    "id": null,
    "metadata": {},
    "name": "useAllowedRecipientsQuery",
    "operationKind": "query",
    "text": "query useAllowedRecipientsQuery {\n  viewer {\n    p2pAllowedRecipients {\n      allowlistState {\n        status\n        id\n      }\n      listAllowedRecipients {\n        edges {\n          node {\n            address\n            currencies\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "abb74a1e7561697d4ccb6780404489ed";

export default node;
