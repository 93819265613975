import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';
import { useBraintree } from '@onramp/hooks/useBraintree';
import { coerceError, reportError } from '@onramp/utils/errors';
import { useIsApplePayAvailable } from '@onramp/utils/useIsApplePayAvailable';
import Braintree from 'braintree-web';
import { noop } from '@cbhq/cds-utils';

export type ApplePayState = {
  braintreeClient: Braintree.Client | undefined;
  applePayClient: Braintree.ApplePay | undefined;
  resetApplePayState: () => void;
  applePayNonce: string | undefined;
  setApplePayNonce: (nonce: string | undefined) => void;
};

const initialApplePayState: ApplePayState = {
  braintreeClient: undefined,
  applePayClient: undefined,
  resetApplePayState: noop,
  applePayNonce: undefined,
  setApplePayNonce: noop,
} as const;

export const ApplePayStateContext = createContext<ApplePayState>(initialApplePayState);

// after cko migration, we can remove braintreeClient or remove this probvider and use jotai
export function ApplePayProvider({ children }: { children: ReactNode }) {
  const [applePayState, setApplePayState] = useState(initialApplePayState);
  const { getBraintreeClient } = useBraintree('apple_pay');
  const isApplePayAvailable = useIsApplePayAvailable();

  const resetApplePayState = useCallback(() => {
    void applePayState.braintreeClient?.teardown(noop);
    setApplePayState(initialApplePayState);
  }, [applePayState.braintreeClient]);

  const setApplePayNonce = useCallback((applePayNonce: string | undefined) => {
    setApplePayState((oldState) => ({ ...oldState, applePayNonce }));
  }, []);

  useEffect(() => {
    if (isApplePayAvailable && !applePayState.applePayClient) {
      Bugsnag.leaveBreadcrumb('Instantiating Braintree clients');
      void getBraintreeClient()
        .then(async (braintreeClient) => {
          Bugsnag.leaveBreadcrumb('Braintree client instantiated');
          return Promise.all([
            braintreeClient,
            Braintree.applePay.create({ client: braintreeClient }),
          ]);
        })
        .then(([braintreeClient, applePayClient]) => {
          Bugsnag.leaveBreadcrumb('Braintree Apple Pay client instantiated');
          setApplePayState((oldState) => ({
            ...oldState,
            braintreeClient,
            applePayClient,
            resetApplePayState,
            setApplePayNonce,
          }));
        })
        .catch((err) => {
          reportError(
            coerceError(err).addMetadata({
              context: 'ApplePayProvider.getBraintreeClient',
            }),
          );
        });
    }
  }, [
    applePayState.applePayClient,
    getBraintreeClient,
    isApplePayAvailable,
    resetApplePayState,
    setApplePayNonce,
  ]);

  return (
    <ApplePayStateContext.Provider value={applePayState}>{children}</ApplePayStateContext.Provider>
  );
}

export function useApplePayContext() {
  const applePayData = useContext(ApplePayStateContext);

  if (!applePayData) {
    throw new Error('ApplePayProvider not present.');
  }

  return applePayData;
}
