import { Suspense } from 'react';
import type { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from 'react-query';
import { AppThemeProvider } from '@onramp/components/AppThemeProvider';
import { ExperimentationProvider } from '@onramp/components/ExperimentationProvider';
import { GenericLoadingScreen } from '@onramp/components/screens/GenericLoadingScreen';
import type { MagicSpendParams } from '@onramp/shared/magicSpendParams.schema';
import type { InitParamsType, InternalInitParamsType } from '@onramp/types/initParams';
import { getCustomLogger } from '@onramp/utils/logger';
import { queryClient } from '@onramp/utils/reactQueryClient';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import { useThemeSpectrum } from '@onramp/utils/useThemeSpectrum';
import { ErrorBoundary } from '@onramp/v2/client/components/errors/ErrorBoundary';
import { InitParamsProvider } from '@onramp/v2/client/contexts/initParams/InitParamsContext';
import { useRouter } from 'next/router';
import { createGraphqlEnvironment, GraphqlProvider } from '@cbhq/data-layer';
import type { MessagesMap } from '@cbhq/intl';

/**
 * Generic graphql Provider, sending authHeaders or not is handled in the server.ts
 */
const unauthedGraphqlEnv = createGraphqlEnvironment({
  graphqlUrl: '/graphql/query',
  networkFetch: fetchWithCommonHeaders,
  logger: getCustomLogger('UnAuthedGraphqlProvider'),
});

type I18nProviderProps = {
  locale: string;
  messages: MessagesMap;
};

export const LandingProvidersV2: FC<
  I18nProviderProps & {
    initParamsFromServer?: InitParamsType;
    internalInitParamsFromServer: InternalInitParamsType;
    magicSpendParamsFromServer?: MagicSpendParams;
  }
> = ({
  children,
  initParamsFromServer,
  internalInitParamsFromServer,
  magicSpendParamsFromServer,
  locale,
  messages,
}) => {
  const spectrum = useThemeSpectrum();
  const params = initParamsFromServer ?? {
    appId: '',
    destinationWallets: [],
  };
  const DEFAULT_LOCALE = useRouter().locale;

  return (
    <AppThemeProvider spectrum={spectrum} widgetBackgroundVariant="solid">
      <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={messages}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <GraphqlProvider environment={unauthedGraphqlEnv}>
              <ExperimentationProvider>
                <InitParamsProvider
                  initParamsFromServer={params}
                  internalInitParamsFromServer={internalInitParamsFromServer}
                  magicSpendParamsFromServer={magicSpendParamsFromServer}
                >
                  <Suspense fallback={<GenericLoadingScreen />}>{children}</Suspense>
                </InitParamsProvider>
              </ExperimentationProvider>
            </GraphqlProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </IntlProvider>
    </AppThemeProvider>
  );
};

type GuestCheckoutProvidersProps = I18nProviderProps & {
  initParamsFromServer: InitParamsType | undefined;
  internalInitParamsFromServer: InternalInitParamsType;
  magicSpendParamsFromServer: MagicSpendParams | undefined;
};

export const GuestCheckoutProviders: FC<GuestCheckoutProvidersProps> = ({
  children,
  initParamsFromServer,
  internalInitParamsFromServer,
  magicSpendParamsFromServer,
  locale,
  messages,
}) => {
  const spectrum = useThemeSpectrum();
  const params = initParamsFromServer ?? {
    appId: '',
    destinationWallets: [],
  };
  const DEFAULT_LOCALE = useRouter().locale;

  return (
    <AppThemeProvider spectrum={spectrum} widgetBackgroundVariant="solid">
      <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={messages}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <GraphqlProvider environment={unauthedGraphqlEnv}>
              <ExperimentationProvider>
                <InitParamsProvider
                  initParamsFromServer={params}
                  internalInitParamsFromServer={internalInitParamsFromServer}
                  magicSpendParamsFromServer={magicSpendParamsFromServer}
                >
                  <Suspense fallback={<GenericLoadingScreen />}>{children}</Suspense>
                </InitParamsProvider>
              </ExperimentationProvider>
            </GraphqlProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </IntlProvider>
    </AppThemeProvider>
  );
};
