export const SERVICE_NAME = 'payments/onramp-widget';
export const POPULAR_ASSET_SYMBOLS = ['ETH', 'USDC', 'BTC'];

// Asset and network constants
export const USDC_ASSET_SYMBOL = 'USDC';
export const BASE_NETWORK_NAME = 'base';
export const ETH_ASSET_SYMBOL = 'ETH';
export const ETHEREUM_NETWORK_NAME = 'ethereum';

export const ADD_PAYMENT_METHOD_URL = `${process.env.NEXT_PUBLIC_COINBASE_BASE_URL}/settings/linked-accounts?action=add-payment-method`;
