import { Component } from 'react';
import type { ErrorInfo, ReactNode } from 'react';
import { GenericErrorScreenV2 } from '@onramp/v2/client/components/errors/GenericErrorScreenV2';
import { getErrorVitals } from '@onramp/v2/client/utils/initErrorVitals';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
};

class ErrorBoundaryLevel extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    getErrorVitals().logError(error, {
      context: 'error-boundary',
      isHandled: false,
      severity: 'error',
      componentStack: errorInfo.componentStack,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (this.state.hasError) {
      return <GenericErrorScreenV2 error={error} errorInfo={errorInfo} />;
    }
    return this.props.children;
  }
}

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return <ErrorBoundaryLevel>{children}</ErrorBoundaryLevel>;
}
