import { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ActiveSessionProviderV2 } from '@onramp/components/ActiveSessionProviderV2';
import { useAppParams } from '@onramp/components/AppManagerProvider';
import { Loader } from '@onramp/components/Loader';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useIsWalletApp } from '@onramp/hooks/useIsWalletApp';
import { useOnRampRouter } from '@onramp/hooks/useOnRampRouter';
import type { AnyWidgetParameters } from '@onramp/shared/appParams.schema';
import type {
  InitiateSessionHandlerPayload,
  InitiateSessionHandlerResponse,
} from '@onramp/shared/initiateSessionHandler.schema';
import { initiateSessionHandlerResponseSchema } from '@onramp/shared/initiateSessionHandler.schema';
import { isGsscValidAtom } from '@onramp/state/recoil/authState';
import { getIsLoggedInEgwSessionManager } from '@onramp/usm/useIsLoggedInEgwSessionManager';
import { leaveBreadcrumb } from '@onramp/utils/bugsnag';
import { isCBSessionActive } from '@onramp/utils/cbsession/isCBSessionActive';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { useLogWidgetEvent, useLogWidgetEventOnce } from '@onramp/utils/eventing/useLogWidgetEvent';
import { Experiments } from '@onramp/utils/experiments/experiments';
import { getIsEmbedded } from '@onramp/utils/getIsEmbedded';
import { useLogWidgetMetricOnce } from '@onramp/utils/metrics';
import { isMobileExperience } from '@onramp/utils/postMessage';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import { useSetAtom } from 'jotai';
import { AnalyticsEventImportance, flushQueue } from '@cbhq/client-analytics';
import { useIsTreatment } from '@cbhq/experiments';

import { debugLog } from './Modal/DebugMenu';

const messages = defineMessages({
  loading: {
    id: `ActiveSessionGuard.loading`,
    defaultMessage: 'Wait for a moment...',
    description: 'Loading message instructing the user to wait.',
  },
  redirecting: {
    id: `ActiveSessionGuard.redirecting`,
    defaultMessage: 'Redirecting to sign in...',
    description: 'Loading message indicating that the user will be redirected to login.',
  },
  title: {
    id: `ActiveSessionGuard.title`,
    defaultMessage: 'Sign in to Coinbase',
    description: 'Header title while checking if the user is logged in',
  },
});

export type SessionStatus = 'checking' | 'active' | 'inactive';

type ActiveSessionGuardProps = {
  children: ReactNode;
  status: SessionStatus;
  enabled: boolean;
};

const ActiveSessionGuard: React.FC<ActiveSessionGuardProps> = ({ children, status, enabled }) => {
  const { getRoute } = useOnRampRouter();
  const signinRoute = getRoute('Signin');
  const { formatMessage } = useIntl();
  const logWidgetEvent = useLogWidgetEvent();
  const logWidgetEventOnce = useLogWidgetEventOnce();
  const logWidgetMetricOnce = useLogWidgetMetricOnce();

  useEffect(() => {
    if (enabled) {
      if (status === 'inactive') {
        leaveBreadcrumb('Session checked, session is inactive');
        logWidgetEvent('redirected_to_login', undefined, {
          importance: AnalyticsEventImportance.high,
        });
        logWidgetMetricOnce({
          metricName: 'critical_step',
          value: 1,
          tags: { step: 'redirect-to-login' },
        });
        flushQueue().finally(() => {
          leaveBreadcrumb('Redirected to sigin route');
          window.location.assign(signinRoute);
        });
      } else if (status === 'active') {
        leaveBreadcrumb('Session checked, session is active');
        logWidgetEventOnce('has_active_session');
        logWidgetMetricOnce({
          metricName: 'critical_step',
          value: 1,
          tags: { step: 'has-active-session' },
        });
      }
    }
  }, [status, enabled, signinRoute, logWidgetEvent, logWidgetEventOnce, logWidgetMetricOnce]);

  if (!enabled || status === 'active') {
    return <>{children}</>;
  }

  return (
    <PageWrapper headerText={formatMessage(messages.title)}>
      <Loader
        subText={
          status === 'inactive'
            ? formatMessage(messages.redirecting)
            : formatMessage(messages.loading)
        }
      />
    </PageWrapper>
  );
};

export const ActiveSessionContext = createContext<SessionStatus | undefined>(undefined);

export const ActiveSessionProvider: React.FC<{ isGuardEnabled?: boolean }> = ({
  children,
  isGuardEnabled = true,
}) => {
  const [status, setStatus] = useState<SessionStatus>('checking');
  const { buy } = useAppParams();
  const isMounted = useRef(true);
  const setIsGsscValid = useSetAtom(isGsscValidAtom);
  const isWallet = useIsWalletApp();
  const isWalletMobile = isWallet && isMobileExperience();

  useEffectOnMount(() => {
    const appParams = buy;

    const safeSetStatus = (value: SessionStatus) => {
      if (isMounted.current) {
        setStatus(value);
      }
    };

    if (isWalletMobile) {
      void getIsLoggedInEgwSessionManager()
        .then((usmAuthStatus) => {
          debugLog(`usmAuthStatus: ${usmAuthStatus}`);
        })
        .catch((error) => {
          debugLog(`usmAuthStatus error - ${error}`);
        });
    }

    fetchSessions(appParams)
      .then(({ isAuthenticated, isGsscValid, authenticationProvider }) => {
        safeSetStatus(isAuthenticated ? 'active' : 'inactive');
        setIsGsscValid(isGsscValid);
        clientSessionIdStore.setAuthProvider(authenticationProvider);
      })
      .catch(() => {
        safeSetStatus('inactive');
      });
  });

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <ActiveSessionContext.Provider value={status}>
      <ActiveSessionGuard enabled={isGuardEnabled} status={status}>
        {children}
      </ActiveSessionGuard>
    </ActiveSessionContext.Provider>
  );
};

// Switch based off experiment here as this is under the ExperimentationProvider
export const ActiveSessionProviderSwitcher: React.FC = ({ children }) => {
  const isInUsmExperiment = useIsTreatment(Experiments.jan_2025_onramp_usm);

  if (isInUsmExperiment) {
    return <ActiveSessionProviderV2>{children}</ActiveSessionProviderV2>;
  }

  return <ActiveSessionProvider>{children}</ActiveSessionProvider>;
};

async function fetchInitiateSession(
  appParams: AnyWidgetParameters | undefined,
): Promise<InitiateSessionHandlerResponse> {
  const payload: InitiateSessionHandlerPayload = {
    isMobile: isMobileExperience(),
    appParams,
    isEmbedded: getIsEmbedded(),
  };

  const initiateSessionResponse = await fetchWithCommonHeaders('/initiate-session', {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = initiateSessionHandlerResponseSchema
    .catch({ isAuthenticated: false, isGsscValid: false })
    .parse(await initiateSessionResponse.json());

  if (!responseData.isAuthenticated && responseData.wsstUrl) {
    window.location.assign(responseData.wsstUrl);
    // return never-resolving promise to keep the loading state while the browser navigates
    return new Promise<never>(() => {});
  }

  return {
    ...responseData,
    isAuthenticated: responseData.isAuthenticated,
  };
}

/** Uses both /initiate-session & a UL session check to verify that we're authed.  */
async function fetchSessions(
  appParams: AnyWidgetParameters | undefined,
): Promise<InitiateSessionHandlerResponse> {
  const [initiateSessionResponse, hasActiveCBSession] = await Promise.all([
    fetchInitiateSession(appParams),
    isCBSessionActive(),
  ]);

  return {
    ...initiateSessionResponse,
    isAuthenticated: initiateSessionResponse.isAuthenticated && hasActiveCBSession,
  };
}

export const useIsSessionActive = (): SessionStatus => {
  const status = useContext(ActiveSessionContext);
  if (!status) {
    throw new Error('ActiveSessionProvider not present');
  }

  return status;
};
