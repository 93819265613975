import { useCallback, useContext, useEffect } from 'react';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { getServerMetadata } from '@onramp/utils/getServerMetadata';
import { isMobileExperience } from '@onramp/utils/postMessage';
import { InitParamsContext } from '@onramp/v2/client/contexts/initParams/InitParamsContext';
import { getErrorVitals } from '@onramp/v2/client/utils/initErrorVitals';
import { doOncePerSession } from '@onramp/v2/client/utils/session/doOncePerSession';
import { useGuestOnrampV2StoreValue } from '@onramp/v2/client/views/guest/onramp/state/store';
import { useOnrampInputStoreValue } from '@onramp/v2/client/views/onramp/state/store';
import type { AnalyticsEventData } from '@cbhq/client-analytics';
import { logEvent } from '@cbhq/client-analytics';

import type { NamedAnalyticsEventData } from './events';
import { GUEST_FLOW } from './events';

export const useLogOnrampEventV2 = () => {
  const context = useContext(InitParamsContext);
  const {
    flags: { hasContinuedFromInput },
  } = useOnrampInputStoreValue();
  const { paymentMethod } = useGuestOnrampV2StoreValue();

  const logGuestEventWithParams = useCallback(
    (eventName: string, optionalEventParameters: AnalyticsEventData) => {
      const initParams = context?.initParams ?? {};
      const isInReactNativeWebView = isMobileExperience();
      const defaultMetadata: Record<string, string | boolean | undefined> = {
        originalReferringDomain: getServerMetadata()?.originalReferringDomain,
        hasContinuedFromInput,
        isInReactNativeWebView,
        paymentMethod,
      };

      const walletAnalyticsData = clientSessionIdStore.getWalletAnalyticsData();
      const walletUserId = clientSessionIdStore.getWalletUserId();

      if (walletAnalyticsData !== undefined) {
        // user identified by walletUserId
        defaultMetadata.userUuid = clientSessionIdStore.getUserUuid();
      } else if (walletUserId !== undefined) {
        // for old wallet clients, where amplitude sesison is not shared between flows
        // user identified by entityHash
        defaultMetadata.walletUserId = walletUserId;
      }

      const metadata = {
        ...defaultMetadata,
        ...optionalEventParameters,
      };

      if (!context) {
        getErrorVitals().logError(
          new Error('useLogOnrampEventV2 must be used within a InitParamsProvider'),
          {
            context: 'useLogOnrampEventV2',
            severity: 'critical',
            isHandled: false,
          },
        );
      }

      if (context !== undefined) {
        logEvent(eventName, {
          appId: initParams.appId,
          ...metadata,
        });
      } else {
        logEvent(eventName, metadata);
      }
    },
    [hasContinuedFromInput, context, paymentMethod],
  );

  return logGuestEventWithParams;
};

export const useLogOnrampEventOnMountV2 = (
  eventName: string,
  optionalEventParameters: AnalyticsEventData,
) => {
  const logGuestEvent = useLogOnrampEventV2();

  useEffect(() => {
    logGuestEvent(eventName, optionalEventParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useLogOnrampEventOnceV2 = () => {
  const logGuestOnrampEvent = useLogOnrampEventV2();

  const log = useCallback(
    (data: NamedAnalyticsEventData) => {
      const idemKey = `once_per_session_event.${data.name}`;
      const logOnce = doOncePerSession(() => {
        logGuestOnrampEvent(GUEST_FLOW, data);
      }, idemKey);
      logOnce();
    },
    [logGuestOnrampEvent],
  );

  return log;
};
