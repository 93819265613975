import type { Amount, SelectedAsset } from '@onramp/v2/client/types/commonTypes';
import type { InputType } from '@onramp/v2/client/views/guest/onramp/state/types';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';

export type OnrampInputStore = {
  inputAmount: {
    userAmount: Amount;
    inputType: InputType;
  };
  selectedAsset: SelectedAsset | undefined;
  selectedNetwork: string | undefined;
  flags: {
    hasContinuedFromInput: boolean;
  };
  applePayToken: string;
};

/**
 * This atom will be the store for input changes i.e. the amount, network and asset selected.
 * This will be a separate store from the authed or guest checkout flow
 */

const onrampInputStore = atomWithImmer<OnrampInputStore>({
  inputAmount: {
    userAmount: { value: '0', currency: 'USD' },
    inputType: 'fiat',
  },
  selectedAsset: undefined,
  selectedNetwork: undefined,
  flags: {
    hasContinuedFromInput: false,
  },
  applePayToken: '',
});

export const useOnrampInputStoreValue = () => useAtomValue(onrampInputStore);
export const useUpdateOnrampInputStore = () => useSetAtom(onrampInputStore);
