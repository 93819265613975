import type { twoFactorMethodSelectorQuery$data } from '@onramp/data/__generated__/twoFactorMethodSelectorQuery.graphql';
import { loadable } from 'jotai/utils';
import { graphql } from '@cbhq/data-layer';

import { atomWithQueryAndMap } from '../utils';

const query = graphql`
  query twoFactorMethodSelectorQuery {
    viewer {
      userProperties @required(action: LOG) {
        secondFactor {
          twoFactorMethod
        }
      }
    }
  }
`;

export const twoFactorMethodSelector = atomWithQueryAndMap({
  query,
  variables: () => ({}),
  mapResponse: (data: twoFactorMethodSelectorQuery$data) =>
    data.viewer?.userProperties.secondFactor?.twoFactorMethod,
});

export const twoFactorMethodLoadable = loadable(twoFactorMethodSelector);
