import { z } from 'zod';

// Experience integration type
export const IntegrationTypeSchema = z
  .union([
    z.literal('standalone'), // New tab with query param initialization
    z.literal('secure_standalone'), // New tab/popup experience with postMessage initialization.
    z.literal('direct'), // Embedded experience. Login popup & open iframe modal.
  ])
  .optional()
  .default('standalone');

export type IntegrationType = z.infer<typeof IntegrationTypeSchema>;

// Target experience (standalone only) - where to open the widget.
export const IntegrationTargetSchema = z
  .union([
    z.literal('popup'), // Popup window
    z.literal('tab'), // New tab
  ])
  .optional()
  .default('popup');

export type IntegrationTarget = z.infer<typeof IntegrationTargetSchema>;

const wsstFlowSchema = z.enum(['import-token', 'initiate-session']);

export type WsstFlowParam = z.infer<typeof wsstFlowSchema>;

export const QueryParamsSchema = z.object({
  appId: z.string().min(1).optional(),
  sessionToken: z.string().min(1).optional(),
  walletUserId: z.string().optional(),
  // only for wallet, to share amplitude session
  sessionId: z.string().optional(),
  // only for wallet, to share amplitude session
  sessionUUID: z.string().optional(),
  // mobile apps can send us their app id, which will always be the same for the same device (based on https://npm.im/react-native-device-info)
  deviceId: z.string().optional(),
  nonce: z.string().optional(),
  attribution: z
    .union([z.literal('web'), z.literal('ios'), z.literal('android'), z.literal('extension')])
    .optional(),
  sdkVersion: z.string().optional(),
  appVersion: z.string().optional(),
  platformVersion: z.string().optional(),
  reactNativeVersion: z.string().optional(),
  type: IntegrationTypeSchema,
  target: IntegrationTargetSchema,
  theme: z.string().optional(),
  themeColor: z.string().optional(),
  forceFeature: z.literal('true').optional().catch(undefined),
  /** this is used by `@onramp/server/utils/wsst` & `initiateSessioHandler` to track which WSST flow a user comes from (general vs mobile-only) */
  wsstFlow: wsstFlowSchema.optional().catch(undefined),
  forceCkoNestingFix: z.string().optional(),
  forceEnableGc: z.literal('').optional(),
  // need to pass along sessionToken from offramp secureInit without causing secureInit on onramp
  returnToSessionToken: z.string().optional(),
});

export type SettingsQueryParams = z.infer<typeof QueryParamsSchema>;

export type PlatformAttribution = z.infer<typeof QueryParamsSchema>['attribution'];
