/**
 * @generated SignedSource<<db038b38cc012a6850fa9caa1e43a027>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PaymentMethodTypeV2 = "ACH_BANK_ACCOUNT" | "APPLE_PAY" | "ARGENTINE_BANK_ACCOUNT" | "BANCOMAT_PAY" | "BLIK" | "DENEB_IMPS" | "DENEB_UPI" | "DIRECT_DEPOSIT_ACCOUNT" | "EFT" | "FEDWIRE" | "FIAT_ACCOUNT" | "GIFTCARD" | "GOOGLE_PAY" | "IDEAL_BANK_ACCOUNT" | "INTERAC" | "MB_WAY" | "OPEN_BANKING" | "PAYMENT_METHOD_TYPE_UNKNOWN" | "PAYPAL_ACCOUNT" | "PIX" | "PIX_DEPOSIT" | "PIX_WITHDRAWAL" | "QD" | "REMITLY" | "RTP" | "SEPA_BANK_ACCOUNT" | "SEPA_V2" | "SG_FAST" | "SG_PAYNOW" | "SOFORT" | "SWIFT_BANK_ACCOUNT" | "TRUSTLY" | "UK" | "UK_BANK_ACCOUNT" | "WORLDPAY_CARD" | "ZENGIN_DEPOSIT" | "ZENGIN_DEPOSIT_V2" | "ZENGIN_WITHDRAWAL" | "ZENGIN_WITHDRAWAL_V2" | "ZEPTO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readPrimaryPaymentMethodFragment$data = {
  readonly limits: {
    readonly remainingAvailableLimit: {
      readonly value: string;
    };
  } | null;
  readonly primaryBuy: boolean;
  readonly type: PaymentMethodTypeV2;
  readonly uuid: string;
  readonly " $fragmentType": "readPrimaryPaymentMethodFragment";
};
export type readPrimaryPaymentMethodFragment$key = {
  readonly " $data"?: readPrimaryPaymentMethodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPrimaryPaymentMethodFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPrimaryPaymentMethodFragment"
};

(node as any).hash = "eb53e6d5a245feff54139e687a051c23";

export default node;
