/**
 * @generated SignedSource<<3dec7012ab38372b2aafa23d0e650f86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OnrampNetworksProviderQuery$variables = {
  assetUuid: string;
  include: boolean;
};
export type OnrampNetworksProviderQuery$data = {
  readonly viewer: {
    readonly assetByUuid?: {
      readonly asset: {
        readonly networks: ReadonlyArray<{
          readonly networkName: string | null;
        } | null> | null;
      };
      readonly supportedNetworks: {
        readonly supportedNetworks: ReadonlyArray<{
          readonly assetImageUrl: string | null;
          readonly isDefault: boolean | null;
          readonly networkName: string | null;
          readonly networkSlug: string | null;
        } | null> | null;
      } | null;
    } | null;
  };
};
export type OnrampNetworksProviderQuery = {
  response: OnrampNetworksProviderQuery$data;
  variables: OnrampNetworksProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "include"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "assetUuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "networkName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetNetwork",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "RECEIVE"
    },
    {
      "kind": "Literal",
      "name": "applicationContext",
      "value": "CB_PAY"
    }
  ],
  "concreteType": "SendReceiveSupportedNetworks",
  "kind": "LinkedField",
  "name": "supportedNetworks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SendReceiveSupportedNetwork",
      "kind": "LinkedField",
      "name": "supportedNetworks",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "networkSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDefault",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "supportedNetworks(action:\"RECEIVE\",applicationContext:\"CB_PAY\")"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnrampNetworksProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ViewerAsset",
                "kind": "LinkedField",
                "name": "assetByUuid",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnrampNetworksProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ViewerAsset",
                "kind": "LinkedField",
                "name": "assetByUuid",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56721faf016c95a63a37c4cbd6b2946c",
    "id": null,
    "metadata": {},
    "name": "OnrampNetworksProviderQuery",
    "operationKind": "query",
    "text": "query OnrampNetworksProviderQuery(\n  $assetUuid: Uuid!\n  $include: Boolean!\n) {\n  viewer {\n    assetByUuid(uuid: $assetUuid) @include(if: $include) {\n      asset {\n        networks {\n          networkName\n        }\n        id\n      }\n      supportedNetworks(action: RECEIVE, applicationContext: CB_PAY) {\n        supportedNetworks {\n          networkName\n          networkSlug\n          isDefault\n          assetImageUrl\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c902cf622fe9582320dfa9a2caa983c6";

export default node;
