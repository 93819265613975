import { useQuery } from 'react-query';
import { createFetch, defaultFetchConfig } from '@cbhq/data-layer';

// Entry-gateway mounted path for checking session validity (defined in entry-gateway configuration)
export const ENTRY_GATEWAY_SESSION_CHECK_PATH = '/session';

let checkLoginRequest: boolean;
let checkLoginResponse: boolean;

const fetch = createFetch({
  ...defaultFetchConfig,
  credentials: 'include',
  retryNetwork: true,
  // Disable these since they're not accepted by default for CORS requests
  disableAnalyticsHeaders: true,
});

async function getIsLoggedIn(url: string): Promise<boolean> {
  if (typeof checkLoginResponse !== 'undefined') {
    return checkLoginResponse;
  }

  if (!checkLoginRequest) {
    checkLoginRequest = await fetch(url).then((response) => {
      if (response instanceof Error) {
        // eslint-disable-next-line no-console
        console.error('Error checking for a session.');
        checkLoginRequest = false;
        throw response;
      } else {
        checkLoginResponse = response.status === 200;
      }
      return checkLoginResponse;
    });
  }

  return checkLoginRequest;
}

export async function getIsLoggedInEgwSessionManager(): Promise<boolean> {
  return getIsLoggedIn(ENTRY_GATEWAY_SESSION_CHECK_PATH);
}

export function useIsLoggedInEgwSessionManager() {
  const { data, error } = useQuery({
    queryKey: ['isLoggedInEgwSession'],
    queryFn: getIsLoggedInEgwSessionManager,
  });

  if (typeof data === 'boolean') return data;
  if (error instanceof Error) return error;

  return Boolean(data);
}
