import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import { BuyFlowKillSwitchBanner } from '@onramp/components/BuyFlowKillSwitchBanner';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { useSplashScreenContext } from '@onramp/components/SplashScreen/context';
import type { AssetListFragment$key } from '@onramp/data/__generated__/AssetListFragment.graphql';
import type { selectAssetV2Query } from '@onramp/data/__generated__/selectAssetV2Query.graphql';
import type { useDefaultSourceOfFundsQuery } from '@onramp/data/__generated__/useDefaultSourceOfFundsQuery.graphql';
import type { useNewSourcesOfFundsQuery as UseNewSourcesOfFundsQuery } from '@onramp/data/__generated__/useNewSourcesOfFundsQuery.graphql';
import { useDefaultSourceOfFunds } from '@onramp/hooks/useDefaultSourcesOfFunds';
import { defaultSourceOfFundsQuery } from '@onramp/hooks/useDefaultSourcesOfFunds/useDefaultSourceOfFunds';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useIsWalletApp } from '@onramp/hooks/useIsWalletApp';
import { useOnRampRouter } from '@onramp/hooks/useOnRampRouter';
import { usePrefetchQuery } from '@onramp/hooks/usePrefetchQuery';
import { useReportAndViewError } from '@onramp/hooks/useReportAndViewError';
import { useResetError } from '@onramp/hooks/useResetError';
import { useNewSourcesOfFundsQuery } from '@onramp/hooks/useSourcesOfFunds/useNewSourcesOfFunds';
import { useValidateAddressAllowList } from '@onramp/hooks/validation/useValidateAddressAllowList';
import type { SelectedAsset } from '@onramp/state/BuyWidgetState';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState';
import { useOnrampNetworks } from '@onramp/state/contexts';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { haveAppliedDefaultAssetAtom } from '@onramp/state/recoil/atoms/initProcessAtoms';
import { useIsBuyFlowType } from '@onramp/state/recoil/buyFlowType';
import { leaveBreadcrumb } from '@onramp/utils/bugsnag';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import type { BaseError } from '@onramp/utils/errors';
import { coerceError, HandledError, InternalError } from '@onramp/utils/errors';
import { logSelectAssetClick } from '@onramp/utils/eventing';
import { useLogOnrampEvent } from '@onramp/utils/eventing/useLogOnrampEvent';
import exitWidget from '@onramp/utils/exitWidget';
import { maskWalletAddress } from '@onramp/utils/maskWalletAddress';
import { logWidgetMetricOnce, useLogWidgetMetric } from '@onramp/utils/metrics';
import {
  broadcastEvent,
  isEmbeddedExperience,
  isMobileExperience,
} from '@onramp/utils/postMessage';
import { useCbPayAssetsFilter } from '@onramp/utils/useCbPayAssetsFilter';
import { useIsApplePayAvailable } from '@onramp/utils/useIsApplePayAvailable';
import { useIsBuyFlowKillSwitched } from '@onramp/utils/useIsBuyFlowKillSwitched';
import { useAtom } from 'jotai';
import { Button } from '@cbhq/cds-web/buttons';
import { SearchInput } from '@cbhq/cds-web/controls';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { TextBody } from '@cbhq/cds-web/typography';
import { graphql, useFragment, useLazyLoadQuery } from '@cbhq/data-layer';

import { AssetList, assetListFragment } from './components/AssetList';
import { CreateWalletTransactionWrapper } from './components/CreateWalletTransactionWrapper';
import { LoadingAssets } from './components/LoadingAssets';
import { readCBPayAssetAsOnrampState } from './fragments/readCBPayAssetAsOnrampState';
import { useIsAssetKillSwitched } from './hooks/useIsAssetKillSwitched';
import { useRunChecksAgainstAssetList } from './hooks/useRunChecksAgainstAssetList';
import { useSelectAssetTabs } from './hooks/useSelectAssetTabs';
import { selectAssetReusedMessages } from './messages';

const messages = defineMessages({
  chooseCoin: {
    id: 'SelectAssetPage.chooseCoin',
    defaultMessage: 'Select asset to add',
    description: 'Select asset page title',
  },
  loadingAssets: {
    id: 'SelectAssetPage.loadingAssets',
    defaultMessage: 'Loading assets...',
    description: 'Loading message for crypto assets',
  },
  invalidAddressTitle: {
    id: 'SelectAssetPage.invalidAddressTitle',
    defaultMessage: 'Invalid wallet address',
    description: 'Error message title when the wallet address is invalid',
  },
  invalidAddressDescription: {
    id: 'SelectAssetPage.invalidAddressDescription',
    defaultMessage:
      '{address} is invalid to send {asset} to. Please select another cryptocurrency or review your address details.',
    description:
      'Error message description when sending an asset to the wrong wallet address. Prompts user to try again with a different cryptocurrency or look at their wallet address.',
  },
  invalidAddressDescriptionNoAddressInput: {
    id: 'SelectAssetPage.invalidAddressDescriptionNoAddressInput',
    defaultMessage:
      'No compatible wallet address found to send {asset} to. Please select another cryptocurrency or review your address details.',
    description:
      'Error message description when sending an asset to the wrong wallet address. Prompts user to try again with a different cryptocurrency or look at their wallet address.',
  },
  addressBookErrorDescription: {
    id: 'SelectAssetPage.addressBookErrorDescription',
    defaultMessage:
      '{address} has not been added to your allowlist for {asset}. Please update your allowlist on {link} and try again.',
    description:
      "Error message description when a user's wallet address has not been added to their allowlist",
  },
  assetKilledTitle: {
    id: 'SelectAssetPage.assetKilledTitle',
    defaultMessage: '{asset} is temporarily unavailable',
    description: 'Error screen title shown when the user picks a disabled asset',
  },
  assetKilledDescription: {
    id: 'SelectAssetPage.assetKilledDescription',
    defaultMessage:
      'We’re upgrading our system right now, so buying or transferring {asset} is currently unavailable. The update should only take about an hour. Try again later.',
    description: 'Error screen description shown when the user picks a disabled asset',
  },
  assetKilledCtaLabel: {
    id: 'SelectAssetPage.assetKilledCtaLabel',
    defaultMessage: 'Got it',
    description:
      'Label for button that dismisses the error screen shown when the user picks a disabled asset',
  },
  sourceOfFundsGenericErrorMessage: {
    id: 'SelectAssetPage.sourceOfFundsGenericErrorMessage',
    defaultMessage:
      'Failed to retrieve payment methods. Please try again or add a new payment method.',
    description: 'Error message when failing to retrieve a default source of funds.',
  },
});

export type SelectAssetCallbackProps = {
  asset: SelectedAsset;
};

export const selectAssetQuery = graphql`
  query selectAssetV2Query($filter: CBPayAssetFilters!) {
    viewer {
      # Tradable assets queries
      ...AssetListFragment @arguments(filter: $filter)
      ...useSelectAssetTabsFragment @arguments(filter: $filter)
      ...useRunChecksAgainstAssetListFragment @arguments(filter: $filter)
    }
  }
`;

function SelectAssetContent() {
  const { formatMessage } = useIntl();
  const router = useOnRampRouter();
  const { selectedAsset, sourceOfFunds, selectedNetwork, sourceOfFundsV2 } = useBuyWidgetState();
  const reportAndViewError = useReportAndViewError();
  const resetError = useResetError();
  const { shouldAutoSelectAsset, getConfigByAssetTicker } = useDestinationWalletsV2();
  const validateAddressAllowlist = useValidateAddressAllowList();
  const { computeActiveWalletAddress } = useOnrampNetworks();
  const { isAssetKilled } = useIsAssetKillSwitched();
  const filter = useCbPayAssetsFilter();
  const logWidgetMetric = useLogWidgetMetric();
  const logOnrampEvent = useLogOnrampEvent();
  const cameFromOneClickBuy = useIsBuyFlowType('one-click-buy');
  const { defaultAsset } = useWidgetParams('buy');
  const { viewer } = useLazyLoadQuery<selectAssetV2Query>(selectAssetQuery, { filter });
  const applePayEnabled = useIsApplePayAvailable();

  const [haveAppliedDefaultAsset, setHaveAppliedDefaultAsset] = useAtom(
    haveAppliedDefaultAssetAtom,
  );

  usePrefetchQuery<UseNewSourcesOfFundsQuery>(
    useNewSourcesOfFundsQuery,
    useMemo(() => ({ applePayEnabled }), [applePayEnabled]),
  );

  usePrefetchQuery<useDefaultSourceOfFundsQuery>(
    defaultSourceOfFundsQuery,
    useMemo(
      () => ({
        uuid: '',
        skipAccountByUuid: true,
        applePayEnabled,
        fiatCurrency: '',
        skipAssetByUuid: true,
      }),
      [applePayEnabled],
    ),
  );

  const getDefaultSourceOfFunds = useDefaultSourceOfFunds();

  const attribution = clientSessionIdStore.getPlatformAttribution();
  const isMobilePlatform = attribution === 'ios' || attribution === 'android';
  const handleOnSelect = useCallback(
    async ({ asset }: SelectAssetCallbackProps) => {
      try {
        if (!asset.assetUuid || !asset.ticker) {
          const { networks, ...reportableAsset } = asset;
          throw new InternalError('Asset data missing from selected asset').addMetadata({
            ...reportableAsset,
          });
        }

        if (isAssetKilled(asset)) {
          const { networks, ...reportableAsset } = asset;

          const assetKilledErrorRichMessage = (
            <VStack flexGrow={1} alignItems="center">
              <TextBody as="p" color="foregroundMuted" spacingBottom={3} align="center">
                {formatMessage(messages.assetKilledDescription, { asset: asset.ticker })}
              </TextBody>
              {!shouldAutoSelectAsset && (
                <Button variant="primary" block onPress={resetError}>
                  {formatMessage(messages.assetKilledCtaLabel)}
                </Button>
              )}
            </VStack>
          );

          throw new HandledError({
            message: formatMessage(messages.assetKilledDescription, { asset: asset.ticker }),
            debugMessage: 'Asset disabled by kill switch',
          })
            .addMetadata({
              ...reportableAsset,
            })
            .addHandlingParams({
              backType: shouldAutoSelectAsset && !isMobilePlatform ? 'none' : 'back',
              goBackTo: 'Buy.SelectAsset',
              richMessage: assetKilledErrorRichMessage,
              title: formatMessage(messages.assetKilledTitle, { asset: asset.ticker }),
              pictogramName: 'spot:unsupportedAsset',
              pageTitle: 'Coinbase Onramp',
            });
        }

        const walletAddress = computeActiveWalletAddress(asset.ticker, asset.networks);

        if (!walletAddress) {
          const config = getConfigByAssetTicker(asset.ticker, asset.networks);
          const addressFromConfig = config?.address;
          let error: BaseError;

          // We should be able to match where this asset was requested from so it's an internal error
          if (!addressFromConfig) {
            error = new InternalError(
              'Could not find wallet address in destination wallet config by ticker',
            ).addHandlingParams({
              message: formatMessage(messages.invalidAddressDescriptionNoAddressInput, {
                asset: asset.ticker,
              }),
            });
          } else {
            error = new HandledError({
              message: formatMessage(messages.invalidAddressDescription, {
                asset: asset.ticker,
                address: addressFromConfig,
              }),
              debugMessage: 'invalid wallet address provided for asset',
            });
          }

          logWidgetMetric({
            metricName: 'invalid_wallet_address',
            value: 1,
            tags: {
              in_create_wallet_transaction_treatment: 'true',
              asset_ticker: asset.ticker,
            },
          });

          logOnrampEvent('invalid_wallet_address', {
            isInCreateWalletTransactionTreatment: true,
            assetTicker: asset.ticker,
          });

          throw error
            .addHandlingParams({
              title: formatMessage(messages.invalidAddressTitle),
            })
            .addMetadata({
              asset_ticker: asset.ticker,
              masked_address: addressFromConfig
                ? maskWalletAddress(addressFromConfig)
                : 'undefined',
            });
        }

        // Check whether address book is enabled and if the wallet address has been added for the particular currency.
        const { addressError } = validateAddressAllowlist(walletAddress, asset.ticker);
        if (addressError) {
          throw addressError;
        }

        const defaultSourceOfFunds = await getDefaultSourceOfFunds({
          selectedAssetUuid: asset.assetUuid,
          assetTicker: asset.ticker,
        });
        // Check for uuid since that could also be undefined
        if (!defaultSourceOfFunds?.v2.uuid) {
          throw coerceError('No accounts or payment methods found.').addHandlingParams({
            message: formatMessage(messages.sourceOfFundsGenericErrorMessage),
          });
        }

        sourceOfFunds.onChange(defaultSourceOfFunds.v1);
        sourceOfFundsV2.onChange(defaultSourceOfFunds.v2);

        leaveBreadcrumb(`Selected asset ${asset.ticker}`);
        selectedAsset.onChange(asset);
        logSelectAssetClick(asset.ticker);

        if (cameFromOneClickBuy) {
          router.push('Buy.OneClick');
        } else if (!cameFromOneClickBuy) {
          selectedNetwork.onChange(undefined);
          router.push('Buy.Input');
        }
      } catch (error) {
        reportAndViewError(coerceError(error));
      }
    },
    [
      isAssetKilled,
      computeActiveWalletAddress,
      validateAddressAllowlist,
      getDefaultSourceOfFunds,
      selectedAsset,
      cameFromOneClickBuy,
      formatMessage,
      shouldAutoSelectAsset,
      resetError,
      isMobilePlatform,
      getConfigByAssetTicker,
      logWidgetMetric,
      logOnrampEvent,
      sourceOfFunds,
      sourceOfFundsV2,
      router,
      selectedNetwork,
      reportAndViewError,
    ],
  );

  const assetData = useFragment<AssetListFragment$key>(assetListFragment, viewer);

  const shouldApplyDefaultAsset = useMemo(
    () => Boolean(defaultAsset) && !haveAppliedDefaultAsset,
    [defaultAsset, haveAppliedDefaultAsset],
  );

  useEffect(
    function applyDefaultAsset() {
      if (haveAppliedDefaultAsset || !defaultAsset) {
        return;
      }

      const assetRef = assetData.cbPayTradableAssets?.edges?.find(
        // UUID is preferred, ticker is legacy support for wallet
        (e) => defaultAsset === e?.node?.symbol || defaultAsset === e?.node?.uuid,
      )?.node;

      if (!assetRef) {
        leaveBreadcrumb(
          `Could not apply default asset because assetRef is empty for ${defaultAsset}`,
        );
        setHaveAppliedDefaultAsset(true);
        return;
      }

      leaveBreadcrumb(`Applied default asset ${defaultAsset}`);
      const asset = readCBPayAssetAsOnrampState(assetRef);
      setHaveAppliedDefaultAsset(true);
      void handleOnSelect({ asset });
    },
    [
      assetData.cbPayTradableAssets?.edges,
      defaultAsset,
      handleOnSelect,
      haveAppliedDefaultAsset,
      selectedAsset,
      selectedAsset.value,
      setHaveAppliedDefaultAsset,
    ],
  );

  useRunChecksAgainstAssetList(viewer, handleOnSelect);

  if (shouldAutoSelectAsset || shouldApplyDefaultAsset) {
    return <LoadingAssets />;
  }

  return <SelectAssetV2Content viewer={viewer} onSelect={handleOnSelect} />;
}

function SelectAssetV2Content({
  onSelect,
  viewer,
}: {
  onSelect: (args: SelectAssetCallbackProps) => Promise<void>;
  viewer: selectAssetV2Query['response']['viewer'];
}) {
  const { formatMessage } = useIntl();
  const logOnrampEvent = useLogOnrampEvent();
  const { defaultExperience } = useWidgetParams('buy');
  const splashScreenContext = useSplashScreenContext();

  const { activeTab, handleSelectTab, tabs } = useSelectAssetTabs(
    viewer,
    defaultExperience,
    defaultExperience,
  );

  const isWalletApp = useIsWalletApp();
  useEffectOnMount(() => {
    if (isWalletApp) {
      broadcastEvent({ eventName: 'select_asset_loaded' });
    }
  });

  const handleSelect = useCallback(
    async ({ asset }: SelectAssetCallbackProps) => {
      await onSelect({ asset });
    },
    [onSelect],
  );

  useEffect(() => {
    if (activeTab) {
      logOnrampEvent('viewed_select_asset_tab', { tab_id: activeTab });
    }
  }, [activeTab, logOnrampEvent]);

  const handleBuyAnotherAssetPressed = useCallback(() => {
    logOnrampEvent('clicked_select_asset_buy_new_asset_button');
    handleSelectTab('buy');
  }, [handleSelectTab, logOnrampEvent]);

  const [searchQuery, setSearchQuery] = useState('');

  if (splashScreenContext.isVisible) {
    splashScreenContext.dismissSplashScreen();
  }

  return (
    <>
      <Box spacing={2} spacingBottom={1}>
        <SearchInput
          value={searchQuery}
          onChangeText={setSearchQuery}
          autoFocus={false}
          placeholder={formatMessage(selectAssetReusedMessages.searchPlaceholder)}
          compact
        />
      </Box>
      {!searchQuery && (
        <TabNavigation
          value={activeTab}
          tabs={tabs}
          onChange={handleSelectTab as (tabId: string) => void}
          spacingHorizontal={3}
          spacingBottom={1}
        />
      )}
      <AssetList
        viewerRef={viewer}
        onSelect={handleSelect}
        searchQuery={searchQuery}
        activeTab={activeTab}
        activeTabOnChange={handleSelectTab}
        onBuyAnotherAssetPressed={handleBuyAnotherAssetPressed}
      />
    </>
  );
}

export default function SelectAsset() {
  const { goBack } = useOnRampRouter();
  const cameFromOneClickBuy = useIsBuyFlowType('one-click-buy');
  const headerText = useSelectAssetHeaderText();
  const shouldShowBackButton =
    isEmbeddedExperience() || isMobileExperience() || cameFromOneClickBuy;
  const handleOnBack = useCallback(() => {
    if (cameFromOneClickBuy) {
      goBack();
    } else {
      logWidgetMetricOnce({
        metricName: 'critical_step',
        value: 1,
        tags: { step: 'exit-from-back-button' },
      });
      exitWidget();
    }
  }, [cameFromOneClickBuy, goBack]);
  const isBuyFlowKillSwitched = useIsBuyFlowKillSwitched();

  return (
    <PageWrapper
      headerText={headerText}
      onBack={shouldShowBackButton ? handleOnBack : undefined}
      loadingElement={<LoadingAssets />}
    >
      {isBuyFlowKillSwitched && <BuyFlowKillSwitchBanner />}
      <SelectAssetContent />
      <CreateWalletTransactionWrapper />
    </PageWrapper>
  );
}

export const useSelectAssetHeaderText = () => {
  const { formatMessage } = useIntl();
  const { shouldAutoSelectAsset } = useDestinationWalletsV2();
  const cameFromOneClickBuy = useIsBuyFlowType('one-click-buy');
  const shouldShowLoadingTitle = shouldAutoSelectAsset && !cameFromOneClickBuy;
  return shouldShowLoadingTitle
    ? formatMessage(messages.loadingAssets)
    : formatMessage(messages.chooseCoin);
};
