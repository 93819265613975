import type { PlatformAttribution } from '@onramp/shared/queryParams.schema';
import { jsonStringSchema } from '@onramp/shared/utils.schema';
import { z } from 'zod';
import { SubjectType } from '@cbhq/client-analytics';

const EXPERIMENT_PATH = 'v3/coinbase.experiment.ExperimentService/ListSplitTestGroups';

/**
 * References:
 * - https://github.cbhq.net/infra/config-service/blob/f0c8ec3a5d2e84a627626d50f79d2f24e18660ef/gen/go/coinbase/config/parameter_types.pb.go#L477-L490
 */
export const SUBJECT_TYPE_TO_NUMERIC_VALUE_MAP = {
  [SubjectType.user]: 1,
  [SubjectType.device]: 2,
  [SubjectType.wallet_user]: 3,
  [SubjectType.nft_user]: 4,
  [SubjectType.commerce_merchant]: 5,
  [SubjectType.uuid]: 6,
  [SubjectType.edp_fingerprint_id]: 7,
  [SubjectType.custom]: 10,
} as const; /** TODO [ONRAMP-2368]: add `satisfies Record<SubjectType, number>` to make sure we include all SubjectType values */

export type SubjectTypeNumber =
  typeof SUBJECT_TYPE_TO_NUMERIC_VALUE_MAP[keyof typeof SUBJECT_TYPE_TO_NUMERIC_VALUE_MAP];

/**
 * Reference: https://github.cbhq.net/infra/config-service/blob/a9cd956c/protos/coinbase/config/parameter_types.proto#L278-L288
 */
export const PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP = {
  unknown: 0,
  ios: 1,
  android: 2,
  web: 3,
  proMobileIos: 4,
  proMobileAndroid: 5,
  walletIos: 6,
  walletAndroid: 7,
  walletExtension: 8,
} as const;

export type PlatformType = keyof typeof PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP;
export type PlatformTypeNumber =
  typeof PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP[keyof typeof PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP];

export const EXPERIMENT_PATH_CLIENT = `/cb-api-proxy/${EXPERIMENT_PATH}`;
export const EXPERIMENT_PATH_SERVER = `/api/${EXPERIMENT_PATH}`;

export type ExperimentSettings = {
  platformType: PlatformType;
  subjectType: SubjectType;
};

export const APP_ID_EXPERIMENT_SETTINGS: ExperimentSettings = {
  platformType: 'web',
  subjectType: SubjectType.custom,
};

/**
 * According to https://github.cbhq.net/mono/repo/tree/master/data/experiment-service
 * We have to fetch our experiments via device_id, hence we use subject_type: 2
 * and client_type: 3 which stands for web usage.
 */
export const DEVICE_EXPERIMENT_SETTINGS: ExperimentSettings = {
  platformType: 'web',
  subjectType: SubjectType.device,
};

// we'll need another settings map for the retail app with platform types 'android' & 'ios'
export const WALLET_USER_EXPERIMENT_SETTINGS: Record<
  'ios' | 'android' | 'extension',
  ExperimentSettings
> = {
  extension: {
    platformType: 'walletExtension',
    subjectType: SubjectType.wallet_user,
  },
  android: {
    platformType: 'walletAndroid',
    subjectType: SubjectType.wallet_user,
  },
  ios: {
    platformType: 'walletIos',
    subjectType: SubjectType.wallet_user,
  },
};

export const platformAttributionToWalletUserExperimentSettingsMap: Record<
  NonNullable<PlatformAttribution>,
  ExperimentSettings | undefined
> = {
  web: undefined,
  ...WALLET_USER_EXPERIMENT_SETTINGS,
};

export const experimentsConfigSchema = z.object({
  groups: z.array(
    z.object({
      test: z.string(),
      group: z.string(),
      isTracked: z.optional(z.boolean()),
    }),
  ),
});

export const parsedExperimentCookieSchema = experimentsConfigSchema.extend({
  /** this is included to check if the subject id matches — if not, the cookie is ignored. it's not defined in the overrides cookie */
  subjectId: z.string().optional(),
});

export type ParsedExperimentCookie = z.TypeOf<typeof parsedExperimentCookieSchema>;

export const experimentCookieSchema = jsonStringSchema
  .pipe(parsedExperimentCookieSchema)
  .optional()
  .catch(undefined);

export type ExperimentsResponse = z.infer<typeof experimentsConfigSchema>;
export type ExperimentGroupData = ExperimentsResponse['groups'][number];

export type FetchExperimentsPayload = {
  subject_id: string;
  client: { type: PlatformTypeNumber; version?: string };
  subject_type: SubjectTypeNumber;
};
