import { parseNetworkIdToStoneId } from '@onramp/utils/blockchains/networkMetadata';
import type { MessageData } from '@onramp/utils/postMessage';
import { isNotNullish } from '@onramp/utils/types';
import { RegexChecks } from '@onramp/utils/validation';
import { z } from 'zod';

export const NetworkIdSchema = z.string().transform(parseNetworkIdToStoneId);

/** A ISO 4217 currency code, which must always have 3 letters {@link https://www.iso.org/iso-4217-currency-codes.html} */
export const FiatCurrencySchema = z
  .string()
  .length(3)
  .transform((x) => x.toUpperCase());

export const DefaultPaymentMethodSchema = z.enum([
  'CRYPTO_ACCOUNT',
  'FIAT_WALLET',
  // Payment method ones
  'CARD',
  'ACH_BANK_ACCOUNT',
  'APPLE_PAY',
]);

const assetSymbolAliasMap = new Map<string, string>([
  // CBBTC is wrapped Bitcoin on the Ethereum or Base network. Internally the CBBTC symbol does not exist, you can only
  // buy BTC. But when you send it to an address on Ethereum or Base, you actually recieve CBBTC.
  ['CBBTC', 'BTC'],
]);

// Override specific input asset symbols to match the asset symbols used internally by Coinbase. We have to do this
// because wallet apps will send us the external symbol of assets because that's what the user will receive in their
// wallet, but Coinbase systems don't recognize those symbols as valid assets to buy/send.
function assetSymbolTransform(asset: string) {
  return assetSymbolAliasMap.get(asset.toUpperCase()) ?? asset.toUpperCase();
}

// This can be either an internal UUID, or a symbol like "BTC" or "ETH".
const assetStringType = z.string().transform(assetSymbolTransform);

// Example: [{ address: "0x1A2C69d3F9...", blockchains: ["ethereum", "avalanche-c-chain"] }]
export const BaseDestinationAddressAppParamsSchema = z.array(
  z.object({
    address: z.string().regex(RegexChecks.walletAddress).min(1),
    blockchains: z
      .array(NetworkIdSchema)
      .transform((array) => array.filter(isNotNullish))
      .optional(),
    assets: z.array(assetStringType).optional(),
    supportedNetworks: z
      .array(NetworkIdSchema)
      .transform((array) => array.filter(isNotNullish))
      .optional(),
  }),
);

export type DestinationAddressAppParam = z.infer<
  typeof BaseDestinationAddressAppParamsSchema
>[number];

/**
 * Merges and deduplicates arrays. If all parameters are nullish, returns `undefined`.
 *
 * @example
 * mergeDestinationWalletArrays([1, 2], [2, 3]) // [1, 2, 3]
 * mergeDestinationWalletArrays([1, 2], undefined) // [1, 2]
 * mergeDestinationWalletArrays(undefined, undefined) // undefined
 * mergeDestinationWalletArrays([], []) // []
 */
export const mergeDestinationWalletArrays = <T>(
  ...arrays: (T[] | undefined)[]
): T[] | undefined => {
  const definedArrays = arrays.filter(isNotNullish);
  return definedArrays.length > 0 ? Array.from(new Set(definedArrays.flat(1))) : undefined;
};

export const DestinationAddressAppParamsSchema = BaseDestinationAddressAppParamsSchema.transform(
  (destinationWallets): DestinationAddressAppParam[] => {
    const addressToDestinationWalletMap = new Map<string, DestinationAddressAppParam>();

    for (const destinationWallet of destinationWallets) {
      const sameAddressWallet = addressToDestinationWalletMap.get(destinationWallet.address);

      // creating an object from scratch so that we get errors if new fields are added and we get forced to merge them
      // always creating the object so that we deduplicate array elements
      addressToDestinationWalletMap.set(destinationWallet.address, {
        address: destinationWallet.address,
        assets: mergeDestinationWalletArrays(sameAddressWallet?.assets, destinationWallet.assets),
        blockchains: mergeDestinationWalletArrays(
          sameAddressWallet?.blockchains,
          destinationWallet.blockchains,
        ),
        supportedNetworks: mergeDestinationWalletArrays(
          sameAddressWallet?.supportedNetworks,
          destinationWallet.supportedNetworks,
        ),
      });
    }

    return Array.from(addressToDestinationWalletMap.values());
  },
);

// e.g. { "0x1": ["ethereum", "base"], "1ab": ["solana"] }
const AddressToBlockchainsMapSchema = z.record(z.string(), z.array(z.string()));

// Converts the addresses and assets params into an equivelant destinationWallets param.
// e.g. addresses={"0x1":["ethereum","base"],"1ab":["solana"]}
// becomes destinationWallets=[{address:"0x1",blockchains:["ethereum","base"]},{address:"1ab",blockchains:["solana"]}]
// e.g. addresses={"0x1":["ethereum","base"]}&assets=["usdc"]
// becomes destinationWallets=[{address:"0x1",supportedNetworks:["ethereum","base"],assets:["usdc"]}]
export function addressAndAssetsToDestinationWallets(
  addresses: z.infer<typeof AddressToBlockchainsMapSchema>,
  assets?: string[],
) {
  return Object.entries(addresses).map(([address, networks]) => {
    return {
      address,
      assets: assets?.map((asset) => asset.toUpperCase()),
      blockchains: assets ? undefined : networks,
      supportedNetworks: assets ? networks : undefined,
    };
  });
}

export const BaseBuyWidgetSchema = z.object({
  widget: z.literal('buy'),
  fiatCurrency: FiatCurrencySchema.optional(),
  presetFiatAmount: z.number().optional(),
  presetCryptoAmount: z.number().optional(),
  // destinationWallets is marked as optional in this schema because we support providing addresses and assets instead
  // and converting those params into destinationWallets, but at runtime destinationWallets will always be present
  destinationWallets: DestinationAddressAppParamsSchema.optional(),
  // If destinationWallets is not provided, then addresses is required
  addresses: AddressToBlockchainsMapSchema.optional(),
  assets: z.array(assetStringType).optional(),
  defaultNetwork: NetworkIdSchema.optional(),
  defaultAsset: assetStringType.optional(),
  defaultExperience: z.enum(['buy', 'send']).optional(),
  handlingRequestedUrls: z.boolean().optional(),
  /**
   * (buy-and-send only) if present, the user amount should be transaction total, including fees;
   * if not present, the user amount should be how much crypto they're buying and fees are added to it
   */
  quoteId: z.string().optional(),
  defaultPaymentMethod: DefaultPaymentMethodSchema.optional(),
  /** Used to associate transactions with a user for the partner to consume the Transaction Status API. */
  partnerUserId: z.string().optional(),
  /** Token used for secure initialization */
  sessionToken: z.string().optional(),
  /** Optional redirect URL, if provided and valid we'll redirect to this when the send succeeds */
  redirectUrl: z.string().optional(),
  /** Name of the end partner that we can attribute transactions to */
  endPartnerName: z.string().optional(),
});

// This transorm is run on the BaseBuyWidgetSchema to enforce that either destinationWallets or addresses is provided,
// and will convert the addresses and assets params into a destinationWallets param.
function transformAddressesAndAssetsToDestinationWallets(
  data: z.infer<typeof BaseBuyWidgetSchema>,
  ctx: z.RefinementCtx,
) {
  // We remove addresses and assets from the final result because we transform them into destinationWallets
  const undefineAddressesAndAssets = {
    addresses: undefined,
    assets: undefined,
  };

  if (data.addresses) {
    const destinationWallets = addressAndAssetsToDestinationWallets(data.addresses, data.assets);
    return {
      ...data,
      destinationWallets,
      ...undefineAddressesAndAssets,
    };
  }

  if (data.destinationWallets) {
    return {
      ...data,
      destinationWallets: data.destinationWallets,
      ...undefineAddressesAndAssets,
    };
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Either addresses or destinationWallets must be defined',
  });
  return z.NEVER;
}

export const BuyWidgetSchema = BaseBuyWidgetSchema.transform(
  transformAddressesAndAssetsToDestinationWallets,
);

export const WidgetKeySchema = BaseBuyWidgetSchema.shape.widget;

export type WidgetKey = z.infer<typeof WidgetKeySchema>;

export const Schemas: Record<WidgetKey, z.AnyZodObject | AnyZodEffects> = {
  buy: BuyWidgetSchema,
};

export const AnyWidgetParametersSchema = BuyWidgetSchema;

export type AnyWidgetParameters = z.infer<typeof AnyWidgetParametersSchema>;

type ParsedResponse<T> = {
  success: boolean;
  error?: z.ZodError;
  data: T;
};

// Zod will strip any additional parameters that aren't part of the schema
// so we don't store anything in state we don't need to.
export function parseWidgetParams(
  data?: MessageData & { widget?: WidgetKey },
  shouldThrow = false,
): ParsedResponse<AnyWidgetParameters | undefined> {
  const baseSchema = WidgetKeySchema.safeParse(data?.widget);
  if (!baseSchema.success) {
    if (shouldThrow) {
      throw baseSchema.error;
    }
    return {
      success: baseSchema.success,
      error: baseSchema.error,
      data: undefined,
    };
  }

  const schema = Schemas[baseSchema.data].safeParse(data);
  if (!schema.success) {
    if (shouldThrow) {
      throw schema.error;
    }
    return {
      success: schema.success,
      error: schema.error,
      data: undefined,
    };
  }

  return {
    success: schema.success,
    data: schema.data as AnyWidgetParameters,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyZodEffects = z.ZodEffects<any, any, any>;
