import { flushQueue } from '@cbhq/client-analytics';

import { broadcastEvent } from './postMessage';

export default function exitWidget() {
  flushQueue().finally(() => {
    broadcastEvent({ eventName: 'exit' });
    if (window === window.top) {
      window.close();
    }
  });
}
