import z from 'zod';

export const magicSpendParamsSchema = z.object({
  appId: z.string(),
  ownerAddress: z.string(),
  toAddresses: z.array(z.string().min(1)).min(1),
  chainId: z.string().transform((val) => parseInt(val, 10)),
  amount: z.string(),
  asset: z.string(),
  paymentMethod: z.union([z.literal('APPLE_PAY'), z.literal('CARD')]),
});

export type MagicSpendParams = z.infer<typeof magicSpendParamsSchema>;
