import { useIsSmartContractWallet } from '@onramp/hooks/useIsSmartContractWallet';
import type { Screen } from '@onramp/utils/eventing';
import { logNavSignout } from '@onramp/utils/eventing';
import exitWidget from '@onramp/utils/exitWidget';
import { Experiments } from '@onramp/utils/experiments/experiments';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import { useIsTreatment } from '@cbhq/experiments';

export const exitUrl = new URL('exit', process.env.NEXT_PUBLIC_ONRAMP_BASE_URL);

export const useLogout = (signoutSource: Screen) => {
  const isSmartContractWallet = useIsSmartContractWallet();
  const isInUsmExperiment = useIsTreatment(Experiments.jan_2025_onramp_usm);

  return useReffedFunction(async () => {
    try {
      const signOutPath = isInUsmExperiment ? '/v2/signout' : '/signout';
      const signoutURL = new URL(signOutPath, process.env.NEXT_PUBLIC_COINBASE_BASE_URL);
      const returnUrl = isSmartContractWallet ? `${exitUrl}` : window.location.href;
      signoutURL.searchParams.append('return', returnUrl);
      logNavSignout(signoutSource);

      if (!isInUsmExperiment) {
        const resp = await fetchWithCommonHeaders('/signout', { method: 'POST' });
        if (!resp.ok) {
          return;
        }
      }

      if (isSmartContractWallet) {
        window.open(`${signoutURL}`, '_blank');
        exitWidget();
        return;
      }

      window.location.assign(`${signoutURL}`);
    } catch {
      // noop
    }
  });
};
