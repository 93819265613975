import { useMemo } from 'react';
import { isWalletApp } from '@onramp/utils/isWalletApp';
import { useInitParams } from '@onramp/v2/client/contexts/initParams/InitParamsContext';

export function useIsWalletApp() {
  const {
    internalInitParams: {
      clientAppDetails: { appId },
    },
  } = useInitParams();
  return useMemo(() => isWalletApp(appId), [appId]);
}
