import { isProduction } from './environment/sharedEnv';

const publicKey = process.env.NEXT_PUBLIC_RISK_JS_PUBLIC_KEY as string;

export const riskJsScriptSrc = isProduction
  ? 'https://risk.checkout.com/cdn/risk/1.2/risk.js'
  : 'https://risk.sandbox.checkout.com/cdn/risk/1.2/risk.js';

let riskJs: RiskJs | undefined;
export const handleLoadRiskJs = () => {
  riskJs = window.Risk.init(publicKey);
};

/**
 * Publishes device data & retrieves the `deviceSessionId`.
 * https://www.checkout.com/docs/business-operations/prevent-fraud/integrate-with-risk-js#Integrate_the_package
 */
export const publishRiskData = async () => {
  const waitStart = Date.now();
  while (!riskJs) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (Date.now() - waitStart >= 30_000) {
      throw new Error('Risk.js load timeout');
    }
  }

  return riskJs.publishRiskData();
};
