import { doOncePerSession } from '@onramp/v2/client/utils/session/doOncePerSession';
import type { AnalyticsEventData } from '@cbhq/client-analytics';
import { ActionType, ComponentType, logEvent } from '@cbhq/client-analytics';

export const GUEST_FLOW = 'guest_flow';
export const MAGIC_SPEND_GUEST_FLOW = 'magic_spend_guest_flow';

export type NamedAnalyticsEventData = AnalyticsEventData & {
  name: string;
};

export const logEventOnce = (data: NamedAnalyticsEventData) => {
  const idemKey = `once_per_session_event.${data.name}`;
  const log = doOncePerSession(() => {
    logEvent(GUEST_FLOW, data);
  }, idemKey);

  log();
};

const GUEST_FLOW_EVENT = {
  action: ActionType.view,
  componentType: ComponentType.page,
};

const GUEST_FLOW_USER_CLICK_ACTION = {
  action: ActionType.click,
};

const GUEST_FLOW_BUTTON = {
  componentType: ComponentType.button,
};

export const GUEST_FLOW_APPLE_PAY_BUTTON_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '6153967f-b244-4490-bb6c-cfdaef0ffb21',
  name: 'APPLE_PAY_BUTTON_CLICKED',
};

export const GUEST_FLOW_APPLE_PAY_NOT_SUPPORTED: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.button,
  loggingId: '244733bd-4cc8-46e9-8c23-d99493fb97bc',
  name: 'APPLE_PAY_NOT_SUPPORTED:',
};

export const GUEST_FLOW_APPLE_PAY_LOAD_ATTEMPT: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.button,
  loggingId: '175a218b-5cc6-4447-b4cc-c28a04bc1040',
  name: 'GUEST_FLOW_APPLE_PAY_LOAD_ATTEMPT',
};

export const GUEST_FLOW_APPLE_PAY_LOAD_SUCCESS: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.button,
  loggingId: '06f47839-835e-4fee-b542-bd5b1ae9f281',
  name: 'APPLE_PAY_LOAD_SUCCESS',
};

export const GUEST_FLOW_APPLE_PAY_LOAD_FAILED: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.button,
  loggingId: 'def45315-d332-47e4-8ee4-550a89cc470e',
  name: 'APPLE_PAY_LOAD_FAILED',
};

export const GUEST_FLOW_APPLE_PAY_COMMIT_ERROR: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.button,
  loggingId: '483b1836-0af1-4f52-a670-6c91a951526a',
  name: 'APPLE_PAY_COMMIT_ERROR',
};

export const GUEST_FLOW_CONTINUE_TO_GUEST_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'fd8649a6-896a-448f-9a28-4d61310d485e',
  name: 'GUEST_FLOW_CONTINUE_TO_GUEST_CLICKED',
};

export const GUEST_FLOW_COTINUE_WITH_APPLE_PAY: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'e6f0e3c2-c92c-4c53-aee3-2d976ebbe6550',
  name: 'CONTINUE_WITH_APPLE_PAY',
};

export const GUEST_FLOW_CARD_INPUT_CONTINUE_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '45bdc097-2cc5-4e1e-b71e-befe1efee280',
  name: 'CARD_INPUT_CONTINUE_CLICKED',
};

export const GUEST_FLOW_BUY_NOW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'efbe2240-1f68-4572-83e5-4420a1a8989a',
  name: 'BUY_NOW_CLICKED',
};

export const GUEST_FLOW_ORDER_SUBMITTED: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.unknown,
  loggingId: '00e63af8-909f-4812-844e-20c5dcafa892',
  name: 'ORDER_SUBMITTED',
};

export const GUEST_FLOW_EDIT_ORDER_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '0636e062-cbf3-45bc-aa2f-6d74a6bea59a',
  name: 'EDIT_ORDER_CLICKED',
};

export const GUEST_FLOW_EDIT_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '2be15489-47c4-4ef3-acbc-1bf3fd8af106',
  name: 'EDIT_BACK_PRESSED',
};

export const GUEST_FLOW_EDIT_ASSET_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '7cbd417f-fabb-4e42-82fe-48d8f3c96382',
  name: 'EDIT_ASSET_CLICKED',
};

export const GUEST_FLOW_EDIT_NETWORK_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'b0df5b66-6da8-4a99-b4bd-1dbac39732e4',
  name: 'EDIT_NETWORK_CLICKED',
};

export const GUEST_FLOW_REVIEW_ORDER_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '9069abf7-7c25-4197-9bd8-d9a075693643',
  name: 'REVIEW_ORDER_CLICKED',
};

export const GUEST_FLOW_SWAP_AND_CONVERT_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '7f4c9c30-4b0d-4065-92de-424c640351fb',
  name: 'SWAP_AND_CONVERT_CLICKED',
};

export const GUEST_FLOW_EXIT_PHONE_VERIFICATION_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '98cd77c1-7cfb-4faf-8337-5303b0ef3244',
  name: 'EXIT_PHONE_VERIFICATION_VIEW_CLICKED',
};

export const GUEST_FLOW_ASSET_SELECTED = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '3481c8dd-f627-43ab-8147-72064a98759e',
  name: 'ASSET_SELECTED',
};

export const GUEST_FLOW_NETWORK_SELECTED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'f9d1b5c8-7f4a-4d7e-8c1d-6c4f3b6b9b4d',
  name: 'NETWORK_SELECTED',
};

export const GUEST_FLOW_EXIT_CARD_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '6254d4ae-1cf9-4c40-96b0-282dd64505dc',
  name: 'EXIT_CARD_VIEW_CLICKED',
};

export const GUEST_FLOW_BACK_TO_INPUT_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'a9d8ce12-83ee-461a-9150-4b754c67bc92',
  name: 'GUEST_FLOW_BACK_TO_INPUT_CLICKED',
};

export const GUEST_FLOW_AUTH_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'a493e867-6832-4c0c-8523-9dab75ed8ffb',
  name: 'AUTH_VIEW_CLICKED',
};

export const GUEST_FLOW_AUTH_VIEW_REDIRECTED: NamedAnalyticsEventData = {
  action: ActionType.unknown,
  componentType: ComponentType.unknown,
  loggingId: '47fabf3f-acd8-41ef-b20b-c34c9ad95039',
  name: 'AUTH_VIEW_REDIRECTED',
};

export const GUEST_FLOW_VIEW_DETAILS_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'daa1b81b-96f0-4f76-8e5f-b5d39064bce7',
  name: 'VIEW_DETAILS_CLICKED',
};

export const GUEST_FLOW_REDIRECT_TO_EDIT_BELOW_MIN_SEND: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '2be15489-47c4-4ef3-acbc-1bf3fd8af106',
  name: 'REDIRECT_TO_EDIT_BELOW_MIN_SEND',
};

export const CARD_DETAILS_VIEWED: (redirectUrl: string | undefined) => NamedAnalyticsEventData = (
  redirectUrl,
) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '0fa30516-9acf-4370-9341-bc911ec1c871',
  name: 'CARD_DETAILS_VIEWED',
  redirectUrl,
});

export const CHANGE_NETWORK_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'ae9bcca3-8424-4f2e-89f2-2d0ea59e902a',
  name: 'CHANGE_NETWORK_VIEWED',
};

export const EDIT_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '3842b5e0-b1fb-455d-83fb-d4d871e8d03a',
  name: 'EDIT_VIEWED',
};

export const ORDER_DETAILS_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'a4a154e8-32a0-49a8-802a-2c8bbc5a960e',
  name: 'ORDER_DETAILS_VIEWED',
};

export const ORDER_PREVIEW_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '62f9ab67-7152-459c-9bf8-e469fc47549e',
  name: 'ORDER_PREVIEW_VIEWED',
};

export const ORDER_SUBMITTED_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'f06e9b62-ead8-44f5-b9b7-681393e5f06e',
  name: 'ORDER_SUBMITTED_VIEWED',
};

export const SUCCESS_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '3a8df573-e332-4f3f-8cda-1e9d50303826',
  name: 'SUCCESS_VIEWED',
};

export const TRYING_TO_VIEW_SUCCESS_WITH_NO_UUID: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '78c71129-deb3-4837-baba-69936d92fdd8',
  name: 'TRYING_TO_VIEW_SUCCESS_WITH_NO_UUID',
};

export const PHONE_VERIFICATION_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'a4a4da70-25a6-4017-9ebb-28054da457a8',
  name: 'PHONE_VERIFICATION_VIEWED',
};

export const SELECT_ASSET_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '9f2fd471-469a-4dc0-9598-5db5099183f1',
  name: 'SELECT_ASSET_VIEWED',
};

export const TRANSACTION_LIMIT_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0beafd62-45d0-45b2-ad14-efffc51433e0',
  name: 'TRANSACTION_LIMIT_VIEWED',
};

export const TRANSACTION_LIMIT_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'bb0fb2ae-aff4-4fb9-8ecc-659adf15f0c5',
  name: 'TRANSACTION_LIMIT_BACK_PRESSED',
};

export const TRANSACTION_LIMIT_CREATE_ACCOUNT_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'fd0fb833-a2f2-4abb-9748-4f321493a586',
  name: 'TRANSACTION_LIMIT_CREATE_ACCOUNT_PRESSED',
};

export const TRANSACTION_LIMIT_SKIP_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '7819d25c-1e17-489f-9498-61bc80e64f27',
  name: 'TRANSACTION_LIMIT_SKIP_PRESSED',
};

export const PHONE_INPUT_CHANGED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '9ae9450b-5afa-4696-b9d3-0d250e0c32ea',
  name: 'PHONE_INPUT_CHANGED',
};

export const CARD_EXPIRATION_CHANGED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0a8f7016-0f8a-4c17-b89d-71ee5f3b7d59',
  name: 'CARD_EXPIRATION_CHANGED',
};

export const ADDRESS_AUTOCOMPLETE_ACCEPTED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0a8f7016-0f8a-4c17-b89d-71ee5f3b7d59',
  name: 'ADDRESS_AUTOCOMPLETE_ACCEPTED',
};

export const EMAIL_CHANGED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '8bb9dbac-8385-4e2a-ad1d-865e689809c2',
  name: 'EMAIL_CHANGED',
};

export const ERROR_SCREEN: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '948184c3-3435-4aed-a746-8208e14ed87c',
  name: 'ERROR_SCREEN',
};

export const SWITCH_TO_AUTHED_FLOW: (automatic: boolean) => NamedAnalyticsEventData = (
  automatic,
) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '75aee2eb-fdbd-4f7a-bc1b-a7b251f61d25',
  name: 'SWITCH_TO_AUTHED_FLOW',
  automatic,
});

type RedirectFromCardDetailsGuestUnavailableArgs = {
  killed: boolean;
  eligible: boolean;
};

export const REDIRECT_FROM_CARD_DETAILS_GUEST_UNAVAILABLE: (
  args: RedirectFromCardDetailsGuestUnavailableArgs,
) => NamedAnalyticsEventData = ({ killed, eligible }) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '7832c112-8ef1-4688-819c-d0749918eac2',
  name: 'REDIRECT_FROM_CARD_DETAILS_GUEST_UNAVAILABLE',
  eligible,
  killed,
});

export const REDIRECT_FROM_PHONE_VERIFICATION_GUEST_UNAVAILABLE: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '4b51f18b-1b15-42e9-985b-8cc945e05c0b',
  name: 'REDIRECT_FROM_PHONE_VERIFICATION_GUEST_UNAVAILABLE',
};

export const REDIRECT_FROM_PHONE_VERIFICATION_APPLE_PAY_UNAVAILABLE: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '802de33a-4c20-4b4a-b341-da18d8ed8eec',
  name: 'REDIRECT_FROM_PHONE_VERIFICATION_APPLE_PAY_UNAVAILABLE',
};

export const REDIRECT_FROM_PHONE_VERIFICATION_AMOUNT_LESS_THAN_GCO_MIN: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0917f2e4-253d-4bca-8b83-0c6f99617546',
  name: 'REDIRECT_FROM_PHONE_VERIFICATION_AMOUNT_LESS_THAN_GCO_MIN',
};

export const PHONE_VERIFICATION_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'f95fdaf1-7f1b-4a27-ad5f-c6c9da25b68c',
  name: 'PHONE_VERIFICATION_BACK_PRESSED',
};

export const PHONE_VERIFICATION_NO_ASSETS_EXIT_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '518732b9-1963-406e-a938-4324030493be',
  name: 'PHONE_VERIFICATION_NO_ASSETS_EXIT_PRESSED',
};

export const TWO_FACTOR_RESULT: (success: boolean) => NamedAnalyticsEventData = (success) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: 'afe1e3f2-f9e6-48b4-9c5f-d70262f63ba2',
  name: 'TWO_FACTOR_RESULT',
  success,
});

export const TWO_FACTOR_SKIPPED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'a7f78a20-cb03-43e0-a9c8-609d700762c4',
  name: 'TWO_FACTOR_SKIPPED',
};

type PhoneVerificationErrorType =
  | 'asset_unavailable'
  | 'network_unavailable'
  | 'no_assets'
  | 'return_to_order_submitted'
  | 'transaction_count';

export const PHONE_VERIFICATION_ERROR: (
  errorType: PhoneVerificationErrorType,
) => NamedAnalyticsEventData = (errorType) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: 'cb7eecab-71cb-44ec-a3d8-2b806391eb98',
  name: 'PHONE_VERIFICATION_ERROR',
  errorType,
});

type AuthorizeSessionError =
  | 'invalid_request'
  | 'non_graphql_error'
  | 'permission_denied'
  | 'region_forbidden'
  | 'session_region_mismatch'
  | 'unexpected_error_message';

export const AUTHORIZE_SESSION_RESULT: (
  errorType: AuthorizeSessionError,
) => NamedAnalyticsEventData = (errorType) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '89d02009-ee37-4916-a9b0-f59adcf2f5ec',
  name: 'AUTHORIZE_SESSION_RESULT',
  errorType,
});

type CreateTransactionErrorType =
  | 'expired_session_no_auth_code'
  | 'expired_session_reprompt'
  | 'invalid_address'
  | 'invalid_request'
  | 'non_graphql_error'
  | 'risk_declined'
  | 'transaction_count'
  | 'unexpected_graphql_message'
  | 'weekly_limit';

export const CREATE_TRANSACTION_ERROR: (
  errorType: CreateTransactionErrorType,
) => NamedAnalyticsEventData = (errorType) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '9b45b7a9-d192-4cb2-a297-cf920af88d40',
  name: 'CREATE_TRANSACTION_ERROR',
  errorType,
});

type CreatedTransactionTags = {
  subsidized: boolean;
  transactionUuid: string;
};

export const CREATED_TRANSACTION: (args: CreatedTransactionTags) => NamedAnalyticsEventData = ({
  subsidized,
  transactionUuid,
}) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: 'de67beb1-fe0c-406d-8ccd-0d26e8c90409',
  name: 'CREATED_TRANSACTION',
  subsidized,
  transactionUuid,
});

type CommitTransactionErrorType =
  | 'expired_session_no_auth_code'
  | 'expired_session_reprompt'
  | 'card_soft_declined'
  | 'invalid_card'
  | 'insufficient_balance'
  | 'card_hard_declined'
  | 'card_risk_declined'
  | 'cvv_not_present'
  | 'region_mismatch'
  | 'recaptcha_failed'
  | 'permission_denied'
  | 'unexpected_graphql_message';

export const COMMIT_TRANSACTION_ERROR: (
  errorType: CommitTransactionErrorType,
) => NamedAnalyticsEventData = (errorType) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: 'ab65a4d9-8408-400a-a50d-7ab9ba872137',
  name: 'COMMIT_TRANSACTION_ERROR',
  errorType,
});

type CommittedTransactionTags = {
  transactionUuid: string;
};

export const COMMITTED_TRANSACTION: (args: CommittedTransactionTags) => NamedAnalyticsEventData = ({
  transactionUuid,
}) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '6b00eb12-7295-413a-ba12-6010eddf1c44',
  name: 'COMMITTED_TRANSACTION',
  transactionUuid,
});

export const GUEST_FLOW_3DS_FAILED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'f3ae40d7-1199-45f8-85e0-cf6980f563d4',
  name: 'GUEST_FLOW_3DS_FAILED',
};
