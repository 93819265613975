import { useOpenUrl } from '@onramp/hooks/utils/useOpenUrl';
import { ADD_PAYMENT_METHOD_URL } from '@onramp/utils/consts';

import { isMobileExperience } from '../../utils/postMessage';

export function useHandleAddPaymentMethodInRetail() {
  const openUrl = useOpenUrl();

  /**
   * If in a WebView, open URL in the current browsing context; otherwise, in a new tab or window.
   * The special behavior when in a WebView aims to allow Android WebViews to intercept the navigation and provide an alternative experience.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target}
   * @see {@link https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#setSupportMultipleWindows}
   * */
  return () => {
    if (isMobileExperience()) {
      window.open(ADD_PAYMENT_METHOD_URL, '_self');
      return;
    }

    openUrl(ADD_PAYMENT_METHOD_URL);
  };
}
