import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { parseUrl } from './url';

export const getIsMagicSpendPath = (href = window.location.href): boolean => {
  let pathname = href;
  const url = parseUrl(pathname); // if it parses, then pathname is a full URL with host, path, etc.
  if (url) {
    pathname = url.pathname;
  }

  return pathname.startsWith('/v2/magic-spend');
};

export const useIsMagicSpendPath = () => {
  const { asPath } = useRouter();
  return useMemo(() => getIsMagicSpendPath(asPath), [asPath]);
};
