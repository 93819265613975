import { useMemo } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ModalLayout } from '@onramp/components/Modal';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { genericMessages } from '@onramp/utils/genericMessages';
import type { Tags } from '@onramp/v2/client/views/guest/onramp/analytics';
import {
  ERROR_SCREEN,
  GENERIC_ERROR,
  GUEST_FLOW,
  logCriticalStepMetric,
  useLogOnrampEventOnMountV2,
} from '@onramp/v2/client/views/guest/onramp/analytics';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';
import { logMetric } from '@cbhq/client-analytics';

const messages = defineMessages({
  genericErrorTitle: {
    id: 'GenericErrorScreenV2.genericErrorTitle',
    defaultMessage: 'An error occurred',
    description: 'Title for the generic error screen',
  },
  genericErrorDescription: {
    id: 'GenericErrorScreenV2.genericErrorDescription',
    defaultMessage: "We're looking into it right now. Please try again later.",
    description: 'Description for the generic error screen',
  },
  cancelButtonText: {
    id: 'GenericErrorScreenV2.cancelButtonText',
    defaultMessage: 'Cancel',
    description: 'Button text for cancel button',
  },
});

type GenericErrorScreenProps = {
  justifyTitle?: ComponentProps<typeof Box>['justifyContent'];
  justify?: ComponentProps<typeof VStack>['justifyContent'];
  icon?: ReactNode;
  pageTitle?: string;
  description?: ReactNode;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  showCloseButton?: boolean;
  title?: string;
  onPrimaryPress?: () => void;
  onSecondaryPress?: () => void;
  onBack?: () => void;
  // Our Error boundary does not let us use our hook logging utils so passing along error into here to log it
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

export const GenericErrorScreenV2 = ({
  justifyTitle,
  justify,
  icon,
  pageTitle,
  description,
  primaryButtonTitle,
  secondaryButtonTitle,
  showCloseButton,
  title,
  onPrimaryPress,
  onSecondaryPress,
  onBack,
  error,
  errorInfo,
}: GenericErrorScreenProps) => {
  useEffectOnMount(() => {
    logErrorMetric();
  });
  useLogOnrampEventOnMountV2(GUEST_FLOW, {
    ...ERROR_SCREEN,
    error: error?.message,
    errorInfo: errorInfo?.componentStack,
  });

  const { formatMessage } = useIntl();

  const descriptionNode = useMemo(() => {
    if (Boolean(description) && typeof description !== 'string') {
      return description;
    }

    return (
      <TextBody as="p" color="foregroundMuted" spacingBottom={3} align="center">
        {description ?? formatMessage(messages.genericErrorDescription)}
      </TextBody>
    );
  }, [description, formatMessage]);

  return (
    <ModalLayout
      onBack={onBack}
      title={pageTitle ?? formatMessage(messages.genericErrorTitle)}
      hideHamburgerMenu
      showCloseButton={showCloseButton}
    >
      <VStack
        testID="generic-error-view"
        as="main"
        spacing={3}
        height="100%"
        justifyContent={justify}
      >
        <VStack flexGrow={1} alignItems="center">
          {icon ?? (
            <Box spacingVertical={3} justifyContent="center">
              <HeroSquare name="serverCatSystemError" dimension="200x200" />
            </Box>
          )}
          <Box justifyContent={justifyTitle ?? 'center'} width="100%">
            <TextTitle3 as="h3" color="foreground" spacingTop={2} spacingBottom={1}>
              {title ?? formatMessage(genericMessages.somethingWentWrong)}
            </TextTitle3>
          </Box>
          {descriptionNode}
        </VStack>
        <VStack gap={1}>
          {onPrimaryPress !== undefined && (
            <Box width="100%">
              <Button variant="primary" block onPress={onPrimaryPress}>
                {primaryButtonTitle ?? formatMessage(genericMessages.continue)}
              </Button>
            </Box>
          )}
          {onSecondaryPress !== undefined && (
            <Box width="100%">
              <Button variant="secondary" block onPress={onSecondaryPress}>
                {secondaryButtonTitle ?? formatMessage(messages.cancelButtonText)}
              </Button>
            </Box>
          )}
        </VStack>
      </VStack>
    </ModalLayout>
  );
};

/**
 * This function publishes a raw error metric to count the number of errors, as well as a session_error metric to only
 * count the first error per session.
 */
const logErrorMetric = (tags?: Tags) => {
  logCriticalStepMetric('session_error', tags);
  logMetric({ ...GENERIC_ERROR, tags: { ...tags } });
};
