import { useEffect } from 'react';
import { flushQueue } from '@cbhq/client-analytics';

export default function useFlushQueueOnPageHidden() {
  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'hidden') {
        void flushQueue();
      }
    };

    document.addEventListener('visibilitychange', listener);

    return () => document.removeEventListener('visibilitychange', listener);
  }, []);
}
