import type { AppParamsMetadata } from '@onramp/components/AppManagerProvider/AppManagerProvider';
import type { TransferType } from '@onramp/hooks/useTransferType';
import type { AuthenticationProvider } from '@onramp/shared/initiateSessionHandler.schema';
import type { BuyWidgetStateProps, ParsedNetworkFormat } from '@onramp/state/BuyWidgetState';
import type { BuyFlowType } from '@onramp/state/recoil/buyFlowType';
import type { WalletAnalyticsData } from '@onramp/types/initParams';
import cloneDeep from 'lodash/cloneDeep';
import type { ReadonlyDeep } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';
import { persistentData } from '@cbhq/client-analytics';

import type { ClientAppDetails } from './clientApps/types';
import { ClientAppDetailsSchema } from './clientApps/types';
import { getCookie, setCookie } from './cookies/clientUtils';
import type { ExperimentTestName } from './experiments/experiments';
import type { FeatureFlags } from './featureFlags/types';
import type { ClientGsscData } from './gssc';
import { clientGsscDataSchema } from './gssc';
import { maskWalletAddress } from './maskWalletAddress';

/**
 * Returns the current user's Device ID, which is set when the user makes their first
 * request to the website and is preserved in a cookie. This is used as a unique
 * device identifier, for example in amplitude.
 */
const getDeviceId = () => {
  let deviceId = getCookie({ cookieName: 'deviceId' });
  deviceId ??= uuidv4();

  setCookie({ cookieName: 'deviceId', value: deviceId });
  return deviceId;
};

export type ExposedExperimentMetadata = { experiment: ExperimentTestName; group: string };

export type AuthenticationMethod = 'token-import' | 'auth-code-exchange' | 'refresh-token';

/*
 * Simple "store" to hold client-side session ID and user UUID.
 * Can set/read from anywhere, but not "reactive" from a React-perspective.
 */
export const clientSessionIdStore = (() => {
  let internalUserUuid = '';
  let sessionId: string = persistentData.sessionUUID ?? '';
  let internalCsrfToken = '';
  let clientAppDetails: ReadonlyDeep<ClientAppDetails> | undefined;
  let featureFlags: FeatureFlags | undefined;
  let appParamsMetadata: ReadonlyDeep<AppParamsMetadata> | undefined;
  let redactedAppParamsMetadata: ReadonlyDeep<AppParamsMetadata> | undefined;
  let transferType: TransferType | undefined;
  let buyWidgetState: ReadonlyDeep<Partial<BuyWidgetStateProps>> | undefined;
  let activeNetworkMetadata: ReadonlyDeep<ParsedNetworkFormat> | undefined;
  let gsscData: ClientGsscData | undefined;
  let buyFlowType: BuyFlowType | undefined;
  let authProvider: AuthenticationProvider | undefined;
  const exposedExperiments: ExposedExperimentMetadata[] = [];
  let authenticationMethod: AuthenticationMethod | undefined;
  let guestCheckoutEntityHash: string | undefined;
  let guestCheckoutTransactionUuid: string | undefined;
  let guestCheckoutCountryAllowlist: string[] | undefined;
  let walletAnalyticsData: WalletAnalyticsData | undefined;

  return {
    // User ID
    getUserUuid: () => internalUserUuid,
    setUserUuid: (val: string) => {
      internalUserUuid = val;
    },
    // Session ID
    getClientSessionId: () => {
      return sessionId;
    },
    setClientSessionId: (val: string) => {
      if (!val) return;

      sessionId = val;
      persistentData.sessionUUID = val;
    },
    // Device ID
    getDeviceId,
    // CSRF
    getCsrfToken: () => internalCsrfToken,
    setCsrfToken: (val: string) => {
      internalCsrfToken = val;
    },
    // Client app details (public name, feature flags, etc.)
    getClientAppDetails: () => clientAppDetails,
    setClientAppDetails: (val: ClientAppDetails | undefined) => {
      clientAppDetails = ClientAppDetailsSchema.optional().catch(undefined).parse(val);
    },
    // Feature flags
    getFeatureFlags: () => featureFlags,
    setFeatureFlags: (val: FeatureFlags | undefined) => {
      featureFlags = val;
    },
    // Transfer type: buy-and-send or send-only
    getTransferType: () => transferType,
    setTransferType: (val: TransferType | undefined) => {
      transferType = val;
    },
    // buy widget state
    getBuyWidgetState: () => buyWidgetState,
    setBuyWidgetState: (val: Partial<BuyWidgetStateProps> | undefined) => {
      buyWidgetState = val;
    },
    // active network metadata
    getActiveNetworkMetadata: () => activeNetworkMetadata,
    setActiveNetworkMetadata: (val: ParsedNetworkFormat | undefined) => {
      activeNetworkMetadata = val;
    },
    // a subset of GSSC (global session sync cookie) data that the server shares with the client
    getGsscData: () => gsscData,
    setGsscData: (val: ClientGsscData | undefined) => {
      const parsed = clientGsscDataSchema.safeParse(val);
      if (parsed.success) gsscData = parsed.data;
    },
    // buy flow type — e.g. one-click buy, recent transactions, or regular
    getBuyFlowType: () => buyFlowType,
    setBuyFlowType: (val: BuyFlowType | undefined) => {
      buyFlowType = val;
    },
    // TODO [ONRAMP-3188]: Remove once wallet stops passing legacy tokens
    // the authentication provider — either UL or legacy
    getAuthProvider: () => authProvider,
    setAuthProvider: (val: AuthenticationProvider | undefined) => {
      authProvider = val;
    },
    // App params (widget, destination wallets, etc.)
    getAppParamsMetadata: () => appParamsMetadata,
    getRedactedAppParamsMetadata: () => redactedAppParamsMetadata,
    setAppParamsMetadata: (val: AppParamsMetadata | undefined) => {
      appParamsMetadata = val;
      redactedAppParamsMetadata = appParamsMetadata && redactAppParams(appParamsMetadata);
    },
    // authentication method
    getAuthenticationMethod: () => authenticationMethod,
    setAuthenticationMethod: (val: AuthenticationMethod | undefined) => {
      authenticationMethod = val;
    },
    // track which experiments the user was exposed to
    addExposedExperiment: (exposedExperiment: ExposedExperimentMetadata) =>
      exposedExperiments.push(exposedExperiment),
    getExposedExperiments: () => Object.freeze([...exposedExperiments]),
    // an entity hash (SHA-256 of entity type + phone number), used as a user identifier for GC
    getGuestCheckoutEntityHash: () => guestCheckoutEntityHash,
    setGuestCheckoutEntityHash: (value: string | undefined) => {
      guestCheckoutEntityHash = value;
    },
    // the GC transaction UUID
    getGuestCheckoutTransactionUuid: () => guestCheckoutTransactionUuid,
    setGuestCheckoutTransactionUuid: (newGuestCheckoutTransactionUuid: string | undefined) => {
      guestCheckoutTransactionUuid = newGuestCheckoutTransactionUuid;
    },
    // the GC country code allowlist
    getGuestCheckoutCountryAllowlist: () => guestCheckoutCountryAllowlist,
    setGuestCheckoutCountryAllowlist: (value: string[] | undefined) => {
      guestCheckoutCountryAllowlist = value;
    },
    // those are now computed from clientAppDetails and appParamsMetadata
    // App Id
    getAppId: () => clientAppDetails?.appId ?? appParamsMetadata?.queryExclusiveParams?.appId ?? '',
    // Wallet User Id
    getWalletUserId: () => appParamsMetadata?.queryExclusiveParams?.walletUserId,
    // Platform attribution
    getPlatformAttribution: () => appParamsMetadata?.queryExclusiveParams?.attribution,
    // Platform version
    getPlatformVersion: () => appParamsMetadata?.queryExclusiveParams?.platformVersion,
    // SDK version
    getSdkVersion: () => appParamsMetadata?.queryExclusiveParams?.sdkVersion,
    // React Native version
    getReactNativeVersion: () => appParamsMetadata?.queryExclusiveParams?.reactNativeVersion,

    getWalletAnalyticsData: () => walletAnalyticsData,
    setWalletAnalyticsData: (newData: WalletAnalyticsData) => {
      walletAnalyticsData = newData;
    },
  };
})();

function redactAppParams(appParams: ReadonlyDeep<AppParamsMetadata>) {
  const redactedAppParams = cloneDeep(appParams) as AppParamsMetadata;
  if (redactedAppParams.widgetParams?.destinationWallets) {
    redactedAppParams.widgetParams.destinationWallets =
      redactedAppParams.widgetParams.destinationWallets.map((wallet) => ({
        ...wallet,
        address: maskWalletAddress(wallet.address),
      }));
  }

  return redactedAppParams;
}

// Expose on the global object for access in tests.
if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  Object.assign(globalThis, { clientSessionIdStore });
}
