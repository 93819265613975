/**
 * @generated SignedSource<<ef6debd4c4687175af3a2065aa46b75b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PaymentMethodTypeV2 = "ACH_BANK_ACCOUNT" | "APPLE_PAY" | "ARGENTINE_BANK_ACCOUNT" | "BANCOMAT_PAY" | "BLIK" | "DENEB_IMPS" | "DENEB_UPI" | "DIRECT_DEPOSIT_ACCOUNT" | "EFT" | "FEDWIRE" | "FIAT_ACCOUNT" | "GIFTCARD" | "GOOGLE_PAY" | "IDEAL_BANK_ACCOUNT" | "INTERAC" | "MB_WAY" | "OPEN_BANKING" | "PAYMENT_METHOD_TYPE_UNKNOWN" | "PAYPAL_ACCOUNT" | "PIX" | "PIX_DEPOSIT" | "PIX_WITHDRAWAL" | "QD" | "REMITLY" | "RTP" | "SEPA_BANK_ACCOUNT" | "SEPA_V2" | "SG_FAST" | "SG_PAYNOW" | "SOFORT" | "SWIFT_BANK_ACCOUNT" | "TRUSTLY" | "UK" | "UK_BANK_ACCOUNT" | "WORLDPAY_CARD" | "ZENGIN_DEPOSIT" | "ZENGIN_DEPOSIT_V2" | "ZENGIN_WITHDRAWAL" | "ZENGIN_WITHDRAWAL_V2" | "ZEPTO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readPaymentMethodFragment$data = {
  readonly currency: string;
  readonly limits: {
    readonly description: string;
    readonly remainingAvailableLimit: {
      readonly currency: string;
      readonly value: string;
    };
  } | null;
  readonly paymentMethodName: string;
  readonly pickerData: {
    readonly __typename: "BankPickerDataV2";
    readonly accountName: string;
    readonly accountNumber: string;
  } | {
    readonly __typename: "CoinbaseFiatAccountPickerData";
    readonly __typename: "CoinbaseFiatAccountPickerData";
  } | {
    readonly __typename: "WorldpayCardPickerData";
    readonly bin: string;
    readonly cardLast4: string;
    readonly institutionName: string;
    readonly requires3dsDdc: boolean;
    readonly use3ds: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly tradeCapabilities: {
    readonly allowBuy: boolean;
  } | null;
  readonly type: PaymentMethodTypeV2;
  readonly uuid: string;
  readonly verified: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedFiatFragment">;
  readonly " $fragmentType": "readPaymentMethodFragment";
};
export type readPaymentMethodFragment$key = {
  readonly " $data"?: readPaymentMethodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPaymentMethodFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPaymentMethodFragment"
};

(node as any).hash = "8178c341daeca7bd0130f9aedc2fa517";

export default node;
